import classNames from 'classnames';
import createReactClass from 'create-react-class';
import _ from 'lodash';
import {Link} from 'react-router-dom';
import React from 'react';

import * as net from '../net';
import dao from '../dao';
import util from '../util';

const getActiveItemAndImage = () => net.get({url: '/api/name-game/image'});
const submitNameGameGuess = ({correct, image_id}) =>
  net.post({url: '/api/name-game/submit-guess', data: {correct, image_id}});

const NameGameHome = createReactClass({
  getInitialState() {
    return {
      feedback: null,
      item: null,
      image: null,
      collections: null,
    };
  },

  async componentDidMount() {
    const collections = await dao.getCollections();
    this.setState({collections});
    this.getNewItemAndImage();
  },

  async getNewItemAndImage() {
    const {item, image} = await getActiveItemAndImage();
    this.setState({item, image});
  },

  async guessCollection(option) {
    this.setState({ feedback: null, isLoading: true });
    let correct;
    if (option === this.state.item.collection.name) {
      correct = true;
      console.log('You did it!')
    } else {
      correct = false;
      console.log('You fucked up!')
    }

    const item_name = this.state.item.product_name || this.state.item.description_es;
    const item_sku = this.state.item.sku;
    this.setState({
      feedback: {correct, item_name, item_sku},
    });
    const {item, image} = await submitNameGameGuess({correct, image_id: this.state.image.image_id})
    this.setState({item, image, isLoading: false});
  },

  render() {
    const {collections, item, image, feedback} = this.state;
    if (!item || !image || !collections) {
      return <div>Loading...</div>;
    }

    const wrong_collections = collections.filter(({name}) => name !== item.collection.name);

    const options = _.uniq(_.shuffle(
      [item.collection.name].concat(
        _.range(4).map(() => util.choose_random_from_array(wrong_collections).name))));

    return (
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-8 mx-auto">
            <h1>Welcome to the Name Game!</h1>

            <Link to="/internal/name-game/leaderboard">Name Game Leaderboard!</Link>
            <hr />

            <p>
              Compete to learn the names of our products!
            </p>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 40,
              }}
            >
              <img
                src={util.getS3Url(image.s3_key + '-medium')}
                style={{
                  maxWidth: 500,
                  width: '100%',
                  maxHeight: 500,
                }}
                alt=""
              />
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                flexWrap: 'wrap',
              }}
            >
              {options.map((option, i) => (
                <button
                  key={`${option}-${i}`}
                  onClick={() => this.guessCollection(option)}
                  className="btn btn-secondary btn-lg"
                  style={{marginTop: 15, marginBottom: 15, minWidth: 100}}
                  disabled={this.state.isLoading}
                >
                  {option}
                </button>
              ))}
            </div>

            {feedback && (
              <div
                className={
                  classNames('alert',
                    {'alert-success': feedback.correct, 'alert-danger': !feedback.correct})}
                style={{marginTop: 30}}
              >
                The last product was the {' '}
                <strong><Link to={`/internal/item/${feedback.item_sku}`}>{feedback.item_name}</Link></strong>.
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
});

export default NameGameHome;

import createReactClass from 'create-react-class';
import React from 'react';

import './AboutPage.css';

const AboutUsColumn = ({img_path, header, children}) => (
  <div className="aboutUs-column col-sm-4 col-12">
    <img src={`/static/${img_path}`} alt=""/>
    <h4>{header}</h4>

    {children}
  </div>
)

export default createReactClass({
  render() {
    return (
      <div className="container aboutUs">
        <h2>Our <span className="bold">History</span></h2>
        <div className="row">

          <AboutUsColumn header="The Beginning" img_path="about_photos/trig_kid_sailing.jpg">
            <p>
              From the time Trig Liljestrand was a young boy in Sweden, he knew that he wanted to create
              something innovative — the question was never “if” but “when”.
            </p>

            <p>
              In 1985, Trig took the opportunity to import modern Swedish design furniture into the United
              States and his dream was realized; Eurø Style® was born. Trig’s vision was to make
              beautifully designed contemporary furniture affordable and available to everyone.
            </p>

            <p>
              Nearly 30 years later Eurø Style works with more than 20 factories in five countries.
              The innovative and stylish concepts from the Eurø Style design team require a high
              standard of quality and workmanship – and the use of the best materials. Since the beginning,
              Trig’s philosophy has remained consistent – to offer Eurø Style customers the best
              possible style and design, at the best possible price, quality and value.
            </p>
          </AboutUsColumn>


          <AboutUsColumn img_path="about_photos/calais_lifestyle.jpg"
                         header="The Right Design, The Right Price">
            <p>
              A wholesale RTA furniture resource, Eurø Style is more than a brand name – it is a
              complete design approach.
            </p>

            <p>
              The ever-growing furniture collections for living room, bar, dining room and office bring
              cutting-edge contemporary style from Eurø Style’s designers in Europe and factories in
              Italy and Asia.
            </p>

            <p>
              Customers can order Eurø Style® products from the US warehouse or by factory direct
              container programs. The company ships out of its Northern California Warehouse the next
              business day after orders are placed.
            </p>
          </AboutUsColumn>

          <AboutUsColumn header="Today..." img_path="about_photos/bungie_desk.jpg">
            <p>
              Eurø Style® has continued to grow, focusing on the latest in contemporary design. Today,
              Eurø Style occupies 30,000 square feet of permanent showroom space in both the High
              Point, North Carolina, and Las Vegas, Nevada markets and also shows during the
              Hospitality/Contract Design Expo in Las Vegas. Eurø Style manufacturers and distributes
              RTA modern furniture with factories in Italy and East Asia.
            </p>

            <p>
              Eurø Style® occupies a 100,000 square foot warehouse in Union City, California and ships
              next day after order confirmation. Customers can order Eurø Style products from the US
              warehouse or by factory direct container programs.
            </p>
          </AboutUsColumn>
        </div>

        <hr />

        <div className="row">
          <div className="col-sm-4 col-12">
            <h4>Corporate Office</h4>
            <strong>Eurø Style®, LLC.</strong><br />

            2175 East Francisco Boulevard<br />
            San Rafael, CA 94901<br />
            Tel: 415.455.8000<br />
            Fax: 415.455.8033<br />
            Hours: M-F 8am - 5pm PT<br />
          </div>

          <div className="col-sm-8 col-12">
            <h4>Customer Service</h4>

            <div className="row">
              <div className="col-sm-6">
                <strong>Customer Service / New Accounts</strong><br />
                ext. 0<br />
                <a href="mailto:cs@euro.style">cs@euro.style</a><br />
                <br />
              </div>

              <div className="col-sm-6">
                <strong>Contract & Hospitality</strong><br />
                Marika Licon<br />
                ext. 106 & ext. 116<br />
                <a href="mailto:cs@euro.style">cs@euro.style</a><br />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  },
})


import React from 'react';
import {Helmet} from 'react-helmet';

import {ContentsIoPage} from '../components/ContentsIoPage';

const DOWNLOADS_PAGE_DESCRIPTION = `
  Catalogs, promotional material, terms and conditions and more!
  View our library of PDFs.
`;

class DownloadsPage extends React.Component {
  render () {
    return (
      <div className="container">
        <Helmet>
          <title>{'Downloads - Euro Style'}</title>
          <meta name="description" content={DOWNLOADS_PAGE_DESCRIPTION}/>

          <meta property="og:title" content={`Downloads - Euro Style`}/>
          <meta property="og:type" content="product"/>
          <meta property="og:url" content='https://euro.style/downloads'/>
          <meta property="og:description" content={DOWNLOADS_PAGE_DESCRIPTION}/>
        </Helmet>

        <ContentsIoPage
          contents_slug={'downloads'}
          className={'text-center'}
        />
      </div>
    )
  }
}

export default DownloadsPage;
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

import UserStore from '../auth/UserStore';
import util from '../util';
import * as net from '../net';

const NO_PRICE_LEVEL = 'NO_PRICE_LEVEL';
const price_display_to_level = _.clone(util.PRICE_GROUP_TO_PRICE_LEVEL);
price_display_to_level['No Price Level'] = NO_PRICE_LEVEL;

const PriceLevelSelect = ({label, current_price_level, update_price_level}) => (
  <div className="form-group">
    <label>{label}</label>
    <select
      className="form-control"
      required={true}
      onChange={(e) => update_price_level(e.target.value)}
      value={current_price_level}
    >
      {_.map(price_display_to_level, (price_level, price_display) =>
        <option value={price_level}>{price_display}</option>)}
    </select>
  </div>
)

PriceLevelSelect.propTypes = {
  label: PropTypes.string.isRequired,
  current_price_level: PropTypes.string.isRequired,
  update_price_level: PropTypes.func.isRequired,
}

class CurrentPriceLevel extends React.Component {
  constructor(props) {
    super(props);
    const user_state = this.getUserState();
    this.state = Object.assign({new_price_level: NO_PRICE_LEVEL}, user_state);
  }

  componentDidMount() {
    UserStore.on('change', this.setUserState);
  }

  componentWillUnmount() {
    UserStore.removeListener('change', this.setUserState);
  }

  getUserState = () => {
    return {
      customer: UserStore.get_customer(),
      user: UserStore.get_user(),
    };
  }

  setUserState = () => {
    this.setState(this.getUserState());
  }

  set_current_price_level = async(e) => {
    e.preventDefault();
    let {new_price_level} = this.state;

    if (new_price_level === NO_PRICE_LEVEL) {
      new_price_level = null;
    }

    const {user} = await net.post({url: '/api/users/current-user/set-price-level', data: {new_price_level}})
    UserStore.set_user(user);
    this.props.history.push('/cart');
  }

  render() {
    const {user, customer, new_price_level} = this.state;
    if (!user || !customer) {
      return <div>Loading user and customer...</div>
    }

    const {current_price_level} = user;

    return (
      <div className="container">
        <div className="row">
          <div className="col-6 mx-auto">
            <h3>Price Level</h3>

            <p>
              <strong>{customer.name}</strong> is at price level <strong>{customer.price_group}</strong>.
            </p>

            <p>
              {!current_price_level && 'There is no price override in effect.'}
              {current_price_level && (
                <span>
                  The price level has been manually set to <strong>
                    {util.PRICE_LEVEL_TO_PRICE_GROUP[current_price_level]}</strong>.
                </span>
              )}
            </p>

            <hr />

            <form className="form" onSubmit={this.set_current_price_level}>
              <h5>Set a new Price Level</h5>

              <PriceLevelSelect
                label="New Price Level"
                current_price_level={new_price_level}
                update_price_level={(new_price_level) => this.setState({new_price_level})}
              />

              <button className="btn btn-success" type="submit">
                Update Price level
              </button>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export {CurrentPriceLevel, PriceLevelSelect, NO_PRICE_LEVEL};
import classNames from 'classnames';
import React from 'react';
import _ from 'lodash';

import OrderDAO from './OrderDAO';
import Price from '../components/Price';

const ThCenter = ({children}) => <th className="text-center">{children}</th>

class OrderStatsSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order_stats: null,
      is_loading: true,
      current_partition: null,
    }
  }

  componentDidMount = async() => {
    const {order_stats} = await OrderDAO.get_order_stats()
    this.setState({order_stats, is_loading: false});
  }

  render() {
    const {is_loading, order_stats, current_partition} = this.state;
    if (is_loading) {
      return <div>Loading order stats...</div>;
    }

    const partitions = Object.keys(order_stats);
    const partition_infos = order_stats[current_partition];

    return (
      <div>
        <h3>Order Stats</h3>

        <ul className="nav nav-pills mb-3">
          {partitions.map((partition) => (
            <li className="nav-item">
              <button type="button"
                className={classNames("btn","btn-link","nav-link", {active: current_partition === partition})}
                onClick={() => this.setState({current_partition: partition})}
              >
                {partition}
              </button>
            </li>
          ))}
        </ul>

        {current_partition && (
          <table className="table table-sm table-striped">
            <thead>
              <tr>
                <ThCenter>{current_partition}</ThCenter>
                <ThCenter># Orders</ThCenter>
                <ThCenter>Avg. Order</ThCenter>
                <ThCenter>Total Value</ThCenter>
              </tr>
            </thead>
            <tbody>
              {_.map(_.sortBy(partition_infos, ({total_order_value}) => -1 * total_order_value), (info) => (
                <tr>
                  <td>{info.partition_key}</td>
                  <td className="text-right">{info.num_of_orders}</td>
                  <td className="text-right"><Price price={info.avg_order}/></td>
                  <td className="text-right"><Price price={info.total_order_value}/></td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

      </div>
    )
  }
}

export default OrderStatsSection;
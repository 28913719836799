import React from 'react';
import {Link}  from 'react-router-dom';

import auth_dao from './auth_dao';

class ForgotPasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      success_message: null,
      error_message: null,
      is_loading: true,
    }
  }

  requestNewPassword = async(e) => {
    e.preventDefault();
    this.setState({is_loading: true, error_message: null, success_message: null});

    const {email} = this.state;
    try {
      const {success_message} = await auth_dao.requestNewPassword({email})
      this.setState({success_message});
    } catch (e) {
      const {error_message} = e.responseJSON;
      this.setState({is_loading: false, error_message});
    }
  }

  render() {
    const {email, success_message, error_message} = this.state;
    return (
      <div className="container">
        <div className="col-12 col-sm-8 col-md-6 mx-auto">
          <h3>Forgot Your Password?</h3>

          {error_message && <div className="alert alert-danger" role="alert"> {error_message} </div> }
          {success_message && <div className="alert alert-success" role="alert"> {success_message} </div> }

          <form onSubmit={this.requestNewPassword}>
            <div className="form-group">
              <label>Email</label>
              <input
                name="email"
                type="email"
                className="form-control"
                value={email}
                onChange={(e) => this.setState({email: e.target.value})}
                required
              />
            </div>

            <button className="btn btn-success">Request New Password</button>
          </form>

          <p className="mb-5">
            After submitting this form, you will receive an email with a link to set your password.
          </p>

          <div>
            <Link to="/signup">Sign Up</Link>
          </div>
          <div>
            <Link to="/login">Log In</Link>
          </div>
        </div>
      </div>
    )
  }
}

export default ForgotPasswordPage;
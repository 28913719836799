import PropTypes from 'prop-types';
import React from 'react';
import Dropzone from 'react-dropzone';

import UserDAO from '../auth/UserDAO';
import CustomerDAO from '../customers/CustomerDAO';
import util from '../util'

const dropstyle = {width: 200, height: 200, borderWidth: 2, borderColor: '#666666', borderStyle: 'dashed', borderRadius: 5, margin: 5};

class TermsAndConditions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      terms_and_conditions_doc: null,
      is_loading: true,
    }
  }

  get_getter_func() {
    const {user_id, customer_id} = this.props;

    if (user_id) {
      return UserDAO.get_terms_and_conditions_doc.bind(null, user_id);
    }

    if (customer_id) {
      return CustomerDAO.get_terms_and_conditions_doc.bind(null, customer_id);
    }
  }

  get_updater_func() {
    const {user_id} = this.props;

    if (user_id) {
      return UserDAO.upload_terms_and_conditions_doc.bind(null, user_id);
    }
  }

  componentDidMount = async() => {
    const getter_func = this.get_getter_func();

    const {terms_and_conditions_doc} = await getter_func();
    this.setState({terms_and_conditions_doc, is_loading: false})
  }

  upload_terms_and_conditions_doc = async(file) => {
    this.setState({is_loading: true});
    const updater_func = this.get_updater_func();

    try {
      const {terms_and_conditions_doc} = await updater_func({files: [file]});
      this.setState({terms_and_conditions_doc, success_message: true});
    } catch (e) {
      this.setState({is_loading: false, error: 'Problem uploading terms and conditions...'});
      return await null;
    }

    this.setState({is_loading: false, error: ''});
  }

  render() {
    const {is_loading, terms_and_conditions_doc, error} = this.state;
    const updater_func = this.get_updater_func();

    if (is_loading) {
      return <div className="col-12 col-sm-6">Loading terms and conditions...</div>
    }

    if (!terms_and_conditions_doc) {
      return (
        <div className="col-12 col-sm-6">
          <h5>Upload Your Signed Terms and Conditions</h5>

          {updater_func ? (
              <div>
                <div className="dropzone">
                  <Dropzone
                    multiple={false}
                    onDrop={(files) => this.upload_terms_and_conditions_doc(files[0])}
                  >
                    {({getRootProps, getInputProps}) => (
                      <div style={dropstyle} {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p>Drag and drop a document here and it will automatically upload.</p>
                      </div>
                    )}
                  </Dropzone>
                </div>
              </div>
            ) : (
              <div>
                Go to a user page to upload this document.
              </div>
            )}

          {error && (
            <div className="alert alert-danger">{error}</div>
          )}
        </div>
      )
    }

    return (
      <div className="col-12 col-sm-6">
        <h5>Terms and Conditions</h5>

        <p>
          Thank you for uploading your terms and conditions. To view your terms and conditions {' '}
          <a href={util.getS3Url(terms_and_conditions_doc.s3_key)} target='_blank' rel="noopener noreferrer">click here</a>.
        </p>
      </div>
    )
  }
}

TermsAndConditions.propTypes = {
  user_id: PropTypes.number
}

export default TermsAndConditions;

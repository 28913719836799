import PropTypes from 'prop-types';
import React from 'react';
import reactUpdate from 'react-addons-update';

import AddressDisplay from './AddressDisplay';
import {AddressFields, EMPTY_SHIPPING_ADDRESS} from './AddressFields';


class AddressEditable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: null,
      is_loading: true,
      is_edit_mode: false,
    };
  }

  componentDidMount = async() => {
    const {get_address_func} = this.props;
    const {address} = await get_address_func();

    this.setState({address: address || EMPTY_SHIPPING_ADDRESS, is_loading: false});
  }

  update_address = async(e) => {
    e.preventDefault();

    this.setState({is_loading: true})

    const {update_address_func} = this.props;
    const {address} = this.state;

    const {address: new_address} = await update_address_func(address);
    this.setState({is_loading: false, is_edit_mode: false, address: new_address})
  }

  render() {
    const {is_loading, address, is_edit_mode} = this.state;
    const {label} = this.props;

    if (is_loading) {
      return <div>Loading address...</div>;
    }

    if (is_edit_mode) {
      return (
        <div className="mb-4">
          <form onSubmit={this.update_address}>
            <AddressFields
              update_address={(new_obj) => {
                const address = reactUpdate(this.state.address, {$merge: new_obj});
                this.setState({address});
              }}
              {...address}
            />

            <button type='submit' className="btn btn-success">
              Update Shipping Address
            </button>
          </form>
        </div>
      )
    }

    return (
      <div>
        <div>
          <strong>{label}</strong> {' '}
          <span className="btn btn-sm btn-link" onClick={(e) => this.setState({is_edit_mode: true})}>
            Edit
          </span>
        </div>

        <AddressDisplay {...address} />
      </div>
    );
  }
}

AddressEditable.propTypes = {
  label: PropTypes.string.isRequired,
  get_address_func: PropTypes.func.isRequired,
  update_address_func: PropTypes.func.isRequired,
}

export default AddressEditable

import classNames from 'classnames';
import React from 'react';
import {Link, Route, Switch} from 'react-router-dom';

import AboutPage from '../company_info/AboutPage';
import BlogPage from '../blog/BlogHome';
import ShowroomsPage from '../company_info/ShowroomsPage';
import TermsPage from '../company_info/TermsPage';
import MAPPolicy from '../company_info/MAPPolicy';
import PrivacyPolicy from '../company_info/PrivacyPolicy';
import DownloadsPage from '../company_info/DownloadsPage';
import Accessibility from '../company_info/Accessibility';
import CCPAAddendum from '../company_info/CCPAAddendum';
import CSCAct from '../company_info/CSCAct';
import PISOORights from '../company_info/PISOORights';

const ABOUT_PAGES = [
  {
    route: 'history',
    name: 'History',
    component: AboutPage,
  },
  {
    route: 'blog',
    name: 'Newsletter',
    component: BlogPage,
  },
  {
    route: 'showrooms',
    name: 'Showrooms',
    component: ShowroomsPage,
  },
  {
    route: 'terms',
    name: 'Terms & Conditions',
    component: TermsPage,
  },
  {
    route: 'map',
    name: 'MAP Policy',
    component: MAPPolicy,
  },
  {
    route: 'privacy',
    name: 'Privacy Policy',
    component: PrivacyPolicy,
  },
  {
    route: 'downloads',
    name: 'Downloads',
    component: DownloadsPage,
  },
  {
    route: 'accessibility',
    name: 'Accessibility Statement',
    component: Accessibility,
  },
  {
    route: 'ccpa-addendum',
    name: 'CCPA Addendum',
    component: CCPAAddendum,
  },
  {
    route: 'california-supply-chains-act',
    name: 'California Supply Chains Act',
    component: CSCAct,
  },
  {
    route: 'personal-information-sales-opt-out-and-opt-in-rights',
    name: 'Personal Information Sales Opt-Out and Opt-In Rights',
    component: PISOORights,
  },
];

const AboutPagesNavLinks = ({location}) => {
  return (
    <div className="container" style={{marginBottom: 30}}>
      <div className="row">
        <div className="col-12">
          <ul className="nav nav-pills justify-content-center">
            {ABOUT_PAGES.map(({route, name}) => (
              <li key={route} className='nav-item'>
                <Link
                  className={classNames('nav-link', {active: location.pathname === `/about/${route}`})}
                  to={`/about/${route}`}
                >
                  {name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

const AboutPages = ({location}) => {
  return (
    <div>
      <AboutPagesNavLinks location={location} />
      <Switch>
        {ABOUT_PAGES.map(({route, component}) => (
          <Route path={`/about/${route}`} component={component}/>
        ))}
      </Switch>
    </div>
  );
};

export default AboutPages;

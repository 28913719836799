import React from 'react';

import ItemDAO from '../ItemDAO';
import ItemGrid from '../product/ItemGrid';
import ItemConstants from '../dashboard/ItemConstants';


class HiddenProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: null,
    };
  }

  async componentDidMount() {
    const {items} = await ItemDAO.get_items_as_employee({
      do_not_show_on_website_from_nav: true,
      status: ItemConstants.STATUS.ACTIVE,
    });
    this.setState({items});
  }

  render() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-12 mx-auto col-sm-8">
              <h3>Hidden Products</h3>

              <p>
                These products are hidden from the website so customers who are browsing our site, cannot see
                these products.
              </p>
            </div>
          </div>
        </div>
        <hr />
        <ItemGrid items={this.state.items} non_filter_items={this.state.items}/>
      </div>
    );
  }
}

export default HiddenProducts;

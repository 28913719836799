import createReactClass from 'create-react-class';
import React from 'react';
import FontAwesome from 'react-fontawesome';

import './PriceCalculator.css';

const PRICE_LEVELS = [
  'MSRP', 'MAP', 'D', 'C', 'H', 'K', 'I', 'W', 'L', 'T', 'R', 'U',
  // 'LC',
  // 'PC20', 'PC40', 'FC', 'PO',
];

const UP = 'up';
const DOWN = 'down';

const PRICE_LEVEL_FROM_MSRP = {
  MSRP: {
    name: 'Manufacturer Suggested Retail',
    multiple: 1,
    commission: 0,
  },
  MAP: {
    name: 'Minimum Advertised',
    multiple: 0.7,
    round: DOWN,
    commission: 0,
  },
  D: {
    name: 'Designer',
    multiple: 0.45,
    round: UP,
    commission: 6,
  },
  C: {
    name: 'Contract',
    multiple: 0.4,
    round: UP,
    commission: 6,
  },
  H: {
    divisor: 2.6,
    round: UP,
    commission: 6,
  },
  K: {
    divisor: 2.8,
    round: UP,
    commission: 6,
  },
  I: {
    name: 'Wholesale',
    divisor: 3,
    commission: 6,
  },
  W: {
    name: 'Premier Wholesale',
    divisor: 3.3,
    round: UP,
    commission: 5,
  },
  L: {
    name: 'Low Wholesale',
    divisor: 3.5,
    round: UP,
    commission: 4,
  },
  T: {
    name: 'Truckload (Min Order $15k)',
    divisor: 3.6,
    round: UP,
    commission: 3,
  },
  R: {
    divisor: 3.7,
    round: UP,
    commission: 2,
  },
  U: {
    divisor: 3.8,
    round: UP,
    commission: 1,
  },
  // LC: {
  //   name: 'Landed Cost',
  //   divisor: 6.9,
  //   commission: 0,
  // },

  // PC20: [1/4.0],
  // PC40: [1/4.0],
  // FC: [1/4.0],
  //
  // PO: [1/4.0],
};

const roundPrice = (value) => (Number(Math.round(value + 'e2') + 'e-2'));


const calculateFromMSRP = (msrp) => {
  return PRICE_LEVELS.reduce((obj, price_level) => {
    const {multiple, divisor, round} = PRICE_LEVEL_FROM_MSRP[price_level];

    let price;
    if (multiple) {
      price = msrp * multiple;
    } else {
      price = msrp / divisor;
    }

    if (round === UP) {
      price = Math.ceil(price);
    } else if (round === DOWN) {
      price = Math.floor(price);
    }

    price = roundPrice(price);  // Round to two decimals

    if (isNaN(price)) {
      return obj;
    }

    obj[price_level] = price;
    return obj;
  }, {});
};

const calculateMSRPFromPrice = (price_level, price) => {
  const {multiple, divisor} = PRICE_LEVEL_FROM_MSRP[price_level];

  let msrp;
  if (multiple) {
    msrp = price / multiple;
  } else {
    msrp = price * divisor;
  }

  msrp = roundPrice(msrp);
  return msrp;
};


const PriceLevelDisplay = createReactClass({
  getInitialState() {
    return {
      showInfo: false,
    };
  },

  render() {
    const {requestChange, price_level, current_price} = this.props;
    const {multiple, divisor, round, name, commission} = PRICE_LEVEL_FROM_MSRP[price_level];

    return (
      <div className="priceLevel">
        <label>{price_level}</label>
        <input
          type="number"
          value={current_price}
          onChange={(e) => requestChange(e.target.value)}
        />

        <FontAwesome
          style={{marginLeft: 8, color: 'gray'}}
          name="info-circle"
          onClick={() => this.setState({showInfo: !this.state.showInfo})}
        />

        {this.state.showInfo && (
          <div className="priceLevel-info">
            {name && (
              <div><strong>{name}</strong></div>
            )}
            <div>
              {divisor && (
                <span>MSRP / {divisor} {' '}</span>
              )}
              {multiple && (
                <span>MSRP * {multiple} {' '}</span>
              )}
              {round && (
                <span>(Round {round})</span>
              )}
            </div>
            <span>Commission {commission}%</span>
          </div>
        )}
      </div>
    );
  }
});


const PriceCalculator = createReactClass({
  getInitialState() {
    return PRICE_LEVELS.reduce((obj, price_level) => {
      obj[price_level] = 0;
      return obj;
    }, {cubic_feet: 2.9})
  },

  updatePriceLevel(price_level, price) {
    if (!price) {
      this.setState({[price_level]: price});
      return;
    }

    const msrp = calculateMSRPFromPrice(price_level, price);
    this.setState(calculateFromMSRP(msrp))
  },

  updateCubicFeet(cubic_feet) {
    this.setState({cubic_feet});
  },

  render() {
    return (
      <div className="row priceCalculator">
        <div className="col-12 col-md-5 mx-auto">
          <h1 style={{marginBottom: 20}}>Price Calculator</h1>

          {PRICE_LEVELS.map((price_level) => (
            <PriceLevelDisplay
              key={price_level}
              price_level={price_level}
              current_price={this.state[price_level]}
              requestChange={(new_price) => this.updatePriceLevel(price_level, new_price)}
            />
          ))}

          <div className="priceLevel">
            <label>Feet<sup>3</sup></label>
            <input
              type="number"
              onChange={(e) => {
                this.updateCubicFeet(e.target.value)
              }}
              value={this.state.cubic_feet}
            />
          </div>

          {/*<div className="priceLevel">*/}
            {/*<label>FC</label>*/}
            {/*<span>*/}
                {/*{this.state.LC ? roundPrice((this.state.LC - 2 * this.state.cubic_feet) / 0.65) : 0}*/}
              {/*</span>*/}
          {/*</div>*/}
        </div>
      </div>
    )
  }
});

export default PriceCalculator;

import React from 'react';
import {Link} from 'react-router-dom';

import ContentDAO from './ContentDAO.js';
import SingleBlogPost from './SingleBlogPost.js';

import './blog.css';

class BlogPost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      blog_post: null,
    }
  }

  componentDidMount = async () => {
    const blog_slug = this.props.match.params.slug;
    const blog_post = await ContentDAO.getBlogPost(blog_slug)
    this.setState({blog_post});
  }

  render() {
    const {blog_post} = this.state;

    return (
      <div className="container blog">
        <div className='row'>
          <div className='col-12 col-md-10 mx-auto'>
            <div className='mb-5'>
              <Link to='/newsletter'>All Newsletters</Link>
            </div>

            {blog_post === null && <div>Loading...</div>}
            {blog_post && <SingleBlogPost blog_post={blog_post}/>}
          </div>
        </div>
      </div>
    )
  }
}

export default BlogPost;

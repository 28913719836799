import React from 'react';

import dao from '../dao';

import './PublicFooter.css';

class PublicFooter extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      is_loading: true,
      footer_html: null,
    };
  }

  componentDidMount = async () => {
    const setup = await dao.getSiteSetup();
    const footer_html = setup.footer;

    this.setState({
      is_loading: false,
      footer_html,
    })
  }

  render() {
    const {is_loading, footer_html} = this.state;

    return (
      <div className="footer">
        {is_loading ? (
          <div>Loading...</div>
        ) : (
          <div dangerouslySetInnerHTML={{__html: footer_html}}/>
        )}
      </div>
    );
  }
}

export default PublicFooter;

import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

import PresentationDAO from './PresentationDAO';
import CartStore from '../cart/CartStore';
import {OrderItemsTable} from '../orders/OrderItemsTable';
import Price from '../components/Price';
import {PriceLevelSelect, NO_PRICE_LEVEL} from '../dashboard/CurrentPriceLevel';

const PriceLevelAndDisplayInputs = ({index, price_display, price_level, update_state}) => (
  <div className="mb-3">
    <PriceLevelSelect
      label={`Price Level ${index}`}
      current_price_level={price_level}
      update_price_level={(price_level) => update_state({[`price_${index}_level`]: price_level})}
    />

    <div className="form-group">
      <label>Price Display {index}</label>
      <input
        type="text"
        className="form-control"
        value={price_display}
        onChange={(e) => update_state({[`price_${index}_display`]: e.target.value})}
      />
    </div>
  </div>
);

PriceLevelAndDisplayInputs.propTypes = {
  index: PropTypes.number.isRequired,
  price_display: PropTypes.string.isRequired,
  price_level: PropTypes.string.isRequired,
  update_state: PropTypes.func.isRequired,
}

const PRICE_ATTRIBUTES = _.flatten([1, 2, 3, 4].map((index) =>
  [`price_${index}_display`, `price_${index}_level`]
))

class CreatePresentationPage extends React.Component {
  constructor(props) {
    super(props);

    const state = {
      cart_sku_to_quantity: CartStore.get_cart(),
      customer_id: '',
      title: '',
      description: '',
      email_to: '',
    };

    [1, 2, 3, 4].forEach((index) => {
      state[`price_${index}_level`] = NO_PRICE_LEVEL;
      state[`price_${index}_display`] = '';
    })

    this.state = state;
  }

  componentDidMount() {
    CartStore.on('change', this.setCartState);
  }

  componentWillUnmount() {
    CartStore.removeListener('change', this.setCartState);
  }

  setCartState = () => {
    this.setState({
      cart_sku_to_quantity: CartStore.get_cart()
    });
  }

  create_presentation = async(e) => {
    e.preventDefault();
    this.setState({is_loading: true});

    const {title, description, email_to} = this.state;
    const sku_to_quantity = CartStore.get_cart();

    const skus = _.map(sku_to_quantity.toObject(), (quantity, sku) => sku);

    const presentation_data = {
      title,
      description,
      email_to,
      skus,
      is_url_publicly_accessible: true,
      customer_id: null,
    }

    PRICE_ATTRIBUTES.forEach((attr) => {
      const attr_value = this.state[attr];
      if (attr_value !== NO_PRICE_LEVEL) {
        presentation_data[attr] = attr_value;
      } else {
        presentation_data[attr] = null;
      }
    })

    const {presentation} = await PresentationDAO.create_presentation(presentation_data);

    const {url_key} = presentation;
    this.props.history.push(`/presentations/${url_key}`);
    CartStore.reset_cart();
  }

  render() {
    const {cart_sku_to_quantity, title, description, email_to} = this.state;

    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2>Create Presentation</h2>

            <OrderItemsTable
              skus={_.map(cart_sku_to_quantity.toObject(), (quantity, sku) => sku)}
              extra_columns={[
                {
                  key: 'items_per_carton',
                  header_text: 'Items per Carton',
                  right_align: true,
                  to_value: (item, row_context) => item.items_per_carton,
                  render: (item, value) => value,
                },
                {
                  key: 'msrp',
                  header_text: 'Unit MSRP',
                  right_align: true,
                  to_value: (item, row_context) => item.price_msrp,
                  render: (item, value) => <Price price={value}/>,
                },
                {
                  key: 'map',
                  header_text: 'Unit MAP',
                  right_align: true,
                  to_value: (item, row_context) => item.price_map,
                  render: (item, value) => <Price price={value}/>,
                },
                {
                  key: 'consolidation_zone',
                  header_text: 'Consolidation Zone',
                  center_align: true,
                  to_value: (item, row_context) => item.vendor_code,
                  render: (item, value) => value,
                },
              ]}
              show_subtotal_row={false}
            />

            <form onSubmit={this.create_presentation} className='col-12 col-md-6 mx-auto'>
              <div className="form-group">
                <label>Title</label>
                <input
                  className='form-control'
                  type="text"
                  value={title}
                  onChange={(e) => this.setState({title: e.target.value})}/>
              </div>

              <div className='form-group'>
                <label>Description</label>
                <textarea
                  className='form-control'
                  value={description}
                  onChange={(e) => this.setState({description: e.target.value})}/>
              </div>

              <div className='form-group'>
                <label>Email To</label>
                <input
                  className='form-control'
                  type="email"
                  value={email_to}
                  onChange={(e) => this.setState({email_to: e.target.value})}/>
              </div>

              {/*{[1, 2, 3, 4].map((index) =>*/}
                {/*<PriceLevelAndDisplayInputs*/}
                  {/*index={index}*/}
                  {/*price_display={this.state[`price_${index}_display`]}*/}
                  {/*price_level={this.state[`price_${index}_level`]}*/}
                  {/*update_state={(new_state) => this.setState(new_state)}*/}
                {/*/>*/}
              {/*)}*/}

              <button className="btn btn-success">
                Create Presentation
              </button>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

CreatePresentationPage.propTypes = {}

export {CreatePresentationPage};


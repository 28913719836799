import React from 'react';

const Percent = ({percent, num_digits}) => {
  if (percent == null) {
    return null;
  }

  const display = (percent * 100).toLocaleString(
    undefined,
    {
      minimumFractionDigits: num_digits,
      maximumFractionDigits: num_digits,
    })

  return <span>{display}%</span>;
}

Percent.defaultProps = {
  num_digits: 0,
}

export default Percent;


import moment from 'moment';
import React from 'react';
import {Link} from 'react-router-dom';

import OrderDAO from './OrderDAO';
import Price from '../components/Price';

class OrdersPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: null,
      is_loading: true,
    };
  }

  componentDidMount = async() => {
    const {orders} = await OrderDAO.get_orders();
    this.setState({orders, is_loading: false});
  }

  render() {
    const {is_loading, orders} = this.state;

    if (is_loading) {
      return <div>Loading orders...</div>
    }

    return (
      <div className='row'>
        <div className='col-12'>
          <h3>Users</h3>

          <table className="table table-striped">
            <thead>
              <tr>
                <th>ID</th>
                <th>Time Submitted</th>
                <th>Subtotal</th>
                <th>Customer ID</th>
                <th>Submitted By</th>
                <th>Status</th>
                <th>Sent to NAV</th>
              </tr>
            </thead>

            <tbody>
              {orders.map((order) => {
                return (
                  <tr>
                    <td><Link to={`/orders/${order.id}`}>{order.id}</Link></td>
                    <td>{moment.utc(order.created_at).local().format("MMM Do YYYY HH:mm")}</td>
                    <td><Price price={order.subtotal}/></td>
                    <td>
                      <Link to={`/internal/customers/${order.customer_id}`}>{order.customer_id}</Link>
                    </td>
                    <td>{order.user_who_ordered_email}</td>
                    <td>{order.status}</td>
                    <td className={order.sent_to_nav_queue_at ? 'table-warning' : 'table-danger'}>
                      {JSON.stringify(Boolean(order.sent_to_nav_queue_at))}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default OrdersPage;
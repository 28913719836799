import $ from 'jquery';
import Promise from 'bluebird';

export function post({url, data}) {
  return Promise.resolve($.ajax({
    contentType: 'application/json',
    data: JSON.stringify(data),
    type: 'POST',
    dataType: 'json',
    url: url,
    cache: false,
  }));
};


export function get({url, data, success, username, beforeSend}) {
  return $.get({
    dataType: "json",
    url,
    data,
    success,
    username,
    beforeSend,
  });
}

export function delete_({url, data}) {
  return Promise.resolve($.ajax({
    contentType: 'application/json',
    data: JSON.stringify(data),
    type: 'DELETE',
    dataType: 'json',
    url: url,
    cache: false,
  }));
}

export function postFiles({url, files, data}) {
  const form_data = new FormData();
  files.forEach((file, index) => {
    form_data.append(index, file);
  });

  form_data.set('data', data);

  return $.ajax({
    url,
    type: 'POST',
    data: form_data,
    cache: false,
    dataType: 'json',
    processData: false, // Don't process the files
    contentType: false, // Set content type to false as jQuery will tell the server its a query string req
  });
}


import React from 'react';

import util from '../util';

const INTRODUCTIONS_FILENAME = '2017-07-las-vegas-introductions.pdf';
// const SPECIALS_FILENAME = '2017-07-las-vegas-specials.pdf';


class LasVegasInfo extends React.Component {
  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>Las Vegas Market <small>B601</small></h1>

            Sunday, July 30 - Thursday, August 3, 2017

            <h3>Find our Showroom on B601</h3>

            <p>
              We are on the 6th floor of Building B.
            </p>

            <p>
              From the escalators our showroom will be straight in front of you. Or after exiting the
              elevators, take a left.
            </p>

            <img
              src="/static/las-vegas/floorplan.png"
              style={{maxWidth: 500, width: '100%'}}
              alt=""
            />

            <div>
              <h3>Product Introductions</h3>
              <p>
                We are excited to debut new collections in each of our categories this market! <a
                  href={util.getS3Url('downloads/' + INTRODUCTIONS_FILENAME)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Click here
                </a> to download the Introductions Brochure.
              </p>
            </div>

          </div>
        </div>
      </div>
    )
  }
}

export default LasVegasInfo;
import React from 'react';
import _ from 'lodash';

import OrderDAO from './OrderDAO';
import Price from '../components/Price';

const ThCenter = ({children}) => <th className="text-center">{children}</th>

class ItemStatsSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item_stats: null,
      is_loading: true,
    }
  }

  componentDidMount = async() => {
    const {item_stats} = await OrderDAO.get_item_stats()
    this.setState({item_stats, is_loading: false});
  }

  render() {
    const {is_loading, item_stats} = this.state;
    if (is_loading) {
      return <div>Loading item stats...</div>;
    }

    return (
      <div>
        <h3>Item Stats</h3>

        <table className="table table-sm table-striped">
          <thead>
            <tr>
              <ThCenter>SKU</ThCenter>
              <ThCenter>Name</ThCenter>
              <ThCenter># Orders</ThCenter>
              <ThCenter># Units</ThCenter>
              <ThCenter># Customers</ThCenter>
              <ThCenter>Avg. Cost</ThCenter>
              <ThCenter>Total Value</ThCenter>
            </tr>
          </thead>
          <tbody>
            {_.sortBy(item_stats, ({total_value}) => -1 * total_value).map((item_info) => (
              <tr>
                <td>{item_info.sku}</td>
                <td>{item_info.item.product_description}</td>
                <td className="text-right">{item_info.num_orders}</td>
                <td className="text-right">{item_info.num_units}</td>
                <td className="text-right">{item_info.num_customers}</td>
                <td className="text-right"><Price price={item_info.average_cost}/></td>
                <td className="text-right"><Price price={item_info.total_value}/></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }
}

export default ItemStatsSection;
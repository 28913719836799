import PropTypes from 'prop-types';
import React from 'react';

import ReportDAO from '../reports/ReportDAO';
import Price from '../components/Price';

const ThCenter = ({children}) => <th className="text-center">{children}</th>

const SalesByMonthTable = ({sales_by_month, omit_header}) => (
  <div>
    {!omit_header && (
      <h3>Sales by Month</h3>
    )}

    <table className="table table-sm table-responsive">
      <thead>
        <tr>
          <th/>
          {sales_by_month.map((sale_info) => (
            <ThCenter>
              {sale_info[0]}
            </ThCenter>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>Volume</th>
          {sales_by_month.map((sale_info) => (
            <td>
              <Price price={sale_info[1].total_volume} num_digits={0}/>
            </td>
          ))}
          <td className="table-info">
            <Price
              price={
                sales_by_month.reduce((current_val, sale_info) => sale_info[1].total_volume + current_val, 0)
              }
              num_digits={0}
            />
          </td>
        </tr>

        <tr>
          <th>Orders</th>
          {sales_by_month.map((sale_info) => (
            <td>{sale_info[1].total_orders}</td>
          ))}
          <td className="table-info">
            {sales_by_month.reduce((current_val, sale_info) => sale_info[1].total_orders + current_val, 0)}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);

SalesByMonthTable.propTypes = {
  sales_by_month: PropTypes.arrayOf(PropTypes.array).isRequired,
  omit_header: PropTypes.bool.isRequired,
}

SalesByMonthTable.defaultProps = {
  omit_header: false,
}

class ItemSalesOverTime extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sales_by_month_this_year: null,
      sales_by_month_previous_year: null,
      is_loading: true,
    }
  }

  componentDidMount = async() => {
    const {sku} = this.props;
    const {sales_by_month: sales_by_month_this_year} = await ReportDAO.get_item_sales_by_month(sku)
    const {sales_by_month: sales_by_month_previous_year} = await
      ReportDAO.get_item_sales_by_month(sku, {previous_year: true})
    this.setState({sales_by_month_this_year, sales_by_month_previous_year, is_loading: false});
  }

  render() {
    const {is_loading, sales_by_month_previous_year, sales_by_month_this_year} = this.state;

    if (is_loading) {
      return <div>Loading sales over time...</div>;
    }

    return (
      <div>
        <SalesByMonthTable sales_by_month={sales_by_month_this_year}/>
        <SalesByMonthTable sales_by_month={sales_by_month_previous_year} omit_header={true}/>
      </div>
    );
  }
}

ItemSalesOverTime.propTypes = {
  sku: PropTypes.string.isRequired,
}

export {ItemSalesOverTime, SalesByMonthTable};

import React from 'react';
import queryString from 'query-string';
import {Link}  from 'react-router-dom';

import auth_dao from './auth_dao';
import UserStore from './UserStore';

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      error_message: null,
      is_loading: true,
    }
  }

  submitLogin = async(e) => {
    e.preventDefault();
    this.setState({is_loading: true, error_message: null});

    const {email, password} = this.state;
    try {
      const {user, customer, site_setup} = await auth_dao.tryLogin({email, password})

      if (user) {
        UserStore.set_user(user, customer, site_setup);

        const query_string = this.props.location.search;
        const next_url = queryString.parse(query_string).next || '/';

        this.props.history.push(next_url);
      }
    } catch (e) {
      const {error_message} = e.responseJSON;
      this.setState({is_loading: false, error_message});
    }
  }

  render() {
    const {email, password, error_message} = this.state;

    return (
      <div className="container">
        <div className="col-12 col-sm-8 col-md-6 mx-auto">
          <h3>Login</h3>

          <p>
            If you have bought from Eurø Style® but have not set up a login on this website, {' '}
            <Link to="/signup">click here to sign up.</Link>
          </p>

          {error_message && <div className="alert alert-danger" role="alert"> {error_message} </div> }

          <form onSubmit={this.submitLogin} className="mb-5">
            <div className="form-group">
              <label>Email</label>
              <input
                autoFocus
                name="email"
                type="email"
                className="form-control"
                value={email}
                onChange={(e) => this.setState({email: e.target.value})}
                required
              />
            </div>

            <div className="form-group">
              <label>Password</label>
              <input
                name="password"
                type="password"
                className="form-control"
                value={password}
                onChange={(e) => this.setState({password: e.target.value})}
                required
              />
            </div>

            <button className="btn btn-success">Login</button>
          </form>

          <div className="mb-2">
            <Link to="/signup">Sign Up</Link>
          </div>
          <div>
            <Link to="/request-new-password">Forgot Password</Link>
          </div>
        </div>
      </div>
    )
  }
}

export default LoginPage;
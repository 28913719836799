import * as net from '../net';

const ItemReportDAO = {};

ItemReportDAO.get_attribute_and_function_options = () =>
  net.get({url: '/api/item-reports/get-attribute-and-function-options'})

ItemReportDAO.create_item_report = (data) =>
  net.post({url: '/api/item-reports', data})

ItemReportDAO.update_item_report = (id, data) =>
  net.post({url: `/api/item-reports/${id}`, data})

ItemReportDAO.run_item_report = (id, data) =>
  net.post({url: `/api/item-reports/${id}/export-report`, data})

ItemReportDAO.get_item_report = (id) => net.get({url: `/api/item-reports/${id}`});

ItemReportDAO.get_item_report_columns = (id) => net.get({url: `/api/item-reports/${id}/columns`});

ItemReportDAO.get_item_reports = () => net.get({url: '/api/item-reports'});

export default ItemReportDAO;

import React from 'react';
import FontAwesome from 'react-fontawesome';

function RequestSuccess() {
  return (
  <div className="container">
    <div className="row">
      <div className="col-12 col-sm-8 mx-auto">
        <h1>Request Submitted! <FontAwesome name='check'/></h1>

        <img
          src="/static/splash_photos/2.jpg"
          alt=""
          width="100%"
          style={{marginTop: 20, marginBottom: 20}}
        />

        <p>
          Thank you for submitting your request. A Eurø Style representative will be with you shortly.
        </p>

        <p>
          If you'd like to reach us, you can email <a
          href="mailto:cs@euro.style">cs@euro.style</a> or call us at {' '}
          <strong>415.455.8000</strong>.
        </p>

        <p>
          In the meantime, <a href="/">check out our home page for news and product listings</a>.
        </p>
      </div>
    </div>
  </div>
)}

export default RequestSuccess;
import * as net from '../net';

const UserDAO = {};

UserDAO.get_users = () => net.get({url: '/api/users'})

UserDAO.get_user = (user_id) => net.get({url: `/api/users/${user_id}`})

UserDAO.get_resale_certificate = (user_id) =>
  net.get({url: `/api/users/${user_id}/resale-certificate`})

UserDAO.get_terms_and_conditions_doc = (user_id) =>
  net.get({url: `/api/users/${user_id}/terms-and-conditions-doc`})

UserDAO.upload_terms_and_conditions_doc = (user_id, {files}) =>
  net.postFiles({url: `/api/users/${user_id}/terms-and-conditions-doc`, files})

UserDAO.approve_user = ({user_id, customer_id}) =>
  net.post({url: '/api/users/approve-user', data: {user_id, customer_id}})

UserDAO.block_user = ({user_id}) =>
  net.post({url: '/api/users/block-user', data: {user_id}})

UserDAO.unlink_user = ({user_id}) =>
  net.post({url: '/api/users/unlink-user', data: {user_id}})

UserDAO.set_as_end_user = ({user_id}) =>
  net.post({url: '/api/users/set-as-end-user', data: {user_id}})

UserDAO.get_new_account_application = (user_id) =>
  net.get({url: `/api/users/${user_id}/new-account-application`});

UserDAO.send_app_to_nav = ({user_id, id}) =>
  net.post({url: '/api/users/send-app-to-nav', data: {user_id, id}})

export default UserDAO;

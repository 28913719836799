import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';

import UserDAO from './UserDAO';
import {CustomerSearch} from '../customers/CustomerSearchPage'
import DescriptionList from '../components/DescriptionList';
import TermsAndConditionsDoc from '../profile/TermsAndConditionsDoc';
//import {UserAnalyticsSection} from '../user_analytics/UserAnalyticsComponents';

class ApproveUserSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      is_loading: false,
    }
  }

  approve_user = async(customer_id) => {
    const {user} = this.props;

    this.setState({is_loading: true})

    try {
      await UserDAO.approve_user({user_id: user.id, customer_id})
      window.location.reload()
    } catch (e) {
      this.setState({is_loading: false, error: 'Problem approving user'})
    }
  }

  block_user = async() => {
    const {user} = this.props;

    this.setState({is_loading: true})

    try {
      await UserDAO.block_user({user_id: user.id})
      window.location.reload()
    } catch (e) {
      this.setState({is_loading: false, error: 'Problem blocking user'})
    }
  }

  unlink_user = async() => {
    const {user} = this.props;

    this.setState({is_loading: true})

    try {
      await UserDAO.unlink_user({user_id: user.id})
      window.location.reload()
    } catch (e) {
      this.setState({is_loading: false, error: 'Problem unlinking user'})
    }
  }

  set_as_end_user = async() => {
    const {user} = this.props;

    this.setState({is_loading: true})

    try {
      await UserDAO.set_as_end_user({user_id: user.id})
      window.location.reload()
    } catch (e) {
      this.setState({is_loading: false, error: 'Problem setting as end user'})
    }
  }

  render() {
    const {user} = this.props;
    const {is_active, user_type, status} = user;

    if (user_type === 'End User') {
      return 'User is an end user!';
    }

    if (status === 'Blocked') {
      return 'User is blocked!';
    }

    if (is_active) {
      return (
        <div className="row">
          <div className="col-12">
            <h4>Unlink Customer</h4>
  
            <div className="row">
              <div className="col-6">
                <p>
                  This action will change current user status to Pending. To enable user you need to approve user again.
                </p>
                <button className="btn btn-warning mb-5" onClick={this.unlink_user}>
                Unlink Customer
                </button>
              </div>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="row">
        <div className="col-12">
          <h4>Approve Customer</h4>

          <div className="row">
            <div className="col-6">
              <p>
                Approving a customer will connect them with a customer in our system.
              </p>

              <p>
                As the user views prices and places orders, they will be tied to the customer they are linked
                to.
              </p>
            </div>

            <div className="col-6">
              <p>
                Block a user if they are a previous customer or someone else who should not be able to view
                our pricing.
              </p>

              <button className="btn btn-warning mb-5" onClick={this.block_user}>
                Block User
              </button>

              <p>
                Set a user as an end user. In the future, we will send out promotional
                emails to end users.
              </p>

              <button className="btn btn-info" onClick={this.set_as_end_user}>
                Set as End User
              </button>
            </div>
          </div>

          <CustomerSearch
            show_new_customer_link={false}
            customer_list_props={{
              render_action_cell: (customer) => (
                <td>
                  <button
                    className="btn btn-link"
                    onClick={async() => {
                      await this.approve_user(customer.id);
                    }}
                  >
                    Link to customer
                  </button>
                </td>
              )
            }}
          />
        </div>
      </div>
    )
  }
}

ApproveUserSection.propTypes = {}

class UserNewAccountApplication extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: true,
      new_account_application: null,
      error: '',
    }
  }

  componentDidMount = async() => {
    const {user_id} = this.props;
    const {new_account_application} = await UserDAO.get_new_account_application(user_id);
    this.setState({is_loading: false, new_account_application});
  }

  send_app_to_nav = async() => {
    const {new_account_application} = this.state;
    const {user_id} = this.props;

    this.setState({is_loading: true})

    try {
      await UserDAO.send_app_to_nav({user_id: user_id, id: new_account_application.id})
      window.location.reload()
    } catch (e) {
      this.setState({is_loading: false, error: 'Problem sending to NAV'})
    }
  }

  render() {
    const {is_loading, new_account_application} = this.state;

    if (is_loading) {
      return <div>Loading account application</div>
    }

    if (new_account_application == null) {
      return <div>User has not submitted new account application.</div>
    }

    const {id} = new_account_application;
    return (
      <div className="row">
        <div className="col-6">
          To view user's new account application {' '}
          <a
            href={`/admin/newaccountapplication/edit/?id=${id}`} target="_blank" rel="noopener noreferrer">
            click here
          </a>
          .
        </div>
        <div className="col-6">
          {!this.props.customer && (
            <div>
              <p>
                Send Application To NAV will create new Customer in NAV and on web site based on current user's new account application. Created customer will be automatically linked to current user.
              </p>
              <button className="btn btn-info mb-2" onClick={this.send_app_to_nav}>
                Send Application To NAV
              </button>
            </div>
          )}
        </div>
      </div>
    )
  }
}

UserNewAccountApplication.propTypes = {user_id: PropTypes.number.isRequired}

class UserInfoPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: null,
      user: null,
      is_loading: true,
    };
  }

  componentDidMount = async() => {
    const {user_id} = this.props.match.params;

    const {user, customer} = await UserDAO.get_user(user_id);
    this.setState({is_loading: false, user, customer})
  }

  render() {
    const {is_loading, user, customer} = this.state;

    if (is_loading) {
      return <div>Loading user...</div>
    }

    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h3>User {user.id} &mdash; {user.email}</h3>

            <DescriptionList
              description_schemas={[
                {label: 'First Name', attribute: 'first_name'},
                {label: 'Last Name', attribute: 'last_name'},
                {label: 'Email', attribute: 'email'},
                {label: 'Phone', attribute: 'phone_number'},
                {label: 'Company', attribute: 'company_name'},
                {label: 'Title', attribute: 'company_title'},
                {label: 'User Type', attribute: 'user_type'},
                {label: 'Status', attribute: 'status'},
              ]}
              data={user}
              className='mb-4'
            />

            {customer && (
              <div>
                <h5>Customer Info</h5>

                <DescriptionList
                  description_schemas={[
                    {
                      label: 'Customer ID',
                      render_func: ({id}) => <Link to={`/internal/customers/${id}`}>{id}</Link>
                    },
                    {label: 'Customer Name', attribute: 'name'},
                    {label: 'Category', attribute: 'category'},
                    {label: 'City', attribute: 'city'},
                    {label: 'State', attribute: 'state'},
                  ]}
                  data={customer}
                />
              </div>
            )}
          </div>
        </div>
        <UserNewAccountApplication user_id={user.id} customer={customer}/>

        <hr className="my-3"/>

        {customer && (
          <div>
            <div className="row">
              <TermsAndConditionsDoc user_id={user.id}/>
            </div>
            <hr className="my-5"/>
          </div>
        )}

        {/*<UserAnalyticsSection user_id={user.id} />

        <hr className="my-5"/>*/}

        <ApproveUserSection user={user}/>
      </div>
    )
  }
}

UserInfoPage.propTypes = {}

export default UserInfoPage;


import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';
import _ from 'lodash';

import ItemDAO from '../ItemDAO';

import './ItemPage.css';

import Price from '../components/Price';
import BestCustomersForItem from '../product/BestCustomersForItem';
import {ItemSalesOverTime} from './ItemSalesOverTime';
import ItemCard from '../components/ItemCard';
import ItemStore from '../product/ItemStore';
import DescriptionList from '../components/DescriptionList';
import util from '../util';
import dao from '../dao';


class ItemImagesTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {item_images: null, are_images_expanded: false};
  }

  componentDidMount = async() => {
    const item_images = await dao.getImagesForItem(this.props.item_sku);
    this.setState({item_images});
  }


  render() {
    const {item_images} = this.state;

    if (!item_images) {
      return <div>Loading item images for: {this.props.item_sku}</div>
    }

    return (
      <div>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Thumb</th>
              <th>Image ID</th>
              <th>Original File Name</th>
              <th>s3 Link</th>
            </tr>
          </thead>

          <tbody>
            {item_images.map((item_image) => (
              <tr key={item_image.image_id}>
                <td><img src={util.getS3Url(item_image.s3_key + '-thumb')} alt=""/></td>
                <td style={{verticalAlign: 'middle'}}>{item_image.image_id}</td>
                <td style={{verticalAlign: 'middle'}}>{item_image.original_file_name}</td>
                <td style={{verticalAlign: 'middle'}}>
                  <a href={util.getS3Url(item_image.s3_key)}>{item_image.s3_key}</a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {this.state.are_images_expanded ? (
            <div>
              {this.state.item_images.map(({s3_key}) => (
                <div key={s3_key} style={{width: '100%', border: '1px solid black', margin: 20}}>
                  <div style={{textAlign: 'center'}}>{s3_key}</div>
                  <img key={s3_key} src={util.getS3Url(s3_key)} style={{maxWidth: '100%'}} alt={s3_key}/>
                </div>
              ))}
            </div>
          ) : (
            <div className="btn btn-success" onClick={() => this.setState({are_images_expanded: true})}>
              Expand images here
            </div>
          ) }
      </div>
    );
  }
}

ItemImagesTable.propTypes = {
  item_sku: PropTypes.string,
}


const round_dim = (dim) => {
  if (!dim) {
    return ''
  }
  return dim.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1});
}

const to_dims_string = (length, width, height) => (
  `${round_dim(length)}" x ${round_dim(width)}" x ${round_dim(height)}"`
)

const ItemShippingDimensions = ({item}) => {
  const {carton_length, carton_width, carton_height} = item;

  return (
    <div className="my-2">
      <h4>Shipping Dimensions</h4>

      <DescriptionList
        description_schemas={[
          {
            label: 'Dimensions',
            value: to_dims_string(carton_length, carton_width, carton_height),
          },
          {
            label: 'Weight',
            value: item.carton_weight,
          },
          {
            label: 'Length',
            attribute: 'length',
          },
          {
            label: 'Length + Girth',
            attribute: 'length_plus_girth',
          },
          {
            label: 'Girth',
            attribute: 'girth',
          },
          {
            label: 'Can Ship Parcel',
            attribute: 'can_ship_parcel',
          },
          {
            label: 'Is Parcel Oversized',
            attribute: 'is_parcel_oversized',
          },
        ]}
        data={item.shipping_dimension_info}
      />
    </div>
  )
}

const SHIPPING_CITIES = [
  {city: 'Union City', zip_code: '94587'}, // Lawrence Furniture
  {city: 'San Diego', zip_code: '92130'},
  {city: 'Houston', zip_code: '77040'}, // Kathy Andrews
  {city: 'Boston', zip_code: '02215'},
  {city: 'New Jersey', zip_code: '07036'}, // Jensen Lewis
  {city: 'Miami', zip_code: '33054'}, // El Dorado
]

class ItemShippingQuotes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      quotes: null,
      is_loading: true,
    }
  }

  componentDidMount = async() => {
    const {sku} = this.props.item;

    const quotes = await Promise.all(SHIPPING_CITIES.map(async(shipping_city) => {
      const {city, zip_code} = shipping_city;
      const {quotes} = await ItemDAO.get_parcel_shipping_rates(sku, zip_code);

      if (!quotes || quotes.length === 0) {
        return null;
      }

      const cheapest_quote = quotes[0]
      return {
        city,
        zip_code,
        carrier: cheapest_quote.carrier,
        service: cheapest_quote.carrier_service,
        price: cheapest_quote.price,
      };
    }));


    this.setState({
      quotes: _.without(quotes, null),
      is_loading: false,
    })
  }

  render() {
    const {is_loading, quotes} = this.state;

    if (is_loading) {
      return <div className="alert alert-warning">Loading shipping quotes...</div>;
    }

    const description_schemas = _.map(quotes, ({price, city, zip_code, carrier, service}) => ({
      label: <span>{city} {zip_code}</span>,
      value: <span><Price price={price}/> {carrier} {service}</span>,
    }))

    return (
      <div className="my-2">
        <h4>Parcel Shipping Quotes</h4>

        <p>
          This should work for both individual boxes and kits. This quote is based on carton dimensions and weight
          and shipping out of Union City on our account. This is the cost of shipping one carton.
        </p>

        <DescriptionList
          description_schemas={description_schemas}
        />
      </div>
    )
  }
}

ItemShippingQuotes.propTypes = {}

const ItemPricing = ({item}) => {
  const description_schemas = _.map(util.PRICE_GROUP_TO_PRICE_LEVEL, (attribute, display) => ({
    label: display,
    value: item[attribute],
  }))

  return (
    <div className="my-2">
      <h4>Price Levels</h4>

      <DescriptionList
        description_schemas={description_schemas}
      />
    </div>
  )
}

const ItemAttributes = ({item}) => {
  return (
    <div className="my-2">
      <h4>Other Attributes</h4>

      <DescriptionList
        description_schemas={[
          {
            label: 'UPC',
            attribute: 'upc_code',
          },
          {
            label: 'Qty Available',
            attribute: 'quantity_available',
          },
          {
            label: 'Qty on Hand',
            attribute: 'quantity_on_hand',
          },
          {
            label: 'Qty Reserved on SO',
            attribute: 'quantity_reserved_on_sales_order',
          },
          {
            label: 'Qty on PO',
            attribute: 'quantity_on_purchase_order',
          },
        ]}
        data={item}
      />
    </div>
  )
}


class GetItemWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: null,

      is_loading: true,
    };
  }

  componentDidMount = async() => {
    const {sku} = this.props;
    const item_from_item_store = ItemStore.get_item(sku)

    if (item_from_item_store) {
      this.setState({item: item_from_item_store, is_loading: false});
      return await null;
    }

    const item = await dao.getItem(sku)
    ItemStore.add_item(item);
    this.setState({item, is_loading: false});
  }


  render() {
    const {is_loading, item} = this.state;

    if (is_loading) {
      return <div className="alert alert-warning">Loading item...</div>;
    }

    return this.props.render({item});
  }
}

GetItemWrapper.propTypes = {
  sku: PropTypes.string.isRequired,
  render: PropTypes.func.isRequired,
}


const ItemPage = ({match}) => {
  const {sku} = match.params;

  return (
    <div className="row">
      <div className="col-md-8 mx-auto">
        <h1>Product Page</h1>
        <Link to="/internal">Back to Product Search</Link>
        <hr />

        <GetItemWrapper
          sku={sku}
          render={({item}) => (
            <div>
              <ItemCard item={item}/>

              <ItemShippingDimensions item={item}/>

              <ItemPricing item={item}/>

              <ItemShippingQuotes item={item}/>

              <ItemAttributes item={item}/>
            </div>
          )}
        />

        <BestCustomersForItem sku={sku}/>
        <ItemSalesOverTime sku={sku}/>
        <ItemImagesTable item_sku={sku}/>
      </div>
    </div>
  );
}

export default ItemPage;

import PropTypes from 'prop-types';
import React from 'react';

import {EMPTY_SHIPPING_ADDRESS} from '../components/AddressFields';
import {FormInput, get_initial_state_from_field_definitions} from '../requests/FormInput';
import UserStore from '../auth/UserStore';

import PartsDAO from './PartsDAO';

const CUSTOMER_PARTS_FIELDS = [
  {
    key: 'original_sales_order_number',
    display: 'Sales Order Number',
    type: 'text',
    required: true,
  },
  {
    key: 'original_purchase_order_number',
    display: 'Purchase Order Number',
    type: 'text',
  },
  {
    key: 'product_sku',
    display: 'Product SKU',
    type: 'text',
    required: true,
  },
  {
    key: 'product_name',
    display: 'Product Name',
    type: 'text',
    required: true,
  },
  {
    key: 'container_reference_number',
    display: 'Reference Number',
    infoNode: (
      <p>
        The reference number can be found on the label located underneath the product.
      </p>
    ),
    type: 'text',
  },
  {
    key: 'part_description',
    display: 'Description of Part',
    type: 'textarea',
    infoNode: (
      <div>
        <p>
          Please describe the problem with your product. This will help us determine the part that needs to
          be sent.
        </p>

        <p>
          If possible, include the part letter as shown on the assembly instructions.
        </p>
      </div>
    ),
    large: true,
    required: true,
  },
  {
    key: 'shipping_address',
    display: 'Shipping Address for Part',
    large: true,
    type: 'address',
    initial_value: EMPTY_SHIPPING_ADDRESS,
  },
  // TODO: figure out how to allow the customer to drag and drop photos
];

class CustomerPartsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = Object.assign(
      {is_loading: false},
      get_initial_state_from_field_definitions(CUSTOMER_PARTS_FIELDS)
    );
  }

  request_a_part = async(e) => {
    e.preventDefault();
    this.setState({is_loading: true});

    const {customer} = this.props;
    const {id: customer_id} = customer;

    const part_request_data = Object.assign({customer_id}, this.state)
    await PartsDAO.create_part_request(part_request_data)

    window.location = '/requests/success';
  }

  render() {
    const {is_loading} = this.state;

    return (
      <form onSubmit={this.request_a_part}>
        <div className="row">
          {CUSTOMER_PARTS_FIELDS.map((request_field) => (
            <FormInput
              disabled={is_loading}
              updateValue={(new_value) => this.setState({[request_field.key]: new_value})}
              value={this.state[request_field.key]}
              fieldKey={request_field.key}
              {...request_field}
            />
          ))}

        </div>

        <button className="btn btn-success" type="submit" disabled={is_loading}>
          Submit Part Request
        </button>
      </form>
    )
  }
}

CustomerPartsForm.propTypes = {
  user: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
}


class CurrentUserAndCustomerWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = CurrentUserAndCustomerWrapper.get_user_and_customer()
  }

  componentDidMount() {
    UserStore.on('change', this.set_user_and_customer_from_store);
  }

  componentWillUnmount() {
    UserStore.removeListener('change', this.set_user_and_customer_from_store);
  }

  set_user_and_customer_from_store = () => {
    this.setState(CurrentUserAndCustomerWrapper.get_user_and_customer())
  }

  static get_user_and_customer() {
    return {
      user: UserStore.get_user(),
      customer: UserStore.get_customer(),
      is_loading: UserStore.get_waiting_for_response(),
    }
  }

  render() {
    const {render} = this.props;
    const {user, customer, is_loading} = this.state;

    if (is_loading) {
      return <div className="alert alert-warning">Loading...</div>;
    }

    if (!user) {
      return (
        <div>
          You must be signed in to see this page.
        </div>
      )
    }

    return render({user, customer});
  }
}

CurrentUserAndCustomerWrapper.propTypes = {
  render: PropTypes.func.isRequired,
};

class CustomerRequestAPartPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount = async() => {

  }

  render() {
    return (
      <CurrentUserAndCustomerWrapper render={({user, customer}) => (
        <div className="container">
          <div className="row">
            <div className="col col-md-10 col-lg-8 mx-auto">
              <h2>Request a Part</h2>

              <p>
                Use this page to request a part from our warehouse. The more information you are able to fill
                out, the faster and easier we will be able to get you your part.
              </p>

              <CustomerPartsForm
                user={user}
                customer={customer}
              />
            </div>
          </div>
        </div>
      )}/>
    )
  }
}

CustomerRequestAPartPage.propTypes = {}



export {CustomerRequestAPartPage};


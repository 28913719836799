import PropTypes from 'prop-types';
import classNames from 'classnames';
import React from 'react';
import _ from 'lodash'

class PaginatedItems extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active_page: this.props.page || 1,
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.items.length !== this.props.items.length) {
      this.setState({active_page: this.props.page || 1});
    }
  }

  get_current_page_items(active_page) {
    const {num_items_per_page, items} = this.props;
    if (items.length === 0) {
      return [];
    }
    return _.chunk(items, num_items_per_page)[Math.max(active_page - 1, 0)];
  }

  select_page(e, active_page) {
    e.preventDefault();
    this.setState({active_page});

    const {update_page} = this.props;
    if (update_page) {
      update_page(active_page);
    }

    const {scroll_to_top} = this.props;
    if (scroll_to_top) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const {map_item_to_element, items, num_items_per_page, wrap_rendered_elements} = this.props;
    if (!items) {
      return null;
    }

    const {page: props_page} = this.props;
    let active_page = props_page;
    if (!props_page) {
      active_page = this.state.active_page;
    }

    const num_pages = Math.ceil(items.length / num_items_per_page);

    if (active_page > num_pages) {
      active_page = num_pages;
    }
    const current_page_items = this.get_current_page_items(active_page);

    return (
      <div>
        {wrap_rendered_elements(current_page_items.map(map_item_to_element))}

        {num_pages > 1 && (
          <div className="text-center">
            <nav className="d-inline-block">
              <ul className="pagination">
                <li className={classNames("page-item", {disabled: active_page === 1})}>
                  <button type="button" className="btn btn-link page-link"
                    onClick={(e) => this.select_page(e, 1)}
                  >
                    <span>&laquo;</span>
                  </button>
                </li>

                {active_page - 2 >= 1 && (
                  <li className="page-item">
                    <button type="button" className="btn btn-link page-link" onClick={(e) => this.select_page(e, active_page - 2)}>
                      {active_page - 2}
                    </button>
                  </li>
                )}
                {active_page - 1 >= 1 && (
                  <li className="page-item">
                    <button type="button" className="btn btn-link page-link" onClick={(e) => this.select_page(e, active_page - 1)}>
                      {active_page - 1}
                    </button>
                  </li>
                )}
                <li className="page-item active"><button type="button" className="btn btn-link page-link">{active_page}</button></li>
                {active_page + 1 <= num_pages && (
                  <li className="page-item">
                    <button type="button" className="btn btn-link page-link" onClick={(e) => this.select_page(e, active_page + 1)}>
                      {active_page + 1}
                    </button>
                  </li>
                )}
                {active_page + 2 <= num_pages && (
                  <li className="page-item">
                    <button type="button" className="btn btn-link page-link" onClick={(e) => this.select_page(e, active_page + 2)}>
                      {active_page + 2}
                    </button>
                  </li>
                )}
                {active_page + 3 <= num_pages && (
                  <li className="page-item disabled"><span className="page-link">...</span></li>
                )}

                <li className={classNames("page-item", {disabled: active_page === num_pages})}>
                  <button type="button" className="btn btn-link page-link"
                    onClick={(e) => this.select_page(e, num_pages)}
                  >
                    <span>&raquo;</span>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        )}
      </div>
    )
  }
}

PaginatedItems.propTypes = {
  map_item_to_element: PropTypes.func.isRequired,
  wrap_rendered_elements: PropTypes.func.isRequired,
  num_items_per_page: PropTypes.number.isRequired,
  items: PropTypes.array.isRequired,
  scroll_to_top: PropTypes.bool.isRequired,
};

PaginatedItems.defaultProps = {
  wrap_rendered_elements: (rendered_elements) => <div className="row">{rendered_elements}</div>,
  scroll_to_top: true,
}

export default PaginatedItems;

import axios from 'axios';
import React from 'react';

class IntroductionsPage extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      is_loading: true,
      contents_html: null,
    };
  }

  componentDidMount = async () => {
    const {data} = await axios.get('https://cdn.contents.io/teams/euro-style/collections/pages/items/introductions');
    const contents_html = data.properties.body;
    this.setState({
      is_loading: false,
      contents_html,
    })
  }  
  
  render() {
    const {is_loading, contents_html} = this.state;
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-8 mx-auto">
          {is_loading ? (
            <div>Loading...</div>
          ) : (
            <div dangerouslySetInnerHTML={{__html: contents_html}}/>
          )}
          </div>
        </div>
      </div>
    )
  }
}

export default IntroductionsPage;

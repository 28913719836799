import React from 'react';

import dao from '../dao';
import {FormInput, get_initial_state_from_field_definitions} from './FormInput';

var APPOINTMENT_FIELDS = [
  {
    key: 'name',
    display: 'Your Name',
    type: 'text',
    required: true,
  },
  {
    key: 'company_name',
    display: 'Company Name',
    type: 'text',
    required: true,
  },
  {
    key: 'email',
    display: 'Your Email',
    type: 'email',
    required: true,
  },
  {
    key: 'date',
    display: 'Appointment Date',
    type: 'select',
    options: [],
    required: true,
  },
  {
    key: 'time',
    display: 'Time',
    type: 'select',
    options: [],
    required: true,
  },
  {
    key: 'notes',
    display: 'Notes',
    type: 'textarea',
  }
];

class AppointmentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { is_loading: true }
  }

  componentDidMount = async () => {
    const setup = await dao.getSiteSetup();

    if (!setup.dates)
      return;

    APPOINTMENT_FIELDS[3].options = setup.dates;
    APPOINTMENT_FIELDS[4].options = setup.times;
    this.setState(get_initial_state_from_field_definitions(APPOINTMENT_FIELDS));
    this.setState({date: setup.dates[0], time: setup.times[0]});
    this.setState({is_loading: false, header: setup.appointment_header, footer: setup.appointment_footer});
  };

  scheduleAppointment = async(e) => {
    e.preventDefault();
    var appointment_data = this.state;
    delete appointment_data.is_loading;
    delete appointment_data.header;
    delete appointment_data.footer;
    await dao.createAppointment(appointment_data);
    window.location = '/requests/success';
  }

  render() {
    const {is_loading, header, footer} = this.state;

    if (is_loading) {
      return (
        <div className="container">
          <div>Loading...</div>
        </div>
      )
    }

    return (
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-8 mx-auto">
            <h2>Schedule an <span className="bold">Appointment</span></h2>

            <p dangerouslySetInnerHTML={{__html: header}} />

            <form onSubmit={this.scheduleAppointment}>
              <div className="row">

                {APPOINTMENT_FIELDS.map((request_field) => (
                  <FormInput
                    updateValue={(new_value) => this.setState({[request_field.key]: new_value})}
                    value={this.state[request_field.key]}
                    fieldKey={request_field.key}
                    {...request_field}
                  />
                ))}

              </div>

              <p dangerouslySetInnerHTML={{__html: footer}} />

              <button className="btn btn-success" type="submit">
                Schedule an Appointment
              </button>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default AppointmentForm;
import classNames from 'classnames';
import React from 'react';
import _ from 'lodash';

import ReportDAO from '../reports/ReportDAO';
import CustomerInfoTable from '../reports/CustomerInfoTable';
import DescriptionList from '../components/DescriptionList';
import Price from '../components/Price';

class CustomerServiceProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code_to_info: null,
      is_loading: true,
      current_code: 'ALU',
    };
  }

  componentDidMount = async() => {
    const {code_to_info} = await ReportDAO.get_customer_service_performance()
    this.setState({is_loading: false, code_to_info})
  }

  render() {
    const {is_loading, code_to_info, current_code} = this.state;

    if (is_loading) {
      return 'Loading customer service info...';
    }

    const customer_service_codes = _.keys(code_to_info)

    const customer_service_info = code_to_info[current_code];
    const customer_infos = customer_service_info.customer_infos;
    const best_customer_infos = _.sortBy(customer_infos, ({total_amount}) => -1 * total_amount)

    return (
      <div className="col-12">
        <h2>Customer Service Info</h2>

        <ul className="nav nav-pills mb-4">
          {customer_service_codes.map((code) => (
            <li key={code} className='nav-item'>
              <span
                className={classNames('nav-link', {active: code === current_code})}
                onClick={() => this.setState({current_code: code})}
              >
                {code}
              </span>
            </li>
          ))}
        </ul>

        <DescriptionList
          className='mb-2'
          data={customer_service_info}
          description_schemas={[
            {label: 'Num Orders', attribute: 'num_orders'},
            {label: 'Num Customers', attribute: 'num_customers'},
            {
              label: 'Total Value',
              render_func: ({total_value}) => <Price price={total_value} num_digits={0}/>
            },
          ]}
        />

        {current_code && (
          <CustomerInfoTable
            customer_infos={best_customer_infos}
            show_percents={false}
            show_num_orders={true}
          />
        )}
      </div>
    )
  }
}

export default CustomerServiceProfile;


import {Link} from 'react-router-dom';
import React from 'react';

const FourOhFourPage = () => (
  <div className="container">
    <div className="row">
      <div className="col-12">
        <h1>Whoops, this page is missing...</h1>

        <p>
          We recently moved to a new website, so some of your old links may not work.
        </p>

        <p>
          You can try our <Link to="/">home page</Link> or maybe you'd like to <Link to="/search">search for
          a product</Link>.
        </p>

        <p>
          If you notice issues with the website, please contact <a href="mailto:website@euro.style">website@euro.style</a>.
        </p>
      </div>
    </div>
  </div>
);

export default FourOhFourPage;

import * as net from '../net';

const ReportDAO = {};

ReportDAO.export_custom_column_report = ({skus, columns}) =>
  net.post({url: '/api/reports/custom-column-report', data: {skus, columns}})

ReportDAO.generateInventoryReports = (data) =>
  net.post({url: '/api/reports/generate-inventory-reports', data});

ReportDAO.get_customer_service_performance = () =>
  net.get({url: '/api/reports/customer-service-performance'});

ReportDAO.get_quotes_from_dimensions = (data) =>
  net.post({url: '/api/reports/quotes-from-dimensions', data});

ReportDAO.get_item_sales_by_month = (sku, data) =>
  net.get({url: `/api/items/${sku}/sales-by-month`, data});

export default ReportDAO;

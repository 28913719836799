import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';
import _ from 'lodash';

import {
  OrderItemsTable, ITEM_CARTON_COLUMN, ITEM_VOLUME_COLUMN, ITEM_WEIGHT_COLUMN
} from '../orders/OrderItemsTable';
import ItemStore from '../product/ItemStore';
import SalesOrderDAO from './SalesOrderDAO';
import AddressDisplay from '../components/AddressDisplay'
import DescriptionList from '../components/DescriptionList'
import Price from '../components/Price'
import util from '../util';


const HandlingUnitHeaders = ({handling_unit_headers}) => {
  return (
    <div>
      {handling_unit_headers.map(
        ({
          handling_unit_id, location_code, total_gross_weight, number_of_cartons, payment_method,
          shipping_agent_code, shipping_agent_service_code, tracking_number
        }, index) => (
          <div key={index}>
            <h4> Shipment {handling_unit_id} </h4>

            <DescriptionList
              description_schemas={[
                {label: 'ES Warehouse', value: location_code},
                {label: 'Weight', render_func: () => `${total_gross_weight} lbs`},
                {label: 'Num Cartons', value: number_of_cartons},
                {label: 'Freight Payment', value: payment_method},
                {label: 'Carrier', value: shipping_agent_code},
                {label: 'Carrier Service', value: shipping_agent_service_code},
                {label: 'Tracking', value: tracking_number},
              ]}
              data={{}}
              render_empty_cells={true}
            />
          </div>
        )
      )}
    </div>
  )
}

class SalesOrderInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    const {
      sales_order, sales_order_lines, items, customer, shipping_address, handling_unit_headers
    } = this.props;

    const item_skus = _.map(items, ({sku}) => sku);

    const sku_to_quantity = {};
    const sku_to_cost = {};
    _.each(sales_order_lines, ({item_sku, quantity, unit_price}) => {
      if (!item_sku || !_.includes(item_skus, item_sku)) {
        return;
      }

      sku_to_cost[item_sku] = unit_price;
      if (sku_to_quantity[item_sku]) {
        sku_to_quantity[item_sku] += quantity;
      } else {
        sku_to_quantity[item_sku] = quantity;
      }
    })

    return (
      <div>
        <h2 className="mb-5">Sales Order {sales_order.sales_order_id}</h2>

        <DescriptionList
          description_schemas={[
            {
              label: 'Customer', render_func: () => (
              <Link
                to={`/internal/customers/${customer.id}`}
              >
                {customer.name}
              </Link>
            )
            },
            {attribute: 'customer_po_number', label: 'Customer PO'},
            {attribute: 'status', label: 'Order Status'},
            {attribute: 'shipment_status', label: 'Shipment Status'},
            {label: 'Order Date', render_func: ({order_date}) => util.date_format_iso_to_day(order_date)},
            {
              label: 'Shipment Date',
              render_func: ({shipment_date}) => util.date_format_iso_to_day(shipment_date)
            },
            {
              label: 'Posting Date',
              render_func: ({posting_date}) => util.date_format_iso_to_day(posting_date)
            },
          ]}
          data={sales_order}
          render_empty_cells={true}
        />


        <div className="mb-4">
          <strong>Shipping Address</strong> {' '}
          <AddressDisplay {...shipping_address}/>
        </div>

        <OrderItemsTable
          skus={_.map(sku_to_quantity, (quantity, sku) => sku)}
          extra_columns={[
            {
              key: 'quantity',
              header_text: 'Quantity',
              right_align: true,
              to_value: (item, row_context) => row_context.quantity,
              render: (item, value) => value,
            },
            {
              key: 'unit_cost',
              header_text: 'Unit Cost',
              right_align: true,
              to_value: (item, row_context) => sku_to_cost[item.sku] * row_context.quantity,
              render: (item, value) => <Price price={value}/>,
            },
            {
              key: 'cost',
              header_text: 'Cost',
              right_align: true,
              show_subtotal: true,
              to_value: (item, row_context) => (
                sku_to_cost[item.sku] * row_context.quantity
              ),
              render: (item, value) => <Price price={value}/>,
            },
            ITEM_VOLUME_COLUMN,
            ITEM_WEIGHT_COLUMN,
            ITEM_CARTON_COLUMN,
          ]}
          get_row_context={(item) => ({quantity: sku_to_quantity[item.sku]})}/>

        <HandlingUnitHeaders
          handling_unit_headers={handling_unit_headers}
        />

        {/*<RequestFreightQuoteGeneric
          destination_address={shipping_address}
          sku_to_quantity={sku_to_quantity}
          order_subtotal={sales_order.amount}
        />*/}
      </div>
    )
  }
}

SalesOrderInfo.propTypes = {
  sales_order: PropTypes.object.isRequired,
};

class SalesOrderInfoPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: true,
      error: false,
    };
  }

  componentDidMount = async() => {
    const {sales_order_id} = this.props.match.params;

    try {
      const {
        sales_order, sales_order_lines, items, customer, shipping_address, handling_unit_headers
      } = await SalesOrderDAO.get_sales_order(sales_order_id);
      ItemStore.add_items(items);

      this.setState({
        sales_order, sales_order_lines, items, customer, shipping_address, handling_unit_headers
      })
    } catch (e) {
      this.setState({error: 'Error loading sales order'})
    }

    this.setState({is_loading: false});
  }

  render() {
    const {
      error, is_loading, sales_order, sales_order_lines, items, customer, shipping_address,
      handling_unit_headers,
    } = this.state;

    if (is_loading) {
      return <div className="alert alert-warning">Loading...</div>
    }

    if (error) {
      return <div className="alert alert-danger">{error}</div>
    }

    return (
      <div className="container">
        <div className="row">
          <SalesOrderInfo
            sales_order={sales_order}
            sales_order_lines={sales_order_lines}
            items={items}
            customer={customer}
            shipping_address={shipping_address}
            handling_unit_headers={handling_unit_headers}
          />
        </div>
      </div>
    )
  }
}

SalesOrderInfoPage.propTypes = {}

export {SalesOrderInfoPage, SalesOrderInfo};


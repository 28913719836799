import React from 'react';
import {Link} from 'react-router-dom';
import Dropzone from 'react-dropzone';

import * as net from '../net';
import dao from '../dao';

const dropstyle = {width: 200, height: 200, borderWidth: 2, borderColor: '#666666', borderStyle: 'dashed', borderRadius: 5, margin: 5};


class UploadFilesSection extends React.Component {
  default_state = {
    is_loading: false,
    error: null,
    success_message: false,
  }

  constructor(props) {
    super(props);
    this.state = this.default_state;
  }

  onDrop = async(files) => {
    await this.uploadFiles(files)
  }

  uploadFiles = async(files) => {
    this.setState(this.default_state);
    try {
      await net.postFiles({url: '/api/reports/upload-files', files})
    } catch (e) {
      this.setState({is_loading: false, error: 'Problem processing files...'});
      return await null;
    }

    this.setState({is_loading: false, error: '', success_message: true});
  }

  render() {
    const {error, is_loading, success_message} = this.state;
    return (
      <div>
        <h3>Upload Files</h3>

        <p>
          Upload files to downloads folder.
        </p>

        <section>
          <div className="dropzone">
            <Dropzone
              onDrop={this.onDrop.bind(this)}
              disabled={this.state.is_loading}
            >
              {({getRootProps, getInputProps}) => (
                <div style={dropstyle} {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p>Drag and drop files here.</p>
                </div>
              )}
            </Dropzone>
          </div>
        </section>

        {!is_loading && (
          <div>
            {success_message && (
              <div className="alert alert-success">Finished upload</div>
            )}

            {error && (
              <div className="alert alert-danger">{error}</div>
            )}
          </div>
        )}

        <hr />
        <h3>Existing Files</h3>
        <DownloadFiles refresh={success_message} />
      </div>
    );
  }
}

class DownloadFiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {files: null};
  }

  componentWillReceiveProps(props) {
    const {refresh} = this.props;
    if (props.refresh !== refresh) {
      this.updateFileListState();
    }
  }

  updateFileListState = async() => {
    const files = await dao.getDownloadFiles();
    this.setState({files});
  }

  componentDidMount = async() => {
    this.updateFileListState();
  }

  handleDelete = async(item) => {
    await dao.deleteDownloadFile(item);
    this.updateFileListState();
  };

  render() {
    const {files} = this.state;

    if (!files) {
      return <div>Loading existing files...</div>
    }

    return (
      <table>
        <tbody>
          {files.map(item => (
            <tr>
              <td><Link to={item.url}>{item.name}</Link></td>
              <td>
                <p style={{paddingLeft:'20px',margin:'0',cursor:'pointer'}}
                  onClick={e => {if (window.confirm('Do you want to delete '+ item.name + '?')) this.handleDelete(item.name);}}>
                  <span role="img" aria-label={item.name}>&#10060;</span>
                </p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

const DownloadFilesPage = () => (
  <div className="container">
    <div className="row">
      <div className="mx-auto col-md-8">
        <h1>Downloads</h1>
        <UploadFilesSection />
      </div>
    </div>
  </div>
)


export default DownloadFilesPage;

import dao from '../dao';
import ItemStore from './ItemStore';

const ItemUtil = {};

// There should be separate "categories" of items
// Items that we are searching over, items that we are..., etc etc
ItemUtil.get_all_items = async () => {
  const is_fresh = ItemStore.get_is_fresh();
  if (is_fresh) {
    return ItemStore.get_items();
  }

  const fetched_items = await dao.getItems({});
  ItemStore.add_items(fetched_items);
  ItemStore.set_items_as_fresh();
  return ItemStore.get_items();
}

ItemUtil.get_item = async (sku) => {
  const is_fresh = ItemStore.get_is_fresh();
  if (is_fresh) {
    const item_from_store = ItemStore.get_item(sku);
    if (item_from_store) {
      return item_from_store;
    }
  }

  const item = await dao.getItem(sku);
  return item;
}

ItemUtil.round_dim = (dim) => {
  if (!dim) {
    return ''
  }
  return dim.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1});
}

ItemUtil.to_dims_string = (length, width, height) => {
  if (!length && !width && !height) {
    return ''
  }
  return `${ItemUtil.round_dim(length)}" x ${ItemUtil.round_dim(width)}" x ${ItemUtil.round_dim(height)}"`
}

ItemUtil.num_to_display = (num_value, num_value_min, num_digits, suffix = '') => {
  if (num_value == null) {
    return ''
  }
  const display = num_value.toLocaleString(undefined, {
    minimumFractionDigits: num_digits,
    maximumFractionDigits: num_digits,
  })

  if (num_value_min == null) {
    return display + suffix;
  }

  const display_min = num_value_min.toLocaleString(undefined, {
    minimumFractionDigits: num_digits,
    maximumFractionDigits: num_digits,
  })
  return display_min + suffix + ' - ' + display + suffix;
}

ItemUtil.dims_to_value = (component) => [
  {
    label: component.sku
  },
  {
    label: 'Carton Dims',
    value: ItemUtil.to_dims_string(component.carton_length, component.carton_height, component.carton_width)
  },
  {
    label: 'Carton Weight',
    value: ItemUtil.round_dim(component.carton_weight)
  },
  {
    label: 'Carton Volume',
    value: ItemUtil.round_dim(component.carton_volume)
  }
];

export default ItemUtil;

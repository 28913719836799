import React from 'react';

import {ContentsIoPage} from '../components/ContentsIoPage';

class CSCAct extends React.Component {
  render() {
    return (
      <div className="container">
        <ContentsIoPage
          contents_slug={'california-supply-chains-act'}
        />
      </div>
    )
  }
}

export default CSCAct;
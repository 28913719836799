import createReactClass from 'create-react-class';
import React from 'react';
import {Column, Table} from 'react-virtualized';

import './SearchItems.css';
import util from '../util';
import dao from '../dao';

const getS3Url = (s3_key) => {
  return `https://s3-us-west-2.amazonaws.com/eurostyle-item-photos/${s3_key}`;
};

const SearchItems = createReactClass({
  getInitialState() {
    return {
      search_term: '',
      items: null,
      filtered_items: null,

      isLoading: true,

      filterActive: true,
    }
  },

  async componentDidMount() {
    const items = await dao.getItems({is_shown_on_public_website: false, show_components: true});
    const newItems = items.filter(({status, price_msrp}) => price_msrp > 0);
    this.setState({
      filtered_items: newItems.filter(
        ({status, product_description}) => status === 'Active' && product_description),
      items: newItems,
      isLoading: false
    })
  },


  renderCell({columnIndex, key, rowIndex, style}) {
    const columns = ['img', 'sku', 'description', 'status', 'quantity_available'];
    const item = this.state.filtered_items[rowIndex];
    return (
      <div key={key} style={style} className="searchItems-tableCell">
        {columnIndex === 0 && (
          <img
            src={getS3Url(`product_images/${item.sku}.jpg-thumb`)}
            alt={item.original_file_name}
            style={{maxHeight: 70, maxWidth: 70}}
          />
        )}
        {columnIndex === 1 && (
          <a href={`/internal/item/${item.sku}`} target="_blank" rel="noopener noreferrer">
            {item.sku}
          </a>
        )}
        {columnIndex > 1 && item[columns[columnIndex]]}
      </div>
    )
  },

  updateFilter({toggleActiveFilter = false, toggleProductGridFilter = false, new_search_term}) {
    // Set active filter
    let filterActive = this.state.filterActive;
    if (toggleActiveFilter) {
      filterActive = !filterActive;
    }

    // Set search term
    let search_term = this.state.search_term;
    if (typeof new_search_term !== 'undefined') {
      search_term = new_search_term;
    }

    const filtered_items = this.state.items.filter((item) => {
      if (search_term && !util.filter_item_description(item, search_term)) {
        return false;
      }
      if (filterActive && item.status !== 'Active') {
        return false;
      }
      return true;
    });
    this.setState({filtered_items, filterActive, search_term})
  },

  render() {
    if (this.state.isLoading) {
      return <div>Loading...</div>;
    }

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-10 mx-auto">
            <h1>Find Items</h1>

            <div style={{marginTop: 30, marginBottom: 50}}>
              <input
                className="form-control"
                placeholder="Search for Items"
                onChange={(e) => this.updateFilter({new_search_term: e.target.value})}
                value={this.state.search_term}
              />

              <div className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    checked={this.state.filterActive}
                    onChange={() => this.updateFilter({toggleActiveFilter: true})}
                  />
                  Filter to only Active items
                </label>
              </div>
            </div>

            <Table
              width={800}
              height={800}
              headerHeight={50}
              rowHeight={80}
              rowCount={this.state.filtered_items.length}
              rowGetter={({index}) => this.state.filtered_items[index]}
              gridStyle={{margin: 'auto'}}
            >
              <Column
                dataKey="img"
                width={100}
                cellRenderer={({rowData}) => (
                  <img
                    src={getS3Url(`product_images/${rowData.sku}_01.jpg-thumb`)}
                    alt={rowData.original_file_name}
                    style={{maxHeight: 70, maxWidth: 70}}
                  />
                )}
              />
              <Column
                label="SKU"
                dataKey="sku"
                width={100}
                cellRenderer={({rowData}) => (
                  <a href={`/internal/item/${rowData.sku}`}>{rowData.sku}</a>
                )}
              />
              <Column
                dataKey="product_description"
                cellRenderer={({rowData}) => rowData.product_description || rowData.description_es}
                width={400}
                label="Description"
              />
              <Column dataKey="status" width={80} label="Status"/>
              <Column dataKey="quantity_available" width={50} label="QTY"/>
            </Table>
          </div>
        </div>
      </div>
    );
  }
})

export default SearchItems;

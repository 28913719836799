import React from 'react';

import ItemDAO from '../ItemDAO';
import ItemGrid from '../product/ItemGrid';
import ItemConstants from '../dashboard/ItemConstants';


class ECommerceProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: null,
    };
  }

  async componentDidMount() {
    const {items} = await ItemDAO.get_items_as_employee({
      is_ecommerce_product: true,
      status: ItemConstants.STATUS.ACTIVE,
    });
    this.setState({items});
  }

  render() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-12 mx-auto col-sm-8">
              <h3>eCommerce Products</h3>

              <p>
                eCommerce products. TODO: insert information about what it means to be an eCommerce product
              </p>
            </div>
          </div>
        </div>

        <hr />

        <ItemGrid items={this.state.items} non_filter_items={this.state.items}/>
      </div>
    );
  }
}

export default ECommerceProducts;

import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';
import _ from 'lodash';

import Percent from '../components/Percent';
import Price from '../components/Price';

const ThCenter = ({children}) => <th className="text-center">{children}</th>

const get_total = (infos, attribute, category) => (
  _.chain(infos)
    .filter((info) => (!category || info.category === category))
    .map((info) => info[attribute])
    .reduce((a, b) => a + b, 0)
    .value()
)

const CATEGORIES = [
  'ECOM', 'STCK', 'DESN', 'CNTC'
]

class CustomerInfoTable extends React.Component {
  render() {
    const {customer_infos, show_percents, show_num_orders, num_customers_to_show} = this.props;

    let customer_infos_to_show = customer_infos;

    if (num_customers_to_show) {
      customer_infos_to_show = customer_infos_to_show.slice(0, num_customers_to_show);
    }

    return (
      <table className="table table-sm table-striped">
        <thead>
          <tr>
            <th>ID</th>
            <ThCenter>Customer</ThCenter>
            <ThCenter>Category</ThCenter>
            <ThCenter>Units</ThCenter>
            {show_percents && <ThCenter>% of Units</ThCenter>}
            <ThCenter>Amount</ThCenter>
            {show_percents && <ThCenter>% of $</ThCenter>}
            {show_num_orders && <ThCenter>Num Orders</ThCenter>}
          </tr>
        </thead>
        <tbody>
          {customer_infos_to_show.map(
            ({customer, total_amount, total_items, percent_of_items, percent_of_amount, num_orders}) => (
              <tr>
                <td className="align-middle">
                  <Link to={`/internal/customers/${customer.id}`}>{customer.id}</Link>
                </td>
                <td className="align-middle">{customer.name}</td>
                <td className="align-middle">{customer.category}</td>
                <td className="text-right align-middle">{total_items}</td>
                {show_percents && (
                  <td className="text-right align-middle"><Percent percent={percent_of_items}/></td>
                )}
                <td className="text-right align-middle"><Price price={total_amount} num_digits={0}/></td>
                {show_percents && (
                  <td className="text-right align-middle"><Percent percent={percent_of_amount}/></td>
                )}
                {show_num_orders && (
                  <td className="text-right align-middle">{num_orders}</td>
                )}
              </tr>
            ))}
        </tbody>

        <tfoot className="table-info font-weight-bold">
          <tr>
            <td />
            <td />
            <td />
            <td className="text-right align-middle">
              {get_total(customer_infos, 'total_items')}
            </td>
            <td className="text-right align-middle">
              <Percent percent={get_total(customer_infos, 'percent_of_items')} num_digits={1}/>
            </td>
            <td className="text-right align-middle">
              <Price price={get_total(customer_infos, 'total_amount')}/>
            </td>
            <td className="text-right align-middle">
              <Percent percent={get_total(customer_infos, 'percent_of_amount')} num_digits={1}/>
            </td>
          </tr>

          {CATEGORIES.map((category) => (
            <tr key={category}>
              <td />
              <td />
              <td>
                {category}
              </td>
              <td className="text-right align-middle">
                {get_total(customer_infos, 'total_items', category)}
              </td>
              <td className="text-right align-middle">
                <Percent percent={get_total(customer_infos, 'percent_of_items', category)} num_digits={1}/>
              </td>
              <td className="text-right align-middle">
                <Price price={get_total(customer_infos, 'total_amount', category)}/>
              </td>
              <td className="text-right align-middle">
                <Percent percent={get_total(customer_infos, 'percent_of_amount', category)} num_digits={1}/>
              </td>
            </tr>
          ))}
        </tfoot>
      </table>
    )
  }
}


CustomerInfoTable.propTypes = {
  customer_infos: PropTypes.object.isRequired,
  show_percents: PropTypes.bool.isRequired,
  show_num_order: PropTypes.bool.isRequired,
  num_customers_to_show: PropTypes.number,
}

CustomerInfoTable.defaultProps = {
  show_percents: true,
  show_num_order: false,
}

export default CustomerInfoTable;


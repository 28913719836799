//import 'react-select/dist/react-select.css'
import 'react-virtualized/styles.css'
import "react-image-gallery/styles/css/image-gallery.css";

import PropTypes from 'prop-types';
import pathToRegexp from 'path-to-regexp';
import Raven from 'raven-js';
import React from 'react';
import {BrowserRouter, Redirect, Route, Switch, withRouter} from 'react-router-dom';
import {Helmet} from "react-helmet";


import PublicHome from '../one_off_pages/HomePage';
import ProductSearch from '../product/ProductSearch';
import CategoryHome from '../category/CategoryHome';
import NewProductsPage from '../category/NewProductsPage';
import ContractProductsPage from '../category/ContractProductsPage';
import ECommerceProducts from '../category/ECommerceProducts';
import HiddenProducts from '../category/HiddenProducts';
import OutdoorProducts from '../category/OutdoorProducts';
import ShowSpecialsPage from '../category/ShowSpecialsPage';
import SelectionPage from '../category/SelectionPage';
import FactoryPage from '../category/FactoryPage';
import SalePage from '../category/SalePage'
import FreeProductsPage from '../category/FreeProductsPage'
import IntroductionsPage from '../company_info/IntroductionsPage'
import PaymentTypesPage from '../company_info/PaymentTypesPage'
import ProductVideos from '../company_info/ProductVideos'

import BlogPage from '../blog/BlogHome';
import BlogPost from '../blog/BlogPost';

import ProductPage from '../product/ProductPage';

import {ProductScannerPage, CartScannerPage, InventoryScannerPage} from '../scanner/ProductScanner';

import AboutPages from './AboutPages';

import CatalogRequestForm from '../requests/CatalogRequestForm';
import SwatchRequestForm from '../requests/SwatchRequestForm';
import AppointmentForm from '../requests/AppointmentForm';
import RequestSuccess from '../requests/RequestSuccess';

import LasVegasInfo from '../one_off_pages/LasVegasInfo';
import FourOhFourPage from '../one_off_pages/404Page';

import PublicHeader from '../nav/PublicHeader';
import PublicFooter from '../nav/PublicFooter';
import NewAccountForm from '../requests/NewAccountForm';
import AccountApprovalForm from '../requests/AccountApprovalForm';

import LoginPage from '../auth/LoginPage';
import SignupPage from '../auth/SignupPage';
import ForgotPasswordPage from '../auth/ForgotPasswordPage';
import SetPasswordPage from '../auth/SetPasswordPage';

import AccountHome from '../profile/AccountHome';

import {CartOverview} from '../cart/CartOverview';

import {OrderInfoPage} from '../orders/OrderInfoPage';
import {OrderShippingPage} from '../orders/OrderShipping';
import {OrderPaymentPage} from '../orders/OrderPayment';
import LastOrdersPage from '../orders/LastOrdersPage';
import OrdersToEnterPage from '../orders/OrdersToEnterPage';

import {SalesOrderInfoPage} from '../sales_orders/SalesOrderInfo';

import {CreatePresentationPage} from '../presentations/CreatePresentationPage';
import {PresentationPage, PresentationListPage} from '../presentations/PresentationPage';

import {CustomerRequestAPartPage} from '../parts/PartsForm';
import {PartRequestPage} from '../parts/PartRequest';

import InternalApp from './InternalRouter'

import '../base.css';

const RedirectWithParams = ({exact, from, push, to}) => {
  const pathTo = pathToRegexp.compile(to);
  return (
    <Route exact={exact} path={from} component={({match: {params}}) => (
      <Redirect to={pathTo(params)} push={push}/>
    )}/>
  );
};

const PublicAppRoutes = ({match}) => {
  return (
    <Switch>
      {/* Require login for these routes -> */}
      <Route path="/cart" component={CartOverview}/>
      <Route path="/orders/info" component={LastOrdersPage}/>

      <Route path="/orders/:order_id/shipping" component={OrderShippingPage}/>
      <Route path="/orders/:order_id/payment" component={OrderPaymentPage}/>
      <Route path="/orders/:order_id" component={OrderInfoPage}/>

      <Route path="/last_orders" component={LastOrdersPage}/>
      <Route path="/orders_to_enter" component={OrdersToEnterPage}/>

      <Route path='/sales-orders/:sales_order_id' component={SalesOrderInfoPage}/>

      <Redirect from="/new-account" to="/requests/new-account"/>
      <Route path="/requests/account-approval" component={AccountApprovalForm}/>
      <Route path="/requests/new-account" component={NewAccountForm}/>

      <Route path="/part-requests/:part_request_id" component={PartRequestPage}/>

      <Route path="/account/request-a-part" component={CustomerRequestAPartPage}/>
      <Route path="/account" component={AccountHome}/>

      {/* Routes below are public*/}
      <Route exact path="/" component={PublicHome}/>

      <Route path="/login" component={LoginPage}/>
      <Route path="/signup" component={SignupPage}/>
      <Route path="/request-new-password" component={ForgotPasswordPage}/>
      <Route path="/set-password" component={SetPasswordPage}/>

      <Route path="/about/*" component={AboutPages}/>

      <Redirect from="/accessibility" to="/about/accessibility"/>
      <Redirect from="/ccpa-addendum" to="/about/ccpa-addendum"/>
      <Redirect from="/california-supply-chains-act" to="/about/california-supply-chains-act"/>
      <Redirect from="/personal-information-sales-opt-out-and-opt-in-rights" to="/about/personal-information-sales-opt-out-and-opt-in-rights"/>
      <Redirect from="/downloads" to="/about/downloads"/>
      <Redirect from="/terms" to="/about/terms"/>
      <Redirect from="/map" to="/about/map"/>
      <Redirect from="/privacy" to="/about/privacy"/>
      <Redirect from="/about" to="/about/history"/>

      <Redirect from="/swatchrequest" to="/requests/swatch"/>
      <Redirect from="/catalogs" to="/requests/catalog"/>
      <Redirect from="/office.html" to="/category/office"/>
      <Redirect from="/bar.html" to="/category/bar"/>
      <Redirect from="/office/*" to="/category/office"/>
      <Redirect from="/dining/*" to="/category/dining"/>
      <Redirect from="/bar/*" to="/category/bar"/>
      <Redirect from="/occasional/*" to="/category/occasional"/>
      <Redirect from="/outdoor/*" to="/category/outdoor"/>
      <Redirect from="/dining.html" to="/category/dining"/>
      <Redirect from="/occasional.html" to="/category/occasional"/>
      <Redirect from="/whats-new.html" to="/whats-new"/>
      <Redirect exact from="/sale" to="/sale/All"/>

      <Route path="/whats-new" component={NewProductsPage}/>
      <Route path="/show-specials" component={ShowSpecialsPage}/>
      <Route path="/contract-products" component={ContractProductsPage}/>
      <Route path="/product-selection" component={SelectionPage}/>
      <Route path="/ecommerce-products" component={ECommerceProducts}/>
      <Route path="/hidden-products" component={HiddenProducts}/>
      <Route path="/outdoor-products" component={OutdoorProducts}/>
      <Route path="/category/:category/:product_group" component={CategoryHome}/>
      <Route path="/category/:category" component={CategoryHome}/>
      <Route path="/factory/:factory_code" component={FactoryPage}/>
      <Route path="/sale/:sales_group" component={SalePage}/>
      <Route path="/free-products" component={FreeProductsPage}/>
      <Route path="/introductions" component={IntroductionsPage}/>
      <Route path="/payment-types" component={PaymentTypesPage}/>
      <Route path="/product-videos" component={ProductVideos}/>

      <RedirectWithParams from="/product/:sku" to="/products/:sku"/>
      <RedirectWithParams from="/p/:sku" to="/products/:sku"/>

      <Route exact path="/newsletter" component={BlogPage}/>
      <Route path="/newsletter/:slug" component={BlogPost}/>
      <RedirectWithParams from="/blog/:slug" to="/newsletter/:slug"/>

      <Route path="/products/:sku" component={ProductPage}/>

      <Route path="/search" component={ProductSearch}/>

      <Route path="/scanner-for-inventory" component={InventoryScannerPage}/>
      <Route path="/scanner-for-cart" component={CartScannerPage}/>
      <Route path="/scanner" component={ProductScannerPage}/>

      <Route path="/requests/catalog" component={CatalogRequestForm}/>
      <Route path="/requests/swatch" component={SwatchRequestForm}/>
      <Route path="/requests/appointment" component={AppointmentForm}/>
      <Route path="/requests/success" component={RequestSuccess}/>

      <Route exact={true} path="/presentations" component={PresentationListPage}/>
      <Route path="/presentations/create" component={CreatePresentationPage}/>
      <Route path="/presentations/:presentation_url_key" component={PresentationPage}/>

      <Route path="/shows/las-vegas" component={LasVegasInfo}/>

      <Route path="*" component={FourOhFourPage}/>
    </Switch>
  )
}


const PublicApp = ({match}) => {
  return (
    <div>
      <Helmet>
        <meta
          name="description"
          content={`Euro Style specializes in contemporary furniture at affordable prices. The ever-growing collection for living room, bar, dining room and home office brings cutting-edge contemporary style from Euro Style’s designers in Europe and factories in Italy and Asia. Customers can order Euro Style products from the US warehouse or by factory direct container programs. The company ships out of its Northern California Warehouse the next business day after orders are placed.`}/>

        <meta property="og:title" content="Euro Style - The Right Design, The Right Price"/>
        <meta property="og:type" content="article"/>
        <meta property="og:image" content="https://euro.style/static/splash_photos/1.jpg"/>
        <meta property="og:description"
              content="Euro Style specializes in contemporary furniture at affordable prices. The ever-growing collection for living room, bar, dining room and home office brings cutting-edge contemporary style from Euro Style’s designers in Europe and factories in Italy and Asia."/>
      </Helmet>

      <PublicHeader />

      <PublicAppRoutes match={match}/>

      <PublicFooter />
    </div>
  );
}


class ESAppContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount = async() => {
    this.props.history.listen((location, action) => {
      if (action === 'REPLACE') {
        return;
      }

      window.analytics.page();
    });
  }

  render() {
    return (
      <div>
        <Switch>
          <Route path="/internal" component={InternalApp}/>
          <Route path="/" component={PublicApp}/>
        </Switch>
      </div>
    )
  }
}

ESAppContainer.propTypes = {
  history: PropTypes.object.isRequired,
}


const AppContainer = withRouter(ESAppContainer);

class ESRouter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      has_error: false,
    }
  }

  componentDidMount() {
    Raven.config(window.SENTRY_PUBLIC_DSN).install();
  }

  componentDidCatch(error) {
    this.setState({has_error: true});
    Raven.captureException(error);
  }

  render() {
    const {has_error} = this.state;
    if (has_error) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p>
                I'm sorry, there was an error with the website. Try refreshing to reload.
              </p>

              <p>
                If you keep running into this issue, email <a
                href="mailto:cs@euro.style">cs@euro.style</a>.
              </p>
            </div>
          </div>
        </div>
      )
    }

    return (
      <BrowserRouter>
        <AppContainer/>
      </BrowserRouter>
    );
  }
}

export default ESRouter;

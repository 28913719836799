import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import reactUpdate from 'react-addons-update';

import {AddressFields} from '../components/AddressFields';


const SelectField = ({fieldKey, value, updateValue, required, options, disabled}) => {
  return (
    <select
      className="form-control"
      name={fieldKey}
      required={required}
      onChange={(e) => updateValue(e.target.value)}
      value={value}
      disabled={disabled}
    >
      {options.map((option, index) => {
        let display, optionValue;
        if (typeof option === 'string') {
          display = option;
          optionValue = option;
        } else {
          display = option.display;
          optionValue = option.value;
        }
        return (
          <option key={index} value={optionValue}>{display}</option>
        )
      })}
    </select>
  );
};

SelectField.propTypes = {
  fieldKey: PropTypes.string,
  value: PropTypes.string.isRequired,
  updateValue: PropTypes.func.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  options: PropTypes.array.isRequired,
}


const FormInput = ({
  fieldKey, value, updateValue, display, type, required, infoNode, large, options, step, disabled, className
}) => {
  const class_name = classNames('col-12', {'col-sm-6': !large}, className);

  let input_node = null;

  if (type === 'select') {
    input_node = (
      <SelectField
        fieldKey={fieldKey}
        disabled={disabled}
        value={value}
        updateValue={updateValue}
        required={required}
        options={options}
      />
    );
  } else if (type === 'address') {
    input_node = (
      <AddressFields
        disabled={disabled}
        update_address={(new_value_to_merge) => {
          const new_value = reactUpdate(value, {$merge: new_value_to_merge});
          updateValue(new_value);
        }}
        {...value}
      />
    );
  } else {
    const InputDOMNode = type === 'textarea' ? 'textarea' : 'input';

    input_node = (
      <InputDOMNode
        className="form-control"
        disabled={disabled}
        name={fieldKey}
        type={type}
        required={required}
        onChange={(e) => updateValue(e.target.value)}
        value={value}
        step={step}
      />
    );
  }


  return (
    <div className={class_name}>
      <div className="form-group">
        <label className="font-weight-bold">
          {display}
          {required && <b style={{color: "red"}}> *</b>}
        </label>
        {infoNode}
        {input_node}
      </div>
    </div>
  )
};

FormInput.propTypes = {
  // fieldKey - name field of input, useful for autocomplete
  fieldKey: PropTypes.string,
  // value - user value of the input
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  // updateValue - function to pass updated value, takes in the new value as the argument
  updateValue: PropTypes.func.isRequired,
  // display - label for the field
  display: PropTypes.string.isRequired,
  // type - address|text|textarea|select
  type: PropTypes.string.isRequired,
  // required - bool
  required: PropTypes.bool.isRequired,
  // large - span whole row with field
  large: PropTypes.bool.isRequired,
  // options - used with type == select
  options: PropTypes.array,
  // infoNode - information about the field to help the user fill out
  infoNode: PropTypes.node,
  // disabled - disable the input of new data
  disabled: PropTypes.bool,
}

FormInput.defaultProps = {
  type: 'text',
  required: false,
  large: false,
  disabled: false,
}


const get_initial_state_from_field_definitions = (field_definitions) => {
  const initial_state = {};

  field_definitions.forEach((field_definition) => {
    const {key, initial_value} = field_definition;
    initial_state[key] = initial_value || '';
  });

  return initial_state;
}

export {FormInput, get_initial_state_from_field_definitions};

import PropTypes from 'prop-types';
import React from 'react';
import reactUpdate from 'react-addons-update';
import {Link} from 'react-router-dom';

import UserStore from '../auth/UserStore';
import OrderDAO from './OrderDAO';
import AddressDisplay from '../components/AddressDisplay';
import {AddressFields, EMPTY_SHIPPING_ADDRESS} from '../components/AddressFields';
import {QuoteCards} from '../shipping/QuoteComponents'
import ShippingDAO from '../shipping/ShippingDAO'
import {OrderNavProgress} from './OrderNavProgress';


class OrderShippingAddress extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      is_loading: false,
      is_edit_mode: !props.shipping_address,

      new_shipping_address: props.shipping_address || EMPTY_SHIPPING_ADDRESS,
    };
  }

  update_shipping_address = async(e) => {
    e.preventDefault();

    this.setState({is_loading: true})

    const {new_shipping_address} = this.state;
    await this.props.update_shipping_address(new_shipping_address)

    this.setState({is_loading: false, is_edit_mode: false})
  }

  render() {
    const {is_editable, shipping_address} = this.props;
    const {is_loading, new_shipping_address, is_edit_mode} = this.state;

    if (is_loading) {
      return <div>Loading shipping address...</div>;
    }

    if (is_edit_mode) {
      return (
        <div className="mb-4">
          {this.props.show_warning && ((new_shipping_address == null) || (new_shipping_address.address_1 === "") ||
            (new_shipping_address.name === "") || (new_shipping_address.zip_code === "")) && (
            <div style={{paddingBottom: "20px"}}>
              Your order already created. Please enter shipping information to complete the order.
            </div>
          )}
          <strong>Address</strong>
          <form onSubmit={this.update_shipping_address}>
            <AddressFields
              update_address={(new_obj) => {
                const new_shipping_address = reactUpdate(this.state.new_shipping_address, {$merge: new_obj});
                this.setState({new_shipping_address});
              }}
              {...new_shipping_address}
            />

            <button type='submit' className="btn btn-success">
              Update Shipping Address
            </button>
          </form>
        </div>
      )
    }

    return (
      <div>
        <div>
          <strong>Shipping Address</strong> {' '}
          {is_editable && (
            <span
              className="btn btn-sm btn-link"
              onClick={(e) => this.setState({is_edit_mode: true})}
            >
              Edit
            </span>
          )}
        </div>
        {this.props.show_warning && ((shipping_address == null) || (shipping_address.address_1 === "") ||
         (shipping_address.name === "") || (shipping_address.zip_code === "")) && (
          <div style={{paddingBottom: "20px"}}>
            Your order already created. Please enter shipping information to complete the order.
          </div>
        )}
        <AddressDisplay {...shipping_address} />
      </div>
    );
  }
}

OrderShippingAddress.propTypes = {
  shipping_address: PropTypes.object,
  update_shipping_address: PropTypes.func.isRequired,
  is_editable: PropTypes.bool.isRequired,
}

OrderShippingAddress.defaultProps = {
  is_editable: true,
}

const DisplayFreightQuotes = ({
  pallets, quotes, order_subtotal
}) => (
  <div>
    <FreightBetaDisclaimer/>

    <strong>Pallets</strong>
    <div className="row mb-3">
      {pallets.map((pallet, i) => (
        <div className="col-6 col-md-4 col-lg-3">
          <div className="card mb-3">
            <div className="card-body">
              <div className="card-title font-weight-bold">Pallet {i + 1}</div>

              <div className="mb-1">{pallet.num_items} Items</div>
              <div className="mb-1">{pallet.weight.toFixed(0)} lbs</div>
              <div className="mb-1">{pallet.volume.toFixed(1)} ft<sup>3</sup></div>
              <div className="mb-1">Class {pallet.freight_class}</div>
              <div className="mb-1">{pallet.pallet_size} Pallet</div>
            </div>
          </div>
        </div>
      ))}
    </div>

    <strong>Quotes</strong>

    <QuoteCards
      quotes={quotes}
      order_subtotal={order_subtotal}
    />
  </div>
)

DisplayFreightQuotes.propTypes = {
  pallets: PropTypes.array.isRequired,
  quotes: PropTypes.array.isRequired,
  order_subtotal: PropTypes.number,
}

class OrderFreightQuotesContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: UserStore.get_user(),
      quotes: null,
      pallets: null,
    }
  }

  componentDidMount = async() => {
    UserStore.on('change', this.setUserFromStore);

    await this.get_freight_quote();
  }

  componentWillUnmount() {
    UserStore.removeListener('change', this.setUserFromStore);
  }

  setUserFromStore = () => {
    this.setState({user: UserStore.get_user()})
  }

  get_freight_quote = async() => {
    const {order_id} = this.props;
    const {quote_information} = await OrderDAO.get_freight_quotes(order_id)
    const {quotes, pallets} = quote_information;
    this.setState({quotes, pallets});
  }

  render() {
    const {order_subtotal} = this.props;
    const {quotes, pallets} = this.state;

    if (!quotes || !pallets) {
      return <div>Requesting freight quote...</div>
    }

    return (
      <DisplayFreightQuotes
        pallets={pallets}
        quotes={quotes}
        order_subtotal={order_subtotal}
      />
    )
  }
}

OrderFreightQuotesContainer.propTypes = {
  order_id: PropTypes.number.isRequired,
  destination_zip: PropTypes.string.isRequired,
  require_lift_gate: PropTypes.bool.isRequired,
  update_delivery_options: PropTypes.func.isRequired,
}

const FreightBetaDisclaimer = () => (
  <div className="alert alert-warning">
    Freight quotes are still in beta. Confirm with your customer service representative.
  </div>
)

class RequestFreightQuoteForOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_getting_freight_quotes: false,
      require_lift_gate: false,
    }
  }

  get_freight_quotes = (e) => {
    e.preventDefault();
    this.setState({is_getting_freight_quotes: true});
  }

  render() {
    const {is_getting_freight_quotes, require_lift_gate} = this.state;
    const {order_id, destination_zip, order_subtotal} = this.props;

    if (is_getting_freight_quotes) {
      return (
        <OrderFreightQuotesContainer
          order_id={order_id}
          destination_zip={destination_zip}
          order_subtotal={order_subtotal}
          require_lift_gate={require_lift_gate}
          update_delivery_options={() => this.setState({is_getting_freight_quotes: false})}
        />
      );
    }

    return (
      <div>
        <form className="form-inline mb-4" onSubmit={this.get_freight_quotes}>
          <button type="submit" className="btn btn-success">Get Freight Quotes</button>
        </form>

        <FreightBetaDisclaimer/>
      </div>
    )
  }
}

RequestFreightQuoteForOrder.propTypes = {
  order_id: PropTypes.number.isRequired,
  order_subtotal: PropTypes.number,
  customer_zip: PropTypes.string,
}

class RequestFreightQuoteGeneric extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: false,

      quotes: null,
      pallets: null,
    }
  }

  get_freight_quotes = async() => {
    this.setState({is_loading: true});

    const {sku_to_quantity, destination_address} = this.props;
    const request_data = {sku_to_quantity, destination_address};

    const {quote_information} = await ShippingDAO.get_freight_quotes(request_data)
    const {quotes, pallets} = quote_information;

    this.setState({is_loading: false, quotes, pallets})
  }

  render() {
    const {order_subtotal} = this.props;
    const {is_loading, quotes, pallets} = this.state;

    if (is_loading) {
      return <div className="alert alert-warning">Loading freight quotes...</div>;
    }

    if (!quotes && !pallets) {
      return (
        <div>
          <button className="btn btn-success" onClick={this.get_freight_quotes}>
            Get Freight Quotes
          </button>

          <FreightBetaDisclaimer/>
        </div>
      )
    }

    return (
      <DisplayFreightQuotes
        pallets={pallets}
        quotes={quotes}
        order_subtotal={order_subtotal}
      />
    )
  }
}

RequestFreightQuoteGeneric.propTypes = {
  destination_address: PropTypes.object.isRequired,
  sku_to_quantity: PropTypes.object.isRequired,
  order_subtotal: PropTypes.number,
}

class OrderShipping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {order, update_shipping_address_on_server, shipping_address} = this.props;

    return (
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-8 col-lg-6 mx-auto">
            <OrderNavProgress active_section="shipping"/>

            <h2 className="mb-4">Order {order.id}</h2>

            <OrderShippingAddress
              shipping_address={shipping_address}
              update_shipping_address={update_shipping_address_on_server}
              show_warning={true}
            />

            <div className="mt-5">
              {shipping_address ? (
                  <Link className="btn btn-success" to={`/orders/${order.id}/payment`}>
                    Done with Shipping Info
                  </Link>
                ) : (
                  <Link className="btn btn-link btn-sm" to={`/orders/${order.id}/payment`}>
                    Skip Shipping Info
                  </Link>
                )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

OrderShipping.propTypes = {
  order: PropTypes.object.isRequired,
  shipping_address: PropTypes.object,
}

class OrderShippingInfoWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      is_loading: true,

      order: null,
      shipping_address: null,
    };
  }

  componentDidMount = async() => {
    const {order_id} = this.props;

    const {order} = await OrderDAO.get_order(order_id)
    const {shipping_address} = await OrderDAO.get_shipping_address(order_id);
    this.setState({order, shipping_address});

    this.setState({is_loading: false})
  }

  update_shipping_address_on_server = async(new_shipping_address) => {
    const {order_id} = this.props;

    const {shipping_address} =
      await OrderDAO.update_shipping_address(order_id, {...new_shipping_address})

    this.setState({shipping_address});
  }

  render() {
    const {is_loading, order, shipping_address} = this.state;

    if (is_loading) {
      return <div className="alert alert-warning">Loading shipping info...</div>;
    }

    const {render} = this.props;

    return render({
      order,
      shipping_address,
      update_shipping_address_on_server: this.update_shipping_address_on_server,
    })
  }
}

OrderShippingInfoWrapper.propTypes = {
  render: PropTypes.func.isRequired,
  order_id: PropTypes.number.isRequired,
}


const OrderShippingPage = ({match}) => {
  const {order_id} = match.params;
  return (
    <OrderShippingInfoWrapper
      render={({order, shipping_address, update_shipping_address_on_server}) => (
        <OrderShipping
          order={order}
          shipping_address={shipping_address}
          update_shipping_address_on_server={update_shipping_address_on_server}
        />
      )}
      order_id={order_id}
    />
  )
}

OrderShippingPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      order_id: PropTypes.string.isRequired,
    }),
  }),
}


export {
  OrderShipping,
  OrderShippingInfoWrapper,
  OrderShippingPage,
  DisplayFreightQuotes,
  OrderShippingAddress,
  RequestFreightQuoteForOrder,
  RequestFreightQuoteGeneric,
};


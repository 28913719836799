import React from 'react';

class ShowroomsPage extends React.Component {
  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2>Our <span className="bold">Showrooms</span></h2>

            <h3>Las Vegas</h3>

            <div className="row">
              <div className="col-12 col-sm-6">
                <iframe
                  src="https://walkinto.in/tour/ZJe7Lf2vPW-1xgmUG2wvW/Euro_Style"
                  title="Las Vegas"
                  width="100%"
                  height="250"
                  frameBorder="0"
                  style={{border: 0, float: 'left', paddingRight: 20}}
                  allowFullScreen
                />
              </div>

              <div className="col-12 col-sm-6">
                World Market Center<br />
                B-601, B-675<br />
                475 South Grand Central Parkway<br />
                Las Vegas, NV 89106<br />
              </div>
            </div>

            <hr />

            <h3>High Point</h3>

            <div className="row">
              <div className="col-12 col-sm-6">
                <iframe
                  src="https://my.matterport.com/show/?m=hbSSQxvJZbY"
                  title="High Point"
                  width="100%"
                  height="250"
                  frameBorder="0"
                  style={{border: 0, float: 'left', paddingRight: 20}}
                  allowFullScreen
                />
              </div>

              <div className="col-12 col-sm-6">
                Showplace Building<br />
                3rd Floor<br />
                211 E Commerce Ave<br />
                High Point, NC 27260<br />
              </div>
            </div>

            <hr />

            <h3>HD Expo – Las Vegas, NV</h3>

            <div className="row">
              <div className="col-12 col-sm-6">
                <img src="/static/showroom_photos/hdbooth.jpg" width="100%" alt=""/>
              </div>

              <div className="col-12 col-sm-6">
                Mandalay Bay Convention Center<br />
                Booth #4445<br />
                3950 S. Las Vegas Blvd<br />
                Las Vegas, NV 89119<br />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ShowroomsPage;
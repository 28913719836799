import PropTypes from 'prop-types';
import React from 'react';
import Markdown from 'react-markdown';
import {Link} from 'react-router-dom';

import ItemReportDAO from './ItemReportDAO';
import {FormInput} from '../requests/FormInput';
import {ItemReportForm} from './CreateItemReport';

const convert_sku_text_to_skus = (sku_text) => (
  sku_text.split('\n').map((sku) => sku.trim()).filter((sku) => sku)
)

class ItemReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sku_text: '',
      query_function: '',
      active_items: false,
    };
  }

  run_item_report = async(e) => {
    e.preventDefault();

    const {item_report} = this.props;
    const {id: item_report_id} = item_report;

    const {sku_text, active_items, query_function} = this.state;

    const options = {
      query_function,
    };

    if (active_items) {
      options['active_items'] = true;
    } else {
      options['skus'] = convert_sku_text_to_skus(sku_text);
    }

    const {s3_url} = await ItemReportDAO.run_item_report(item_report_id, options)
    window.open(s3_url)
  }

  render() {
    const {item_report} = this.props;
    const {name, description, created_by_user_email} = item_report;

    const {sku_text, query_function} = this.state;

    return (
      <div>
        <h3>{name} {' '}
          <small>Item Report</small>
        </h3>

        <div>
          Created by {created_by_user_email}
        </div>

        <Markdown
          className="mt-2"
          source={description}
        />

        <div className="mt-2">
          <Link to={`/internal/item-reports/${item_report.id}/edit`}>Edit Report</Link>
        </div>

        <form onSubmit={this.run_item_report} className="mt-3">
          <FormInput
            value={sku_text}
            updateValue={(sku_text) => this.setState({sku_text})}
            display="SKUs"
            type="textarea"
            large={true}
          />

          <FormInput
            value={query_function}
            infoNode={<div>This is for advanced usage. Ask Victor.</div>}
            updateValue={(query_function) => this.setState({query_function})}
            display="Lambda Function for Query Transform"
            type="textarea"
            large={false}
          />

          <button className="btn btn-success" type="submit">
            Run Report
          </button>
        </form>
      </div>
    )
  }
}

ItemReport.propTypes = {}

class ItemReportWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: true,
      error: null,

      item_report: null,
      columns: null,
    }
  }

  componentDidMount = async() => {
    const {item_report_id, get_columns} = this.props;
    const {item_report} = await ItemReportDAO.get_item_report(item_report_id);

    this.setState({item_report});

    if (get_columns) {
      const {columns} = await ItemReportDAO.get_item_report_columns(item_report_id);
      this.setState({columns});
    }

    this.setState({is_loading: false});
  }

  render() {
    const {render} = this.props;
    const {is_loading, error, item_report, columns} = this.state;

    if (error) {
      return <div className="alert alert-danger">{error}</div>;
    }

    if (is_loading) {
      return <div className="alert alert-warning">Loading...</div>;
    }

    return render({item_report, columns});
  }
}

ItemReportWrapper.propTypes = {
  render: PropTypes.func.isRequired,
  item_report_id: PropTypes.string.isRequired,
  get_columns: PropTypes.bool,
}

const ItemReportPage = ({match}) => (
  <div className="container">
    <div className="row">
      <div className="col-12 col-md-10 mx-auto">
        <ItemReportWrapper
          item_report_id={match.params.item_report_id}
          render={({item_report}) => (
            <ItemReport item_report={item_report}/>
          )}
        />
      </div>
    </div>
  </div>
)

const EditItemReportPage = ({match, history}) => (
  <div className="container">
    <div className="row">
      <div className="col-12 col-md-10 mx-auto">
        <ItemReportWrapper
          item_report_id={match.params.item_report_id}
          get_columns={true}
          render={({item_report, columns}) => (
            <ItemReportForm
              history={history}
              initial_item_report={item_report}
              initial_columns={columns}
              update_existing_item_report={ItemReportDAO.update_item_report.bind(null, item_report.id)}
            />
          )}
        />
      </div>
    </div>
  </div>
)


export {ItemReportPage, EditItemReportPage};


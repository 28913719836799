import createReactClass from 'create-react-class';
import queryString from 'query-string';
import React from 'react';
import Dropzone from 'react-dropzone';
import * as net from "../net"

import GenerateProductTags from './GenerateProductTags';
import GenerateInventoryReports from './GenerateInventoryReports';
import ExportCustomColumnsReport from './ExportCustomColumnsReport';

const dropstyle = {width: 200, height: 200, borderWidth: 2, borderColor: '#666666', borderStyle: 'dashed', borderRadius: 5, margin: 5};


const PhotosReportSection = createReactClass({
  getInitialState() {
    return {only_active: true}
  },

  render() {
    return (
      <div>
        <h4>Photos Report</h4>
        <p>
          Get information on how many photos we have for each item.
        </p>

        <div className="checkbox">
          <label>
            <input
              type="checkbox"
              checked={this.state.only_active}
              onChange={() => this.setState({only_active: this.state.only_active})}
            />
            Filter to only Active items
          </label>
        </div>

        <a
          className="btn btn-success"
          href={`/api/reports/item-photos?only_active=${this.state.only_active}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Download Report
        </a>
      </div>
    )
  }
})

const ExportProductImagesCsv = createReactClass({
  getInitialState() {
    return {sku_text: ''}
  },

  exportProductImages() {
    const skus = this.state.sku_text.split('\n').map((sku) => sku.trim()).filter((sku) => sku);
    const product_grid_url = '/api/items/actions/export-image-urls?' + queryString.stringify({skus});
    window.open(product_grid_url);
  },

  render() {
    return (
      <div>
        <h3>Export Product Images</h3>

        <p>
          Enter a list of SKUs on each line to export image urls for those products. The CSV will have
          one column for the sku and the rest will be image URLs.
        </p>

        <textarea
          className="form-control"
          value={this.state.sku_text}
          placeholder='Enter skus'
          onChange={(e) => this.setState({sku_text: e.target.value})}
          style={{marginBottom: 20}}
        />

        <div className="btn btn-success" onClick={() => this.exportProductImages()}>
          Export Product Images
        </div>
      </div>
    )
  }
})

class UpdateItemAttrsFromCsv extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: false,
      error: null,
      skus_not_found: [],
      success_message: false,
    };
  }

  onDrop = async(files) => {
    await this.uploadCsvToUpdateItems(files[0])
  }

  uploadCsvToUpdateItems = async(file) => {
    this.setState({
      is_loading: true,
      error: null,
      skus_not_found: [],
      success_message: false,
    });

    try {
      const {skus_not_found} = await net.postFiles({url: '/api/reports/update-attributes', files: [file]})
      this.setState({skus_not_found, success_message: true, is_loading: false, error: ''});
    } catch (e) {
      let error = 'Problem processing files';

      const {responseJSON} = e;
      if (responseJSON) {
        const {error_message} = responseJSON;

        if (error_message) {
          error = error_message;
        }
      }

      this.setState({is_loading: false, error, success_message: ''});
    }
  }

  render() {
    const {error, skus_not_found, is_loading, success_message} = this.state;
    return (
      <div>
        <h3>Update Item Attributes from CSV</h3>
        <p>
          Drop CSV with headers that correspond to db files.
        </p>
        <section>
          <div className="dropzone">
            <Dropzone
              multiple={false}
              onDrop={this.onDrop.bind(this)}
              disabled={this.state.is_loading}
            >
              {({getRootProps, getInputProps}) => (
                <div style={dropstyle} {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p>Drop in a csv, then it will be uploaded to the server.</p>
                </div>
              )}
            </Dropzone>
          </div>
        </section>

        {is_loading ? (
            <div className="alert alert-warning">Loading...</div>
          ) : (
            <div>
              {success_message && (
                <div className="alert alert-success">Finished upload</div>
              )}

              {error && (
                <div className="alert alert-danger">{error}</div>
              )}

              {Boolean(skus_not_found.length) && (
                <div className="alert alert-danger">
                  Skus not found
                  <ul>
                    {skus_not_found.map((sku) => <li key={sku}>{sku}</li>)}
                  </ul>
                </div>
              )}
            </div>
          )}
      </div>
    );
  }
}

class ExportCatalogRequests extends React.Component {
  render() {
    return (
      <div>
        <h3>Export Catalog Requests</h3>
        <p>
          This report will export Excel file with all catalog requests.
        </p>
        <a
          className="btn btn-success"
          href={`/api/reports/catalog-requests`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Export Requests
        </a>
      </div>
    );
  }
}

const ReportsHome = () => (
  <div className="row">
    <div className="mx-auto col-md-8">
      <h1>Reports Home</h1>

      <PhotosReportSection />

      <hr />

      <GenerateInventoryReports/>

      <hr />

      <GenerateProductTags/>

      <hr />

      <ExportProductImagesCsv/>

      <hr />

      <UpdateItemAttrsFromCsv />

      <hr />

      <ExportCustomColumnsReport />

      <hr />

      <ExportCatalogRequests />
    </div>
  </div>
)


export default ReportsHome;

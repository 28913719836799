import PropTypes from 'prop-types';
import React from 'react';
import ReactTooltip from 'react-tooltip'
import FontAwesome from 'react-fontawesome';

import util from '../util';
import EdiDAO from './EdiDAO';
import DescriptionList from '../components/DescriptionList';

class EdiFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  static get_text_with_line_breaks(text_raw, segment_delimiter) {
    return text_raw.replace(new RegExp(`\\${segment_delimiter}`, 'g'), `${segment_delimiter}\n`);
  }

  render() {
    const {edi_file} = this.props;
    const {id, text_raw, segment_delimiter} = edi_file;

    return (
      <div>
        <h3>EDI File - {id}</h3>

        <DescriptionList
          description_schemas={[
            {
              label: 'Created At',
              render_func: ({created_at}) => util.date_format_iso_to_day(created_at)
            },
            {label: 'Source', attribute: 'source'},
            {
              label: 'File Name',
              render_func: ({source_file_name}) => (
                <span>
                  {source_file_name.substring(0, 15)}... {' '}
                  <FontAwesome name="info-circle" data-tip={source_file_name}/><ReactTooltip />
                </span>
              )
            },
            {label: 'Document Delim', attribute: 'document_delimiter'},
            {label: 'Segment Delim', attribute: 'segment_delimiter'},
            {label: 'Element Delim', attribute: 'element_delimiter'},
            {label: 'Interchange Number', attribute: 'interchange_control_number'},
            {label: 'Interchange Receiver', attribute: 'interchange_receiver_id'},
            {label: 'Interchange Sender', attribute: 'interchange_sender_id'},
            {label: 'Interchange Version', attribute: 'interchange_version_id'},
            {label: 'Customer', attribute: 'customer_id'},
            {label: 'Test Mode', render_func: ({is_test_mode}) => JSON.stringify(is_test_mode)},
          ]}
          data={edi_file}
        />

        <h5>Text</h5>

        <pre>
          {EdiFile.get_text_with_line_breaks(text_raw, segment_delimiter)}
        </pre>

      </div>
    )
  }
}

EdiFile.propTypes = {
  edi_file: PropTypes.object.isRequired,
}

class EdiFileWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: true,
      error: null,

      edi_file: null,
    }
  }

  componentDidMount = async() => {
    const {edi_file_id} = this.props;
    const {edi_file} = await EdiDAO.get_edi_file(edi_file_id);

    this.setState({edi_file, is_loading: false});
  }

  render() {
    const {render} = this.props;
    const {is_loading, error, edi_file} = this.state;

    if (error) {
      return <div className="alert alert-danger">{error}</div>;
    }

    if (is_loading) {
      return <div className="alert alert-warning">Loading...</div>;
    }

    return render({edi_file});
  }
}

EdiFileWrapper.propTypes = {
  render: PropTypes.func.isRequired,
  edi_file_id: PropTypes.string.isRequired,
}

const EdiFilePage = ({match}) => (
  <div className="container">
    <div className="row">
      <div className="col-12 col-md-10 mx-auto">
        <EdiFileWrapper
          edi_file_id={match.params.edi_file_id}
          render={({edi_file}) => (
            <EdiFile edi_file={edi_file}/>
          )}
        />
      </div>
    </div>
  </div>
)

export {EdiFilePage};


import React from 'react';

import dao from '../dao';
import ItemGrid from '../product/ItemGrid';


class FreeProductsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      new_items: null,
    };
  }

  async componentDidMount() {
    const new_items = await dao.getItems({
      free_products: true
    });
    this.setState({new_items});
  }

  render() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-12 mx-auto col-sm-8">
              <h3>Truckload Products</h3>
            </div>
          </div>
        </div>
        <hr />
        {this.state.new_items && this.state.new_items !== '[]' && (
            <ItemGrid items={this.state.new_items} non_filter_items={this.state.new_items}/>
        )}
      </div>
    );
  }
}

export default FreeProductsPage;

import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';

import {OrderNavProgress, ORDER_PROGRESS_TAB_PAYMENT} from './OrderNavProgress';
import OrderDAO from './OrderDAO';
import CustomerDAO from '../customers/CustomerDAO';
import {CardsTable, AddCardViaStripeCheckout} from '../pay/PaymentForm';

class OrderPayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: true,
    };
  }

  choose_card_for_payment = async(credit_card_stripe_id) => {
    const {order} = this.props;
    await OrderDAO.update_order(order.id, {credit_card_stripe_id, payment_type: 'Credit Card'});

    this.props.history.push(`/orders/${order.id}`);
  }

  render() {
    const {order, customer_credit_cards} = this.props;

    return (
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-8 col-lg-6 mx-auto">
            <OrderNavProgress active_section={ORDER_PROGRESS_TAB_PAYMENT}/>

            <h2 className="mb-5">Payment for Order {order.id}</h2>

            <div className="mb-5">
              <h4 className="mb-3">Pay by Credit Card</h4>

              <p className="mb-3">
                Choosing or adding your card will not charge your card. You will be
                contacted for confirmation before your card is charged.
              </p>

              { order && order.customer && order.customer.state && (!['ME','OK','CT','MA','CO'].includes(order.customer.state) ||
              (order.customer.state === 'CO' && moment(order.created_at).diff(moment([2022, 6, 1])) > 0)) && (
                <p className="mb-3 text-danger">
                  Reminder: As of 12/1/2021 a 3% surcharge will be charged to credit card payments. For alternative ways to pay, click&nbsp;
                  <Link to="/payment-types">
                    here
                  </Link>
                  .
                </p>
              )}
              <CardsTable
                cards={customer_credit_cards}
                choose_card_func={this.choose_card_for_payment}
              />

              <AddCardViaStripeCheckout
                customer_id={order.customer_id}
              />
            </div>

            <div>
              <Link to={`/orders/${order.id}`}>
                Skip Payment Information
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

OrderPayment.propTypes = {
  order: PropTypes.object.isRequired,
  customer_credit_cards: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
}

class OrderPaymentInfoWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      is_loading: true,

      order: null,
      customer_credit_cards: null,
    };
  }

  componentDidMount = async() => {
    const {order_id} = this.props;

    const {order} = await OrderDAO.get_order(order_id)
    const {cards: customer_credit_cards} = await CustomerDAO.get_credit_cards(order.customer_id);
    this.setState({order, customer_credit_cards, is_loading: false});
  }

  render() {
    const {is_loading, order, customer_credit_cards} = this.state;

    if (is_loading) {
      return <div className="alert alert-warning">Loading payment info...</div>;
    }

    const {render} = this.props;

    return render({order, customer_credit_cards})
  }
}

OrderPaymentInfoWrapper.propTypes = {
  render: PropTypes.func.isRequired,
  order_id: PropTypes.string.isRequired,
}

const OrderPaymentPage = ({match, history}) => {
  const {order_id} = match.params;
  return (
    <OrderPaymentInfoWrapper
      render={({order, customer_credit_cards}) => (
        <OrderPayment
          order={order}
          customer_credit_cards={customer_credit_cards}
          history={history}
        />
      )}
      order_id={order_id}
    />
  )
}

OrderPaymentPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      order_id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
}

export {OrderPaymentPage};
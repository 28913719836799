import * as net from '../net';

const SalesOrderDAO = {};

SalesOrderDAO.get_sales_order = (sales_order_id) =>
  net.get({url: `/api/sales-orders/${sales_order_id}`});

SalesOrderDAO.get_sales_orders = (customer_id) =>
  net.get({url: `/api/sales-orders/for-customer/${customer_id}`});

export default SalesOrderDAO;

import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import {Link} from 'react-router-dom';


import SalesOrderDAO from './SalesOrderDAO';
import ItemStore from '../product/ItemStore';
import Price from '../components/Price';


const SalesOrderRow = ({nav_sales_header, nav_sales_lines, shipping_address}) => {
  const {
    sales_order_id, amount, order_date, status, shipment_status, customer_po_number
  } = nav_sales_header;

  return (
    <tr>
      <td>
        <Link to={`/sales-orders/${sales_order_id}`}>
          {sales_order_id}
        </Link>
      </td>
      <td>{customer_po_number}</td>
      <td><Price price={amount}/></td>
      <td>{moment(order_date).format('MMMM, DD YYYY')}</td>
      <td>{status}</td>
      <td>{shipment_status}</td>
    </tr>
  )

}

class SalesOrderTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = async() => {

  }

  render() {
    const {sales_orders} = this.props;

    return (

      <table className="table table-sm table-striped">
        <thead>
          <tr>
            <th>ID</th>
            <th>PO</th>
            <th>Amount</th>
            <th>Date</th>
            <th>Order Status</th>
            <th>Shipment Status</th>
          </tr>
        </thead>

        <tbody>
          {sales_orders.map(({nav_sales_header}, idx) => (
            <SalesOrderRow
              nav_sales_header={nav_sales_header}
              key={idx}
            />
          ))}
        </tbody>
      </table>
    )
  }
}

SalesOrderTable.propTypes = {
  sales_orders: PropTypes.array.isRequired,
}

class SalesOrderTableWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: true,
      error: null,

      sales_orders: null,
      customer: null,
    }
  }

  componentDidMount = async() => {
    const {customer_id} = this.props;
    const {
      sales_orders,
      customer,
      items,
    } = await SalesOrderDAO.get_sales_orders(customer_id)

    ItemStore.add_items(items)

    this.setState({
      sales_orders,
      customer,

      is_loading: false,
    })
  }

  render() {
    const {is_loading, error, sales_orders, customer} = this.state;

    if (is_loading) {
      return <div className="alert alert-warning">Loading...</div>;
    }

    if (error) {
      return <div className="alert alert-danger">{error}</div>;
    }

    return (
      <SalesOrderTable
        sales_orders={sales_orders}
        customer={customer}
      />
    )
  }
}

SalesOrderTableWrapper.propTypes = {}

export {SalesOrderTable, SalesOrderTableWrapper};


import * as net from '../net';

const CartDAO = {};

CartDAO.createOrderFromCart = (data) => net.post({url: '/api/orders/create-from-cart', data});

CartDAO.add_to_cart = (data) =>
  net.post({url: '/api/users/current-user/add-skus-to-cart', data});

export default CartDAO;

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const ORDER_PROGRESS_TAB_CART = 'cart';
const ORDER_PROGRESS_TAB_SHIPPING = 'shipping';
const ORDER_PROGRESS_TAB_PAYMENT = 'payment';

const ORDER_PROGRESS_TABS = [
  {
    key: ORDER_PROGRESS_TAB_CART,
    display: '1. Choose Products',
  },
  {
    key: ORDER_PROGRESS_TAB_SHIPPING,
    display: '2. Shipping',
  },
  {
    key: ORDER_PROGRESS_TAB_PAYMENT,
    display: '3. Payment',
  }
]

const OrderNavProgress = ({active_section}) => (
  <ul className="nav nav-pills justify-content-center mb-4">
    {ORDER_PROGRESS_TABS.map(({key, display}) => (
      <li className='nav-item' key={key}>
        <button type="button" className={classNames("btn","btn-link","nav-link", {active: active_section === key})}>
          {display}
        </button>
      </li>
    ))}
  </ul>
)

OrderNavProgress.propTypes = {
  active_section: PropTypes.string.isRequired,
};

export {OrderNavProgress, ORDER_PROGRESS_TAB_PAYMENT, ORDER_PROGRESS_TAB_CART, ORDER_PROGRESS_TAB_SHIPPING};
import moment from 'moment';
import createReactClass from 'create-react-class';
import React from 'react';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import {Link} from 'react-router-dom';

import * as net from '../net';
import dao from '../dao';
import util from '../util';

const ECOMM_SKU_PLACEHOLDER = 'ECOMM_SKU_PLACEHOLDER';

const ECOMMERCE_TEMPLATES = {
  wayfair: {
    url: `https://www.wayfair.com/XX-${ECOMM_SKU_PLACEHOLDER}.html`,
  },
  allmodern: {
    url: `https://www.allmodern.com/XX-${ECOMM_SKU_PLACEHOLDER}.html`,
  },
  amazon: {
    url: `https://www.amazon.com/dp/${ECOMM_SKU_PLACEHOLDER}`
  },
  overstock: {
    url: `https://www.overstock.com/search?keywords=${ECOMM_SKU_PLACEHOLDER}`
  },
  inmod: {
    url: `https://www.inmod.com/${ECOMM_SKU_PLACEHOLDER}.html`
  },
  houzz: {
    url: `https://www.houzz.com/photos/${ECOMM_SKU_PLACEHOLDER}`
  },
  'lamps plus': {
    url: `http://www.lampsplus.com/products/xx__${ECOMM_SKU_PLACEHOLDER}.html`
  },
  'Sleek Modern': {
    url: `${ECOMM_SKU_PLACEHOLDER}`
  },
  'Eurway': {
    url: `https://www.eurway.com/${ECOMM_SKU_PLACEHOLDER}`
  },
}

const ECommerceProductLink = ({ecomm_site, ecomm_sku, inline}) => {
  if (!ecomm_sku) {
    return null;
  }

  const DomElement = inline ? 'span' : 'div';
  const ecomm_url = ECOMMERCE_TEMPLATES[ecomm_site].url.replace(ECOMM_SKU_PLACEHOLDER, ecomm_sku);
  return (
    <DomElement className='mt-2'>
      <a href={ecomm_url} target="_blank" rel="noopener noreferrer">{ecomm_site}</a>
    </DomElement>
  )
}

class ECommerceLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      amazon_price: null,
      amazon_review_count: null,
      amazon_avg_review: null,
      amazon_retailer: null,
    }
  }

  componentDidMount = async() => {
    const {item} = this.props;
    const {amazon_asin} = item;
    if (!amazon_asin) return await null;

    const {value} = await net.get({
      url: `https://ziggurat.zinc.io/v1/zigg/amazon_details/us;${amazon_asin}?max_age=300&timeout=30`,
      username: '5072EBE8E182B6376A2E72CD',
      beforeSend: (xhr) => {
        xhr.setRequestHeader('Authorization', 'Basic emluY2FkbTo=');
      }
    });

    const {
      review_count: amazon_review_count, stars: amazon_avg_review, price: amazon_price, retailer: amazon_retailer
    } = value;
    this.setState({
      amazon_avg_review,
      amazon_review_count,
      amazon_price,
      amazon_retailer,
    })
  }

  render() {
    const {item} = this.props;
    const {amazon_price, amazon_avg_review, amazon_review_count, amazon_retailer} = this.state;

    return (
      <div>
        <div><strong>eCommerce</strong></div>
        {item.wayfair_sku && (
          <div>
            <ECommerceProductLink ecomm_site='wayfair' ecomm_sku={item.wayfair_sku} inline={true}/> {' '} - {' '}
            <ECommerceProductLink ecomm_site='allmodern' ecomm_sku={item.wayfair_sku} inline={true}/>
            : {item.wayfair_product_name}
          </div>
        )}

        {item.amazon_asin && (
          <div>
            <ECommerceProductLink ecomm_site='amazon' ecomm_sku={item.amazon_asin}/>
            {amazon_price && (
              <div>
                ${amazon_price / 100} | {amazon_avg_review} <FontAwesome name="star"/>
                {' '} | {amazon_review_count} Reviews | Sold by {amazon_retailer}
              </div>
            )}
          </div>
        )}

        <ECommerceProductLink
          ecomm_site='overstock'
          ecomm_sku={item.overstock_product_id && item.overstock_product_id.split('-')[0]}/>
        <ECommerceProductLink ecomm_site='houzz' ecomm_sku={item.houzz_product_id}/>
        <ECommerceProductLink ecomm_site='inmod' ecomm_sku={item.inmod_sku}/>
        <ECommerceProductLink ecomm_site='lamps plus' ecomm_sku={item.lamps_plus_sku}/>
        <ECommerceProductLink ecomm_site='Sleek Modern' ecomm_sku={item.sleekmodern_product_url}/>
        <ECommerceProductLink ecomm_site='Eurway' ecomm_sku={item.eurway_product_path}/>
      </div>

    )
  }
}

const ItemCard = createReactClass({
  propTypes: {
    item_sku: PropTypes.string,
    item: PropTypes.object,

    show_kits: PropTypes.bool.isRequired,
    linkify_sku: PropTypes.bool.isRequired,
  },

  getDefaultProps() {
    return {show_kits: true, linkify_sku: false};
  },

  getInitialState() {
    return {
      item: this.props.item || null,

      kits: null,

      collection: null,
    };
  },

  async componentDidMount() {
    const item_sku = this.props.item_sku || this.state.item.sku;
    let item = this.props.item;
    if (!item) {
      item = await dao.getItem(item_sku);
      this.setState({item});
    }

    if (this.props.show_kits) {
      const kits = await dao.getItemKits(item_sku);
      this.setState({kits});
    }

    if (item.collection_id) {
      const collection = await dao.getCollection(item.collection_id);
      this.setState({collection})
    }
  },

  render() {
    const item = this.state.item;

    if (!item) {
      return <div>Loading item {this.props.item_sku}</div>
    }

    const skuNumber = <div style={{fontSize: 'larger', fontWeight: 'bold'}}>{item.sku}</div>;
    let skuNode;
    if (this.props.linkify_sku) {
      skuNode = <Link to={`/internal/item/${item.sku}`}>{skuNumber}</Link>;
    } else {
      skuNode = skuNumber;
    }

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: 10,
          border: 'solid 1px black',
          padding: 10,
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}
      >
        <div style={{width: 190, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          {skuNode}
          {item.main_image_s3_key && (
            <img
              src={util.getS3Url(item.main_image_s3_key + '-medium')}
              style={{maxWidth: 180}}
              alt="Product"
            />
          )}
        </div>

        <div style={{width: 480, display: 'inline-block'}}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline',
            marginTop: 10
          }}>
            <span style={{fontSize: 'large'}}>{item.product_name}</span>
            <span>{item.status}</span>
          </div>

          <div className="mb-2">{item.product_description}</div>

          <div
            className="mb-2"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >

            <div>
              <span className="itemPage-label">QTY:</span>
              <span>{item.quantity_available}</span>
            </div>

            <div><strong>Items/CTN</strong> {item.items_per_carton}</div>

            <div><strong>First Received</strong> {moment(item.first_receipt_date).format('MMM Do, YYYY')}</div>
          </div>

          <ECommerceLinks item={item}/>

          {this.state.kits && Boolean(this.state.kits.length) && (
            <div style={{marginTop: 10}}>
              <div><strong>Kits</strong></div>
              <div>
                <ul>
                  {this.state.kits.map(({sku, description_es}) => (
                    <li key={`kit-${sku}`}>
                      <strong><Link
                        to={`/p/${sku}`}>{sku}</Link></strong>: {description_es}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}

          {this.state.collection && (
            <div style={{marginTop: 10}}>
              <strong>
                <Link to={`/internal/collection/${this.state.collection.name}`}>
                  {this.state.collection.name} Collection
                </Link>
              </strong>
            </div>
          )}

          <Link to={`/product/${item.sku}`}>Public Page</Link>
        </div>
      </div>
    );
  }
});


export default ItemCard;

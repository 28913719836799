import React from 'react';
import reactUpdate from 'react-addons-update';
import _ from 'lodash';

import ReportDAO from './ReportDAO';

class GenerateInventoryReports extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ecomm_retailers: {
        'amazon small parcel': true,
        'amazon ltl': true,
        'bed bath and beyond': true,
        bellacor: true,
        ecomm: true,
        'home depot': true,
        houzz: true,
        "lamps plus": true,
        "lulu and georgia": true,
        macys: true,
        "nebraska furniture mart": true,
        overstock: true,
        staples: true,
      },
    }
  }

  updateCheckedRetailers = (retailer, checked) => {
    const ecomm_retailers = reactUpdate(this.state.ecomm_retailers, {$merge: {[retailer]: !checked}});
    this.setState({ecomm_retailers});
  }

  generateInventoryReports = async(e) => {
    e.preventDefault();

    const {ecomm_retailers: ecomm_retailers_obj} = this.state;
    const ecomm_retailers = _.keys(_.pickBy(ecomm_retailers_obj, (checked) => checked))

    try {
      const {s3_keys} = await ReportDAO.generateInventoryReports({ecomm_retailers});
      _.each(s3_keys, (s3_key) => {
        const s3_url = `https://s3-us-west-2.amazonaws.com/eurostyle-item-photos/${s3_key}`
        window.open(s3_url)
      });
    } catch (e) {
      console.log(e) // Probably need to do real error handling
      return await null;
    }
  }

  render() {
    const checkboxItems = _.map(this.state.ecomm_retailers, (checked, retailer) => {
      return (
        <div className="form-check">
          <label className="form-check-label">
            <input
              className="form-check-input"
              type="checkbox"
              checked={checked}
              onChange={() => this.updateCheckedRetailers(retailer, checked)}
            /> {retailer}
          </label>
        </div>
      );
    });

    return (
      <div>
        <h3>Inventory Reports</h3>
        <form onSubmit={this.generateInventoryReports}>
          {checkboxItems}

          <button className="btn btn-success" type="submit">
            Download Report
          </button>
        </form>
      </div>
    )
  }
}

export default GenerateInventoryReports;

import React from 'react';
import _ from 'lodash';

import CustomerDAO from '../customers/CustomerDAO';
import Percent from '../components/Percent';

class ECommProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: true,

      active_items: null,
      ecomm_ready_items: null,
      ecomm_retailers: null,
    };
  }

  componentDidMount = async() => {
    const {active_items, ecomm_ready_items, ecomm_retailers} = await
      CustomerDAO.get_uploaded_ecomm_products();
    this.setState({ecomm_retailers, ecomm_ready_items, active_items, is_loading: false})
  }

  render() {
    const {is_loading, active_items, ecomm_ready_items, ecomm_retailers} = this.state;

    if (is_loading) {
      return 'Loading ecomm information';
    }

    return (
      <div>
        <h2>eCommerce Products</h2>

        <p>
          This looks at how we are doing with uploading products to our eCommerce partners. A product
          is eCommerce ready if we have pictures and product data.
        </p>

        <div>
          Active Items - <strong>{active_items.length}</strong>
        </div>
        <div>
          eComm Ready Items - <strong>{ecomm_ready_items.length}</strong>
        </div>

        <table className="table table-striped table-sm table-responsive">
          <thead>
            <tr>
              <th>Retailer</th>
              <th>Items Uploaded</th>
              <th>% of eComm Ready</th>
              <th>% of Active</th>
            </tr>
          </thead>
          <tbody>
            {_.map(ecomm_retailers, ({retailer, num_items}) => (
              <tr>
                <td>{retailer}</td>
                <td>{num_items}</td>
                <td><Percent percent={num_items / ecomm_ready_items.length}/></td>
                <td><Percent percent={num_items / active_items.length}/></td>
              </tr>
            ))}
          </tbody>
        </table>

      </div>
    )
  }
}

export {ECommProducts};


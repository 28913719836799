import queryString from 'query-string';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';
import _ from 'lodash';

import util from '../util';

import dao from '../dao';
import ItemConstants from '../dashboard/ItemConstants';
import ItemGrid from '../product/ItemGrid';

import CategoryConstants from './CategoryConstants';

const {CATEGORY_TO_INFO, CATEGORIES} = CategoryConstants;


class CategorySection extends React.Component {
  constructor(props) {
    super(props);
    const {page, query: initial_search_term, filter: initial_filter} = queryString.parse(this.props.location.search);
    const initial_page = +page || 1;
    this.state = {
      search_term: initial_search_term || '',
      page: initial_page,
      filter: initial_filter || '',
      items: null
    }
  }

  /*componentWillReceiveProps(nextProps) {
    console.log(nextProps, this.props)
    if (nextProps.product_group !== this.props.product_group || nextProps.category !== this.props.category) {
      this.update_page(1, nextProps.category, nextProps.product_group)
    }
  },*/

  async componentDidMount() {
    const {category} = this.props;
    if (CATEGORY_TO_INFO[category].display === 'Outdoor') {
      const items = await dao.getItems({
        is_ok_for_outdoor: true,
      });
      this.setState({items});
    } else {
      const items = await dao.getItems({
        category: CATEGORY_TO_INFO[category].item_category_code,
        status: ItemConstants.STATUS.ACTIVE,
      });
      this.setState({items});
    }
    this.updateSearchTerm(this.state.search_term, this.state.filter, this.state.page);
  }

  update_url({new_page, new_search_term, new_filter, next_category = null, next_product_group = null}) {
    this.setState({page: new_page, search_term: new_search_term, filter: new_filter});

    const {history, category, product_group} = this.props;
    const {replace} = history;

    let pathname = `/category/${next_category || category}`
    if (next_product_group || product_group) {
      pathname += `/${next_product_group || product_group}`
    }

    replace({
      pathname,
      search: `?query=${new_search_term}&page=${new_page}&filter=${encodeURIComponent(new_filter)}`,
    });
  }

  updateSearchTerm (search_term, filter, page = null) {
    this.update_url({new_search_term: search_term, new_page: page || 1, new_filter: filter})

    //track_search_debounced(search_term);

    const {items} = this.state;
    if (!items) {
      return;
    }

    const search_results = items.filter((item) => util.filter_item_description(item, search_term));
    this.setState({search_results});
  }

  render() {
    const {category, product_group} = this.props;
    const {search_term, page} = this.state;

    let search_results = this.state.search_results;
    if (!search_results) {
      return <div>Loading...</div>;
    }
    const product_groups = _.sortBy(_.uniq(this.state.items.map(({product_group}) => product_group)));
    if (product_group) {
      search_results = search_results.filter(
        ({product_group: current_product_group}) => current_product_group === product_group)
    }

    return (
      <div>
        <ul className="nav nav-pills justify-content-center">
          {product_groups.map((potential_product_group) => (
            <li
              key={potential_product_group}
              className='nav-item'
            >
              <Link
                className={classNames('nav-link', {active: potential_product_group === product_group})}
                to={`/category/${category}/${potential_product_group}?query=${search_term}&page=${page}`}
              >
                {potential_product_group}
              </Link>
            </li>
          ))}
          <li key='Any' className='nav-item'>
            <Link
              className={classNames('nav-link', {active: product_group === ''})}
              to={`/category/${category}?query=${search_term}&page=${page}`}
            >
              Any
            </Link>
          </li>
        </ul>
        <div className="container">
          <div className="row">
              <div className="col-12">
                <input
                  value={search_term}
                  onChange={(e) => this.updateSearchTerm(e.target.value, this.state.filter)}
                  placeholder="Search..."
                  className="form-control"
                  style={{maxWidth: 200}}
                  ref={(input) => {
                    this.search_input = input;
                  }}
                />
            </div>
          </div>
        </div>
        <ItemGrid
          items={search_results}
          non_filter_items={this.state.items}
          allow_filters={true}
          page={page}
          update_page={(new_page) => {
            this.update_url({new_search_term : search_term || '', new_page, new_filter: this.state.filter || ''})
          }}
          update_filter={(new_filter) => {
            this.update_url({new_search_term : search_term || '', new_page: page || 1, new_filter})
          }}
          filter={this.state.filter}
        />
      </div>
    )
  }
}

CategorySection.propTypes = {
  category: PropTypes.string.isRequired,
  product_group: PropTypes.string,
};

const CategorySplash = ({category, header, descriptionNode}) => (
  <div className="container" dangerouslySetInnerHTML={{__html: descriptionNode}}></div>
);

class SplashSection extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      is_loading: true,
      footer_html: null,
      setup: null,
    };
  }

  componentDidMount = async () => {
    var setup = await dao.get_categories();
    var html = "";
    if (this.props.category === "office")
      html = setup.office;
    if (this.props.category === "bar")
      html = setup.bar;
    if (this.props.category === "dining")
      html = setup.dining;
    if (this.props.category === "occasional")
      html = setup.occasional;
    if (this.props.category === "outdoor")
      html = setup.outdoor;

    this.setState({
      is_loading: false,
      html,
      setup
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.category !== prevProps.category ) {
      var html = "";
      if (this.props.category === "office")
        html = prevState.setup.office;
      if (this.props.category === "bar")
        html = prevState.setup.bar;
      if (this.props.category === "dining")
        html = prevState.setup.dining;
      if (this.props.category === "occasional")
        html = prevState.setup.occasional;
      if (this.props.category === "outdoor")
        html = prevState.setup.outdoor;

      this.setState({
        is_loading: false,
        html,
        setup: prevState.setup
      })
    }
  }

  render() {
    const {is_loading, html} = this.state;

    if (is_loading)
      return null;
    return (
      <CategorySplash
        category={this.props.category}
        descriptionNode={html}
      />
    );
  }
}

SplashSection.propTypes = {
  category: PropTypes.string.isRequired
};

const CategoryHome = ({match, location, history}) => {
  const {category, product_group} = match.params;

  return (
    <div>
      <div className="container" style={{marginBottom: 30}}>
        <div className="row">
          <div className="col-12">
            <ul className="nav nav-pills justify-content-center">
              {CATEGORIES.map((other_category) => (
                <li
                  key={other_category}
                  className='nav-item'
                >
                  <Link
                    className={classNames('nav-link', {active: category === other_category})}
                    to={`/category/${other_category}`}
                  >
                    {CATEGORY_TO_INFO[other_category].display}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <SplashSection category={category}/>

      <hr />

      <CategorySection
        key={category}
        category={category}
        product_group={product_group}
        location={location}
        history={history}
      />
    </div>
  );
}

export default CategoryHome;
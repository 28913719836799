import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

import ReportDAO from '../reports/ReportDAO';
import {QuoteCards} from './QuoteComponents'

const DimensionInput = ({label, value, onChange, type}) => (
  <div className="form-group col-12 col-md-4">
    <label>{label}</label>
    <input
      className="form-control"
      type={type || "number"}
      min="0"
      onChange={onChange}
      value={value}
    />
  </div>
)

DimensionInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

class ShippingCalculator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      length: 0,
      width: 0,
      height: 0,
      weight: 0,
      destination_zip: '92130',
      quotes: null,
    }
  }

  get_quote = async (e) => {
    e.preventDefault();
    this.setState({quotes: null});

    const {length, width, height, weight, destination_zip} = this.state;

    const {quotes} = await
      ReportDAO.get_quotes_from_dimensions({length, width, height, weight, destination_zip});
    this.setState({quotes})
  }


  render() {
    const {length, width, height, weight, destination_zip, quotes} = this.state;

    const parcel_dims = _.sortBy([length, width, height], (dim) => -1 * dim);
    const [parcel_length, parcel_girth_1, parcel_girth_2] = parcel_dims;
    const girth = 2 * (parcel_girth_1 + parcel_girth_2);
    const package_measurement = parcel_length + girth;
    const can_ship_small_parcel = package_measurement < 165 && weight < 150;

    return (
      <div className="col-12 col-md-10 mx-auto">
        <h1>Shipping Calculator</h1>

        <form onSubmit={this.get_quote} className="mb-4">
          <div className="row">
            <DimensionInput
              label="Length (inches)"
              value={length}
              onChange={(e) => this.setState({length: parseInt(e.target.value, 10)})}
            />

            <DimensionInput
              label="Width (inches)"
              value={width}
              onChange={(e) => this.setState({width: parseInt(e.target.value, 10)})}
            />

            <DimensionInput
              label="Height (inches)"
              value={height}
              onChange={(e) => this.setState({height: parseInt(e.target.value, 10)})}
            />

            <DimensionInput
              label="Weight (pounds)"
              value={weight}
              onChange={(e) => this.setState({weight: parseInt(e.target.value, 10)})}
            />

            <DimensionInput
              label="Destination Zip Code"
              type="text"
              value={destination_zip}
              onChange={(e) => this.setState({destination_zip: e.target.value})}
            />

          </div>

          <button type="submit" className="btn btn-success">
            Get Quote
          </button>
        </form>

        <div
          className={
            classNames(
              'alert mb-4',
              {'alert-success': can_ship_small_parcel, 'alert-warning': !can_ship_small_parcel})}
        >
          <p>
            Packages with a package measurement {"<"} 165" and weight {"<"} 150 lbs can ship small parcel.
          </p>

          <dl>
            <dt>Package Measurement</dt>
            <dd>{package_measurement}"</dd>

            <dt>Girth</dt>
            <dd>{girth}"</dd>
          </dl>
        </div>

        {quotes && <QuoteCards quotes={quotes} />}

      </div>
    )
  }
}

export {ShippingCalculator};
import PropTypes from 'prop-types';
import React from 'react';
import reactUpdate from 'react-addons-update';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import _ from 'lodash';
import classNames from 'classnames';


import ItemReportDAO from './ItemReportDAO';
import {FormInput} from '../requests/FormInput';

const ITEM_ATTRIBUTE_TYPE = 'item_attribute_type';
const MAPPING_FUNCTION_TYPE = 'mapping_function_type';
const RAW_VALUE_TYPE = 'raw_value_type';

const COLUMN_TYPES = [ITEM_ATTRIBUTE_TYPE, MAPPING_FUNCTION_TYPE, RAW_VALUE_TYPE];

const get_item_style = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'white',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const ItemReportColumn = ({
  column, update_column, item_attribute_options, mapping_function_options, index, remove_column
}) => {
  const {column_type, column_name, item_attribute, mapping_function, raw_value, id} = column;

  return (
    <Draggable key={id} draggableId={id} index={index}>
      {(provided, snapshot) => (
        <div className="my-2">
          <div
            className={classNames({card: !snapshot.isDragging})}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={get_item_style(
              snapshot.isDragging,
              provided.draggableProps.style
            )}
          >
            <div className="card-header">
              <div className="d-flex justify-content-between">
                <h5>Column {index + 1}</h5>

                <div
                  className="btn btn-sm btn-link"
                  onClick={() => remove_column()}
                >
                  Delete
                </div>
              </div>
            </div>

            <div className="card-body">
              <FormInput
                value={column_name}
                updateValue={(column_name) => {
                  update_column(
                    Object.assign({}, column, {column_name})
                  )
                }}
                display="Column Name"
                type="text"
                required={true}
                large={true}
                className
              />

              <FormInput
                value={column_type}
                updateValue={(column_type) => {
                  update_column(
                    Object.assign({}, column, {column_type})
                  )
                }}
                display="Column Type"
                type="select"
                required={true}
                options={[''].concat(COLUMN_TYPES)}
                large={true}
              />

              {column_type === ITEM_ATTRIBUTE_TYPE && (
                <FormInput
                  value={item_attribute}
                  updateValue={(item_attribute) => {
                    update_column(
                      Object.assign({}, column, {item_attribute})
                    )
                  }}
                  display="Item Attribute"
                  type="select"
                  required={true}
                  options={item_attribute_options}
                  large={true}
                />
              )}

              {column_type === MAPPING_FUNCTION_TYPE && (
                <FormInput
                  value={mapping_function}
                  updateValue={(mapping_function) => {
                    update_column(
                      Object.assign({}, column, {mapping_function})
                    )
                  }}
                  display="Mapping Function"
                  type="select"
                  required={true}
                  options={mapping_function_options}
                  large={true}
                />
              )}

              {column_type === RAW_VALUE_TYPE && (
                <FormInput
                  value={raw_value}
                  updateValue={(raw_value) => {
                    update_column(
                      Object.assign({}, column, {raw_value})
                    )
                  }}
                  display="Raw Value"
                  type="text"
                  large={true}
                />
              )}
            </div>
            {provided.placeholder}
          </div>
        </div>
      )}
    </Draggable>
  )
}

ItemReportColumn.propTypes = {
  column: PropTypes.object.isRequired,
  update_column: PropTypes.func.isRequired,
  remove_column: PropTypes.func.isRequired,
  item_attribute_options: PropTypes.array.isRequired,
  mapping_function_options: PropTypes.array.isRequired,
}

const EMPTY_ITEM_REPORT_COLUMN = {
  column_name: '',
  column_type: '',
  raw_value: '',
  item_attribute: '',
  mapping_function: '',
};

const create_new_column = () => (
  Object.assign({id: _.uniqueId()}, EMPTY_ITEM_REPORT_COLUMN)
)

// Drag and Drop example from: https://codesandbox.io/s/k260nyxq9v
const reorder = (list, start_idx, end_idx) => {
  const actual_end_idx = Math.max(end_idx, 0);
  const result = Array.from(list);
  const [removed] = result.splice(start_idx, 1);
  result.splice(actual_end_idx, 0, removed);

  return result;
};

const get_list_style = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : 'white',
});

class ItemReportColumns extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  onDragEnd = (result) => {
    console.log(result)
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const {columns, update_columns} = this.props;

    const reordered_columns = reorder(
      columns,
      result.source.index,
      result.destination.index
    );

    update_columns(reordered_columns);
  };


  render() {
    const {columns, item_attribute_options, mapping_function_options, update_columns} = this.props;

    return (
      <DragDropContext
        onDragStart={this.onDragStart}
        onDragUpdate={this.onDragUpdate}
        onDragEnd={this.onDragEnd}
      >
        <Droppable droppableId="item-report-columns">
          {(provided, snapshot) => (
            <div
              className="my-4"
              ref={provided.innerRef}
              style={get_list_style(snapshot.isDraggingOver)}
            >
              {columns.map((column, index) => (
                <ItemReportColumn
                  key={index}
                  index={index}
                  column={column}
                  update_column={(column) => {
                    const new_columns = reactUpdate(columns, {[index]: {$set: column}});
                    update_columns(new_columns);
                  }}
                  remove_column={() => {
                    const new_columns = reactUpdate(columns, {$splice: [[index, 1]]});
                    update_columns(new_columns);
                  }}
                  item_attribute_options={item_attribute_options}
                  mapping_function_options={mapping_function_options}
                />
              ))}

            </div>
          )}
        </Droppable>

        <div>
          <button
            type="button"
            className="btn btn-info"
            onClick={() => {
              const new_column = create_new_column()
              const new_columns = reactUpdate(columns, {$push: [new_column]})
              update_columns(new_columns);
            }}
          >
            Add Column
          </button>
        </div>
      </DragDropContext>
    )
  }
}

ItemReportColumns.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    column_name: PropTypes.string,
    column_type: PropTypes.string,
    item_attribute: PropTypes.string,
    mapping_function: PropTypes.string,
  })).isRequired,

  update_columns: PropTypes.func.isRequired,

  item_attribute_options: PropTypes.arrayOf(PropTypes.string).isRequired,
  mapping_function_options: PropTypes.arrayOf(PropTypes.string).isRequired,
}

class ItemReportColumnOptionsWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: true,
      error: null,

      item_attribute_options: null,
      mapping_function_options: null,
    }
  }

  componentDidMount = async() => {
    const {item_attribute_options, mapping_function_options} = await
      ItemReportDAO.get_attribute_and_function_options();

    this.setState({is_loading: false, item_attribute_options, mapping_function_options});
  }

  render() {
    const {render} = this.props;
    const {is_loading, error, item_attribute_options, mapping_function_options} = this.state;

    if (error) {
      return <div className="alert alert-danger">{error}</div>;
    }

    if (is_loading) {
      return <div className="alert alert-warning">Loading...</div>;
    }

    return render({item_attribute_options, mapping_function_options});
  }
}

ItemReportColumnOptionsWrapper.propTypes = {
  render: PropTypes.func.isRequired,
}

class ItemReportForm extends React.Component {
  constructor(props) {
    super(props);

    const {initial_item_report, initial_columns} = this.props;

    this.state = {
      name: initial_item_report ? initial_item_report.name : '',
      description: initial_item_report ? initial_item_report.description : '',

      columns: initial_columns || [],
    };
  }

  create_item_report = async(e, options = {update_existing_report: false}) => {
    e.preventDefault();

    const {name, description, columns} = this.state;
    const item_report_data = {name, description, columns};

    let update_func = ItemReportDAO.create_item_report;

    if (options.update_existing_report) {
      update_func = this.props.update_existing_item_report;
    }

    const {item_report} = await update_func(item_report_data);
    const {id: item_report_id} = item_report;

    this.props.history.push(`/internal/item-reports/${item_report_id}`)
  }

  render() {
    const {update_existing_item_report} = this.props;
    const {columns, name, description} = this.state;

    return (
      <div>
        <h3>Create / Update Item Report</h3>


        <form onSubmit={(e) => e.preventDefault()}>
          <FormInput
            value={name}
            updateValue={(name) => this.setState({name})}
            display="Report Name"
            type="text"
            required={true}
            large={true}
          />

          <FormInput
            value={description}
            updateValue={(description) => this.setState({description})}
            display="Report Description"
            type="textarea"
            required={false}
            large={true}
          />

          <ItemReportColumnOptionsWrapper
            render={({item_attribute_options, mapping_function_options}) => (
              <ItemReportColumns
                columns={columns}
                update_columns={(columns) => this.setState({columns})}
                item_attribute_options={item_attribute_options}
                mapping_function_options={mapping_function_options}
              />
            )}
          />

          <button className="btn btn-success mt-5" type="button" onClick={this.create_item_report}>
            Create New Item Report
          </button>

          {update_existing_item_report && (
            <button
              className="btn btn-warning mt-5 ml-3"
              type="button"
              onClick={(e) => this.create_item_report(e, {update_existing_report: true})}
            >
              Update Existing Item Report
            </button>
          )}
        </form>
      </div>
    )
  }
}

ItemReportForm.propTypes = {
  history: PropTypes.object.isRequired,
  initial_item_report: PropTypes.object,
  initial_columns: PropTypes.array,
  update_existing_item_report: PropTypes.func,
}


const CreateItemReportPage = ({history}) => (
  <div className="container">
    <div className="row">
      <div className="col-12 col-md-10 mx-auto">
        <ItemReportForm history={history}/>
      </div>
    </div>
  </div>
)

export {CreateItemReportPage, ItemReportForm};


import React from 'react';

import {GetCommentsWrapper, CommentsList} from './CommentsViews';

const CommentsFeed = () => (
  <div>
    <h2>Comments Feed</h2>

    <GetCommentsWrapper
      all_comments={true}
      render={({comments}) => (
        <CommentsList
          show_customer_info={true}
          comments={comments}
        />
      )}
    />
  </div>
)

export {CommentsFeed};


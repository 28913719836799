import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';

import PaginatedItems from '../components/PaginatedItems';

const CustomerList = ({customers, render_action_cell}) => {
  if (!customers) {
    return 'No customers found.';
  }

  const wrap_rendered_elements = (rendered_elements) => (
    <table className="table table-striped my-5">
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Category</th>
          <th>City</th>
          <th>State</th>
          <th>Rep</th>
          <th>CS</th>
          <th>Price Level</th>
        </tr>
      </thead>
      <tbody>
        {rendered_elements}
      </tbody>
    </table>
  )

  return (
    <PaginatedItems
      map_item_to_element={(customer) => (
        <tr key={customer.id}>
          <td>
            <Link to={`/internal/customers/${customer.id}`}>
              {customer.id}
            </Link>
          </td>
          <td>{customer.name}</td>
          <td>{customer.category}</td>
          <td>{customer.city}</td>
          <td>{customer.state}</td>
          <td>{customer.sales_person_code}</td>
          <td>{customer.customer_service_code}</td>
          <td>{customer.price_group}</td>
          {render_action_cell && render_action_cell(customer)}
        </tr>
      )}
      num_items_per_page={20}
      items={customers}
      wrap_rendered_elements={wrap_rendered_elements}/>
  )
}

CustomerList.propTypes = {
  customers: PropTypes.array.isRequired,
  render_action_cell: PropTypes.func,
}

export default CustomerList;

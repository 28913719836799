import React from 'react';

import dao from '../dao';
import ItemGrid from '../product/ItemGrid';


class ShowSpecialsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {show_special_items: null};
  }

  async componentDidMount() {
    const show_special_items = await dao.getItems({is_show_special: true});
    this.setState({show_special_items});
  }

  render() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-12 mx-auto col-sm-8">
              <h3><span className="bold">Show</span> Specials</h3>
              <p>
                We offer promotional pricing to thank our customers for visiting our showroom!
              </p>

              <p>
                To be eligible for a show special, you must have checked into our showroom.
              </p>
            </div>
          </div>
        </div>
        <hr />
        <ItemGrid items={this.state.show_special_items} non_filter_items={this.state.show_special_items}/>
      </div>
    )
  }
}

export default ShowSpecialsPage;

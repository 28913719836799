import EventEmitter from 'events';
import _ from 'lodash';

import {Map} from 'immutable';

class ItemStore extends EventEmitter {
  constructor() {
    super();

    this.sku_to_item = Map();
    this.has_fetched_items = false;
  }

  get_is_fresh() {
    return this.has_fetched_items;
  }

  set_items_as_fresh() {
    this.has_fetched_items = true;
  }

  get_item(sku) {
    return this.sku_to_item.get(sku);
  }

  add_items(items) {
    items.forEach((item) => {
      this.sku_to_item = this.sku_to_item.set(item.sku, item)
    })
    this.emit_change();
  }

  add_item(item) {
    this.sku_to_item = this.sku_to_item.set(item.sku, item);
    this.emit_change();
  }

  get_items() {
    return _.sortBy(this.sku_to_item.toList().toArray(),
      ({product_name, description_es}) => [product_name || 'ZZZZZ', description_es]);
  }

  emit_change() {
    this.emit('change');
  }
}

const item_store = new ItemStore();

export default item_store;

import EventEmitter from 'events';
import _ from 'lodash';

import {Map} from 'immutable';

class CustomerStore extends EventEmitter {
  constructor() {
    super();

    this.id_to_customer = Map();
    this.has_fetched_customers = false;
  }

  get_is_fresh() {
    return this.has_fetched_customers;
  }

  get_customers() {
    return _.sortBy(this.id_to_customer.toList().toArray(), ({historical_sales_amount}) => -1 * historical_sales_amount);
  }

  get_customer(id) {
    return this.id_to_customer.get(id);
  }

  add_customer(customer) {
    this.id_to_customer = this.id_to_customer.set(customer.id, customer);
    this.emit_change()
  }

  add_customers(customers) {
    this.has_fetched_customers = true;

    _.each(customers, (customer) => {
      this.id_to_customer = this.id_to_customer.set(customer.id, customer);
    })
    this.emit_change()
  }

  emit_change() {
    this.emit('change');
  }
}

const customer_store = new CustomerStore();

export default customer_store;

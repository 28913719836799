import PropTypes from 'prop-types';
import classNames from 'classnames';
import React from 'react';

const EMPTY_SHIPPING_ADDRESS = {
  name: '',
  phone_number: '',
  address_1: '',
  address_2: '',
  city: '',
  state: '',
  zip_code: '',
  country: 'US',
};

/*const loader = new Loader({
  apiKey: 'AIzaSyBjtFDG0r2t95c4UWVdD30F2XmlqgRRAfw',
  libraries: ['places']
});*/

class AddressField extends React.Component {
  render() {
    const {value, onChange, label, is_big, disabled, max_length, required} = this.props;
    return (
      <div className={classNames('form-group', {'col-6': !is_big, 'col-12': is_big})}>
        <label>{label}{required && <b style={{color: "red"}}> *</b>}</label>
        <input
          maxLength={max_length}
          type="text"
          className="form-control"
          name="form-control"
          onChange={onChange}
          value={value}
          disabled={disabled}
          ref={'input'}
          required={required}
        />
      </div>
    )
  }
}

class AddressFields extends React.Component {
  update_address = (updated_object) => {
    this.props.update_address(updated_object);
  }

  render() {
    const {
      name, phone_number, address_1, address_2, city, state, zip_code, country,
      disabled,
    } = this.props;

    return (
      <div className="row">
        <AddressField
          autoComplete="company-name"
          label="Name"
          max_length={45}
          value={name}
          is_big={true}
          disabled={disabled}
          onChange={(e) => this.update_address({name: e.target.value.replace('|','')})}
          required={true}
        />

        <AddressField
          autoComplete="phone-number"
          label="Phone Number"
          max_length={30}
          is_big={true}
          value={phone_number}
          disabled={disabled}
          onChange={(e) => this.update_address({phone_number: e.target.value.replace('|','')}) }
          required={true}
        />

        <AddressField
          ref='address_1'
          autoComplete="address-line1"
          max_length={45}
          label="Address 1"
          is_big={true}
          value={address_1}
          disabled={disabled}
          onChange={(e) => this.update_address({address_1: e.target.value.replace('|','')}) }
          // TODO: figure out how to make enter not trigger a submit of the entire form
          required={true}
        />

        <AddressField
          label="Address 2"
          autoComplete="address-line2"
          value={address_2}
          disabled={disabled}
          max_length={45}
          onChange={(e) => this.update_address({address_2: e.target.value.replace('|','')})}
          required={false}
        />

        <AddressField
          label="City"
          autoComplete="city"
          max_length={25}
          value={city}
          disabled={disabled}
          onChange={(e) => this.update_address({city: e.target.value.replace('|','')})}
          required={true}
        />

        <AddressField
          label="State"
          max_length={10}
          autoComplete="state"
          value={state}
          disabled={disabled}
          onChange={(e) => this.update_address({state: e.target.value.replace('|','')})}
          required={true}
        />

        <AddressField
          label="Zip Code"
          autoComplete="zip_code"
          max_length={10}
          value={zip_code}
          disabled={disabled}
          onChange={(e) => this.update_address({zip_code: e.target.value.replace('|','')})}
          required={true}
        />

        <AddressField
          label="Country"
          autoComplete="country"
          max_length={10}
          value={country}
          disabled={disabled}
          onChange={(e) => this.update_address({country: e.target.value.replace('|','')})}
          required={true}
        />
      </div>
    )
  }
}

AddressFields.propTypes = {
  update_address: PropTypes.func.isRequired,

  name: PropTypes.string.isRequired,
  phone_number: PropTypes.string.isRequired,
  address_1: PropTypes.string.isRequired,
  address_2: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  zip_code: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
}

export {AddressFields, EMPTY_SHIPPING_ADDRESS};

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Markdown from 'react-markdown';
import {Link} from 'react-router-dom';

import ItemStore from '../product/ItemStore';
import {OrderItemsTable, ITEM_UNIT_VOLUME} from '../orders/OrderItemsTable';
import ItemGrid from '../product/ItemGrid';
import PresentationDAO from './PresentationDAO';
import Price from '../components/Price';
import util from '../util';
import {GetUserWrapper} from '../comments/CommentsViews';
import CartStore from '../cart/CartStore';

const GRID_VIEW = 'GRID_VIEW';
const TABLE_VIEW = 'TABLE_VIEW';

const PRESENTATION_VIEWS = [
  {
    display: 'View as Grid',
    view_type: GRID_VIEW,
  },
  {
    display: 'View as Table',
    view_type: TABLE_VIEW,
  },
]

const PRESENTATION_COLUMNS = [
  {
    key: 'items_per_carton',
    header_text: 'Items per Carton',
    right_align: true,
    to_value: (item, row_context) => item.items_per_carton,
    render: (item, value) => value,
  },
  ITEM_UNIT_VOLUME,
  {
    key: 'consolidation_zone',
    header_text: 'Consolidation Zone',
    center_align: true,
    to_value: (item, row_context) => item.vendor_code,
    render: (item, value) => value,
  },
  {
    key: 'msrp',
    header_text: 'Unit MSRP',
    right_align: true,
    to_value: (item, row_context) => item.price_msrp,
    render: (item, value) => <Price price={value}/>,
  },
  {
    key: 'map',
    header_text: 'Unit MAP',
    right_align: true,
    to_value: (item, row_context) => item.price_map,
    render: (item, value) => <Price price={value}/>,
  },
];

class PresentationView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current_view_type: TABLE_VIEW,

      success_message: null,
      send_to_email: '',
    }
  }

  componentDidMount() {
    const {presentation} = this.props;
    window.analytics.track('Presentation Viewed', {
      presentation_url_key: presentation.url_key,
      presentation_title: presentation.title,
    });
  }

  get_price_level_column(index) {
    const {presentation} = this.props;
    const price_display = presentation[`price_${index}_display`]
    const price_level = presentation[`price_${index}_level`]

    if (!price_level) {
      return null;
    }

    return {
      key: `price_${index}`,
      header_text: price_display,
      right_align: true,
      to_value: (item, row_context) => item[price_level],
      render: (item, value) => <Price price={value}/>,
    };
  }

  get_price_level_columns = () => {
    const price_level_columns = [];
    [1, 2, 3, 4].forEach((index) => {
      const price_level_column = this.get_price_level_column(index);
      if (price_level_column) {
        price_level_columns.push(price_level_column);
      }
    })

    return price_level_columns;
  }

  get_customer_price_column = () => {
    const {customer} = this.props;
    if (!customer) {
      return [];
    }

    const price_level = util.PRICE_GROUP_TO_PRICE_LEVEL[customer.price_group];

    return {
      key: 'my_cost',
      header_text: 'My Cost',
      right_align: true,
      to_value: (item, row_context) => item[price_level],
      render: (item, value) => <Price price={value}/>,
    };
  }

  update_cart_from_presentation = async () => {
    const {items, history} = this.props

    CartStore.reset_cart();

    items.forEach((item) => {
      CartStore.add_to_cart(item.sku, item.items_per_carton);
    })

    history.push('/cart')
  }

  send_email_with_presentation = async (e) => {
    e.preventDefault();

    this.setState({success_message: null});

    const {presentation} = this.props;
    const {send_to_email} = this.state;
    if (!send_to_email) {
      return await null;
    }

    await PresentationDAO.send_presentation_as_email(presentation.url_key, {send_to_email});

    this.setState({send_to_email: '', success_message: `Email sent to ${send_to_email}`})
  }

  render() {
    const {presentation, items, user} = this.props;
    const {current_view_type, send_to_email, success_message} = this.state;

    const {title, description} = presentation
    const skus = items.map(({sku}) => sku);

    return (
      <div>
        <div className="row mb-4">
          <div className="col-12 col-md-8 col-lg-6 mx-auto">
            <h3>{title}</h3>

            <div>
              <Markdown source={description}/>
            </div>

            {user && user.is_active && (
              <div className="mt-3">
                <button className="btn btn-link" onClick={this.update_cart_from_presentation}>
                  Update Cart from Presentation
                </button>

                <form className="form-inline mt-2" onSubmit={this.send_email_with_presentation}>
                  <input
                    required={true}
                    placeholder="Email"
                    type="email"
                    className="form-control"
                    value={send_to_email}
                    onChange={(e) => this.setState({send_to_email: e.target.value})}
                  />

                  <button className="btn btn-success ml-2" type="success">
                    Send Presentation
                  </button>

                </form>
                {success_message && (
                  <div className="alert alert-success">{success_message}</div>
                )}
              </div>
            )}

            <ul className="nav nav-pills mt-3">
              {PRESENTATION_VIEWS.map(({display, view_type}) => (
                <li className="nav-item">
                  <button type="button"
                    className={classNames("btn","btn-link","nav-link", {active: current_view_type === view_type})}
                    onClick={() => this.setState({current_view_type: view_type})}
                  >
                    {display}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {current_view_type === GRID_VIEW && (
          <ItemGrid items={items} non_filter_items={items} allow_filters={false}/>
        )}

        {current_view_type === TABLE_VIEW && (
          <OrderItemsTable
            extra_columns={
              PRESENTATION_COLUMNS.concat(this.get_price_level_columns()).concat(this.get_customer_price_column())
            }
            skus={skus}
            show_subtotal_row={false}
          />
        )}
      </div>
    )
  }
}

PresentationView.propTypes = {
  presentation: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  user: PropTypes.object,
  history: PropTypes.object,
}

class PresentationWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: true,
      error: null,

      presentation: null,
      items: null,
    }
  }

  componentDidMount = async() => {
    const {presentation_url_key} = this.props;

    const {presentation, items} = await PresentationDAO.get_presentation(presentation_url_key);
    ItemStore.add_items(items);
    this.setState({
      is_loading: false,
      presentation,
      items,
    })
  }

  render() {
    const {render} = this.props;
    const {is_loading, error, presentation, items} = this.state;

    if (error) {
      return <div className="alert alert-danger">{error}</div>;
    }

    if (is_loading) {
      return <div className="alert alert-warning">Loading...</div>;
    }

    return render({presentation, items});
  }
}

PresentationWrapper.propTypes = {
  presentation_url_key: PropTypes.string.isRequired,
}

const PresentationPage = ({match, history}) => (
  <div className="container">
    <div className="row">
      <div className="col-12">
        <GetUserWrapper
          render={({user, customer}) => (
            <PresentationWrapper
              presentation_url_key={match.params.presentation_url_key}
              render={({presentation, items}) => (
                <PresentationView
                  presentation={presentation}
                  items={items}
                  user={user}
                  customer={customer}
                  history={history}
                />
              )}
            />
          )}
        />
      </div>
    </div>
  </div>
);

PresentationPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      presentation_url_key: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.object.isRequired,
}

class PresentationListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: true,
      error: null,

      presentations: null,
    }
  }

  componentDidMount = async() => {
    const {presentations} = await PresentationDAO.get_presentations();
    this.setState({is_loading: false, presentations})

  }

  render() {
    const {is_loading, error, presentations} = this.state;

    if (error) {
      return <div className="alert alert-danger">{error}</div>;
    }

    if (is_loading) {
      return <div className="alert alert-warning">Loading...</div>;
    }

    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h3>Presentations</h3>

            <table className="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>URL Key</th>
                  <th>Title</th>
                  <th>Created By</th>
                  <th>Created On</th>
                </tr>
              </thead>

              <tbody>
                {presentations.map(({id, url_key, title, creator_email, created_at}) => (
                  <tr>
                    <td>{id}</td>
                    <td>
                      <Link to={`/presentations/${url_key}`}>
                        {url_key}
                      </Link>
                    </td>
                    <td>{title}</td>
                    <td>{creator_email}</td>
                    <td>{util.date_format_iso_to_day(created_at)}</td>
                    <td>
                      <a href={`/admin/presentation/edit?id=${id}`}>
                        Edit
                      </a>
                    </td>
                    <td>
                      <button
                        className="btn btn-link"
                        onClick={async() => {
                          if (window.confirm('Do you want delete the presentation?')) {
                            await PresentationDAO.delete_presentation(id);
                            window.location.reload();
                          }
                        }}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

export {PresentationPage, PresentationListPage}
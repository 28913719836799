import React from 'react';

import dao from '../dao';
import {FormInput, get_initial_state_from_field_definitions} from './FormInput';
import {EMPTY_SHIPPING_ADDRESS} from '../components/AddressFields';


const SWATCH_REQUEST_FIELDS = [
  {
    key: 'company_name',
    display: 'Company Name',
    type: 'text',
    required: true,
  },
  {
    key: 'email',
    display: 'Email',
    type: 'email',
    required: true,
  },
  {
    key: 'company_website',
    display: 'Company Website',
    type: 'text',
    required: true,
  },
  {
    key: 'phone',
    display: 'Phone',
    type: 'tel',
  },
  {
    key: 'how_did_you_hear_about_us',
    display: 'How did you hear about us?',
    type: 'textarea',
    required: true,
  },
  {
    key: 'request_details',
    display: 'Request Details',
    infoNode: (
      <p>
        Please include item name, item number (SKU), colors, and quantities.
      </p>
    ),
    required: true,
    type: 'textarea',
  },
  {
    key: 'shipping_address',
    display: 'Shipping Address',
    type: 'address',
    initial_value: EMPTY_SHIPPING_ADDRESS,
    large: true,
  },
];

class SwatchRequestForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = get_initial_state_from_field_definitions(SWATCH_REQUEST_FIELDS);
  }

  requestCatalog = async (e) => {
    e.preventDefault();

    const {shipping_address} = this.state;
    const request_data = Object.assign({...shipping_address}, this.state);

    await dao.createSwatchRequest(request_data);
    window.location = '/requests/success';
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-8 mx-auto">
            <h2>Request A <span className="bold">Swatch</span></h2>

            <p>
              Are you curious to see what our materials look like up close? Or are you interested in
              modifying one of our products?
            </p>

            <p>
              Fill out the following forms to request a swatch and we will be in touch shortly!
            </p>

            <form onSubmit={this.requestCatalog}>
              <div className="row">

                {SWATCH_REQUEST_FIELDS.map((request_field) => (
                  <FormInput
                    updateValue={(new_value) => this.setState({[request_field.key]: new_value})}
                    value={this.state[request_field.key]}
                    fieldKey={request_field.key}
                    {...request_field}
                  />
                ))}

              </div>

              <button className="btn btn-success" type="submit">
                Submit Request
              </button>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default SwatchRequestForm;
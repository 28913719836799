import React from 'react';

const AddressLine = ({value}) => {
  if (!value) {
    return null;
  }

  return <span>{value}<br /></span>
}

const AddressDisplay = ({name, phone_number, address_1, address_2, city, state, zip_code, country}) => {
  if (!name && !phone_number && !address_1 && !address_2 && !city && !state && !zip_code && !country) {
    return 'No address found.';
  }

  return (
    <address>
      <AddressLine value={name} />
      <AddressLine value={phone_number} />
      <AddressLine value={address_1} />
      <AddressLine value={address_2} />
      <AddressLine value={`${city} ${state} ${zip_code}`} />
      <AddressLine value={country} />
    </address>
  );
}

export default AddressDisplay;
import PropTypes from 'prop-types';
import React from 'react';

import dao from '../dao';
import DescriptionList from '../components/DescriptionList';
import ItemUtil from './ItemUtil';

class ComponentsDimensions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: true,
      components: null,
    }
  }

  componentDidMount = async () => {
    const {sku} = this.props;
    const {components} = await dao.getItemComponents(sku);
    this.setState({components, is_loading: false});
  };

  render() {
    const {is_loading, components} = this.state;

    if (is_loading) {
      return <div>Loading components for item.</div>
    }
    if (!components.length) {
      return <div>Error, no components found for kit.</div>
    }

    var data = [];
    components.forEach((component) => data.push(...ItemUtil.dims_to_value(component)));
    return (
      <DescriptionList
        style={{fontSize: '0.85rem'}}
        col_className='col-6'
        render_empty_cells={true}
        description_schemas={data}
      />
    )
  }
}

ComponentsDimensions.propTypes = {
  sku: PropTypes.string.isRequired,
}

export default ComponentsDimensions;

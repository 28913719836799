import React from 'react';

import {FormInput} from '../requests/FormInput';
import CustomerUtil from './CustomerUtil';

const CUSTOMER_INFORMATION = [
  {
    key: 'name',
    display: 'Company Name',
    required: true,
    large: true,
  },
  {
    key: 'email',
    display: 'Email',
    type: 'email',
    required: true,
  },
  {
    key: 'phone_number',
    display: 'Phone Number',
    type: 'tel',
    required: true,
  },
  {
    key: 'primary_contact_name',
    display: 'Primary Contact',
    required: true,
  },
  {
    key: 'category',
    display: 'Category',
    type: 'select',
    initial_value: 'DESN',
    options: ['DESN', 'CNTC', 'STCK', 'ECOM', 'OTHR'],
    required: true,
  },
  {
    key: 'city',
    display: 'City',
  },
  {
    key: 'state',
    display: 'State',
  },
  {
    key: 'zip_code',
    display: 'Zip Code',
  },
  {
    key: 'notes',
    display: 'Notes',
    type: 'textarea',
    large: true,
  },
];

const CUSTOMER_CATEGORY_TO_PRICE_LEVEL = {
  STCK: 'I',
  DESN: 'D',
  ECOM: 'H',
  CNTC: 'C',
  OTHR: 'C',
}


class NewCustomerPage extends React.Component {
  constructor(props) {
    super(props);
    // TODO: this should be form data state, not top level state. Because we also want to have is_loading, etc
    this.state = CUSTOMER_INFORMATION.reduce((obj, request_field) => {
      let initial_value;
      if (request_field.type === 'select') {
        initial_value = request_field.initial_value;
      } else {
        initial_value = '';
      }

      obj[request_field.key] = initial_value;
      return obj;
    }, {
      is_loading: false,
    });
  }

  create_customer = async (e) => {
    e.preventDefault();
    this.setState({is_loading: true});

    const customer_data = this.state;
    customer_data.price_group = CUSTOMER_CATEGORY_TO_PRICE_LEVEL[customer_data.category];

    const customer = await CustomerUtil.create_customer(customer_data);
    this.props.history.push(`/internal/customers/${customer.id}`)
  }

  render() {
    const {is_loading} = this.state;

    return (
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-8 mx-auto">
            <h2>Create a <span className="bold">New Customer</span></h2>

            <form onSubmit={this.create_customer}>
              <div className="row">
                {CUSTOMER_INFORMATION.map((request_field) => (
                  <FormInput
                    updateValue={(new_value) => this.setState({[request_field.key]: new_value})}
                    value={this.state[request_field.key]}
                    fieldKey={request_field.key}
                    {...request_field}
                  />
                ))}
              </div>

              <button className="btn btn-success" type="submit" disabled={is_loading}>
                Create Customer
              </button>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default NewCustomerPage;
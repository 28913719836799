import React from 'react';

import OrderTable from './OrderTable'

const ALL_CATEGORY = 'ALL';
const CUSTOMER_CATEGORIES = [
  ALL_CATEGORY, 'ECOM', 'DESN', 'STCK', 'CNTC',
]

class AdminOrdersTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      customer_category: ALL_CATEGORY,
      start_date: null,
      end_date: null,
    }
  }

  render() {
    const {orders} = this.props;
    const {customer_category, start_date, end_date} = this.state;

    const orders_to_show = orders.filter((order) => {
      if (customer_category === ALL_CATEGORY) {
        return true;
      }

      return order.customer.category === customer_category;
    }).filter(({created_at}) => {
      if (!start_date) {
        return true
      }

      return new Date(created_at) >= new Date(start_date);
    }).filter(({created_at}) => {
      if (!end_date) {
        return true
      }

      return new Date(created_at) <= new Date(end_date);
    });

    return (
      <div>
        <div className="mb-4 form-inline">
          <div className="form-group mr-3">
            <label className="mr-1">
              Customer Category
            </label>
            <select
              onChange={(e) =>
                this.setState({customer_category: e.target.value})}
              value={customer_category}
              required={true}
              className="form-control"
            >
              {CUSTOMER_CATEGORIES.map((category) => {
                return <option key={category} value={category}>{category}</option>
              })}
            </select>
          </div>

          <div className="form-group m-3">
            <label className="mr-1">
              Start Date
            </label>
            <input
              type="date"
              className="form-control"
              value={start_date}
              onChange={(e) => this.setState({start_date: e.target.value})}
            />
          </div>

          <div className="form-group mr-3">
            <label className="mr-1">
              End Date
            </label>
            <input
              type="date"
              className="form-control"
              value={end_date}
              onChange={(e) => this.setState({end_date: e.target.value})}
            />
          </div>
        </div>

        <div>

        </div>

        <OrderTable
          orders={orders_to_show}
          show_sent_to_nav_column={true}
        />,
      </div>
    )
  }
}

AdminOrdersTab.propTypes = {}

export {AdminOrdersTab};
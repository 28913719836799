import React from 'react';

const FeaturesAndBenefits = ({features_and_benefits, style}) => {
  if (!features_and_benefits) {
    return null;
  }

  return (
    <div style={style}>
      <h6>Features</h6>
      <div>
        <ul>
          {features_and_benefits.split('\n').map((feature, i) => (
            <li key={`feature-${i}`}>{feature}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default FeaturesAndBenefits;

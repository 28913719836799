import PropTypes from 'prop-types';
import React from 'react';

import FontAwesome from 'react-fontawesome';
import CartStore from './CartStore';
import ItemStore from '../product/ItemStore';


const ItemCartQuantityWarnings = ({item, quantity_in_cart}) => {
  const {quantity_available, items_per_carton} = item;

  const quantity_not_carton_pack = quantity_in_cart % items_per_carton !== 0;
  const quantity_not_available = quantity_in_cart > quantity_available;

  return (
    <div>
      {quantity_not_available && (
        <div className="text-danger">
          Only {quantity_available} available.
        </div>
      )}

      {/*quantity_not_carton_pack && (
        <div className="text-danger">
          Comes in sets of {items_per_carton}.
        </div>
      )*/}
    </div>
  );
}

ItemCartQuantityWarnings.propTypes = {
  item: PropTypes.object.isRequired,
  quantity_in_cart: PropTypes.number.isRequired,
}

class ModifyQuantityButtonGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show_input: false,
    }
  }

  increment_quantity = () => {
    const {items_per_carton, quantity, update_quantity} = this.props;
    const new_quantity = quantity + items_per_carton;
    update_quantity(new_quantity);
  }

  decrement_quantity = () => {
    const {items_per_carton, quantity, update_quantity, minimum_quantity} = this.props;

    const min_qty = minimum_quantity == null ? items_per_carton : minimum_quantity;

    const new_quantity = Math.max(quantity - items_per_carton, min_qty);
    update_quantity(new_quantity);
  }

  render() {
    const {items_per_carton, quantity, update_quantity} = this.props;
    const {show_input} = this.state;

    if (show_input) {
      return (
        <input
          type='number'
          step={items_per_carton}
          min="0"
          style={{maxWidth: 100}}
          className="form-control"
          onChange={(e) => {
            const new_quantity = parseInt(e.target.value, 10) || 0;
            update_quantity(new_quantity);
          }}
          value={quantity}
        />
      )
    }

    return (
      <div className="btn-group">
        <button type="button" className="btn btn-secondary" onClick={this.decrement_quantity}>
          <FontAwesome name="minus"/>
        </button>
        <button type="button" className="btn btn-light"
                onDoubleClick={() => this.setState({show_input: true})}>
          {quantity}
        </button>
        <button type="button" className="btn btn-secondary" onClick={this.increment_quantity}>
          <FontAwesome name="plus"/>
        </button>
      </div>
    )
  }
}

ModifyQuantityButtonGroup.propTypes = {
  items_per_carton: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  minimum_quantity: PropTypes.number,
  update_quantity: PropTypes.func.isRequired,
}

class AddToCartButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quantity: 1,
      show_input: false,
    }
  }

  add_to_cart = async(e) => {
    e.preventDefault();
    const {sku} = this.props;
    const {quantity} = this.state;

    CartStore.add_to_cart(sku, quantity * this.props.items_per_carton);

    this.props.history.push('/cart');
  }

  render() {
    const {quantity} = this.state;

    const {sku} = this.props;
    const item = ItemStore.get_item(sku);

    return (
      <div>
        <form onSubmit={this.add_to_cart} className="form-inline mt-5">
          <ModifyQuantityButtonGroup
            quantity={quantity}
            items_per_carton={1}
            update_quantity={(quantity) => this.setState({quantity})}
          />
          {this.props.items_per_carton > 1 && (
            <span style={{ paddingLeft: '10px', alignSelf: 'center'}}>set(s)</span>
          )}

          <button type="submit" className="btn btn-success ml-5">
            Add to Cart
          </button>
        </form>

        <ItemCartQuantityWarnings
          item={item}
          quantity_in_cart={quantity}
        />
      </div>
    );
  }
}

AddToCartButton.propTypes = {
  history: PropTypes.object.isRequired,
  sku: PropTypes.string.isRequired,
  items_per_carton: PropTypes.number.isRequired,
}

export {AddToCartButton, ItemCartQuantityWarnings, ModifyQuantityButtonGroup};
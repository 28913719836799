import createReactClass from 'create-react-class';
import React from 'react';
import _ from 'lodash';

import dao from '../dao';
import util from '../util';

const GENERIC = 'Generic';
const AMAZON = 'Amazon';
const BED_BATH_AND_BEYOND = 'Bed Bath and Beyond';
const LAMPS_PLUS = 'Lamps Plus';
const WAYFAIR = 'Wayfair';
const WAYFAIR_IMAGES = 'Wayfair Images';
const HOUZZ = 'Houzz';
const OVERSTOCK = 'Overstock';
const ACTIVE_ITEMS = 'Active Items';
const ECOMM_ISSUES = 'eComm Issues';

const ECOMM_RETAILERS = [
  ACTIVE_ITEMS,
  GENERIC,

  AMAZON,
  BED_BATH_AND_BEYOND,
  HOUZZ,
  LAMPS_PLUS,
  OVERSTOCK,
  WAYFAIR,
  WAYFAIR_IMAGES,

  ECOMM_ISSUES,
]

const NO_PRICE_LEVEL = 'NO_PRICE_LEVEL';
const price_display_to_level = _.clone(util.PRICE_GROUP_TO_PRICE_LEVEL);
price_display_to_level['No Price Level'] = NO_PRICE_LEVEL;

const CAN_HAVE_PRICE_LEVEL = [ACTIVE_ITEMS, GENERIC];

const CAN_EXPORT_NOT_UPLOADED_SKUS = [
  AMAZON, BED_BATH_AND_BEYOND, HOUZZ, LAMPS_PLUS, OVERSTOCK, WAYFAIR];

const ExportProductGrid = createReactClass({
  getInitialState() {
    return {
      is_loading: false,

      export_not_uploaded_skus: false,
      sku_text: '',

      ecomm_retailer: GENERIC,
      price_level: NO_PRICE_LEVEL,
    }
  },

  async exportProductGrid(e) {
    e.preventDefault()
    this.setState({is_loading: true});

    const {sku_text, export_not_uploaded_skus, ecomm_retailer, price_level} = this.state;

    const data = {ecomm_retailer, price_level};

    if (export_not_uploaded_skus) {
      data.export_not_uploaded_skus = true;
    } else {
      data.skus = sku_text.split('\n').map((sku) => sku.trim()).filter((sku) => sku);
    }

    try {
      const {s3_key} = await dao.createProductGrid(data);

      const s3_url = `https://s3-us-west-2.amazonaws.com/eurostyle-item-photos/${s3_key}`
      window.open(s3_url)

      this.setState({is_loading: false, error: ''});
    } catch (e) {
      let error = 'Problem processing files';

      const {responseJSON} = e;
      if (responseJSON) {
        const {error_message} = responseJSON;
        if (error_message) {
          error = error_message;
        }
      }

      this.setState({is_loading: false, error});
      return await null;
    }
  },

  render() {
    const {error, is_loading, price_level, ecomm_retailer, export_not_uploaded_skus} = this.state;

    return (
      <div className="container">
        <div className="row">
          <div className="mx-auto col-md-8 col-12">
            <h2>Export Product Grid</h2>

            <p>
              Enter a list of SKUs on each line to export a product grid for those products.
            </p>

            <form onSubmit={this.exportProductGrid}>
              <div className="form-group">
                <label>
                  Retailer for Product Grid
                </label>
                <select
                  onChange={(e) =>
                    this.setState({ecomm_retailer: e.target.value, export_not_uploaded_skus: false})}
                  value={ecomm_retailer}
                  required={true}
                  className="form-control"
                >
                  {ECOMM_RETAILERS.map((retailer) => {
                    return <option key={retailer} value={retailer}>{retailer}</option>
                  })}
                </select>
              </div>

              {CAN_HAVE_PRICE_LEVEL.includes(ecomm_retailer) && (
                <div className="form-group">
                  <label>
                    Price Level
                  </label>
                  <select
                    className="form-control"
                    required={true}
                    onChange={(e) => this.setState({price_level: e.target.value})}
                    value={price_level}
                  >
                    {_.map(price_display_to_level, (price_level, price_display) =>
                      <option value={price_level}>{price_display}</option>)}
                  </select>
                </div>
              )}

              {CAN_EXPORT_NOT_UPLOADED_SKUS.includes(ecomm_retailer) && (
                <div className="form-check">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={export_not_uploaded_skus}
                      onChange={() => this.setState({export_not_uploaded_skus: true})}
                    /> Export SKUs that are not uploaded
                  </label>
                </div>
              )}

              {!export_not_uploaded_skus && (
                <div>
                  <label>
                    SKU List
                  </label>
                  <textarea
                    className="form-control"
                    value={this.state.sku_text}
                    placeholder='Enter skus'
                    onChange={(e) => this.setState({sku_text: e.target.value})}
                    style={{marginBottom: 20}}
                  />
                </div>
              )}

              {is_loading && (
                <div>Creating product grid......</div>
              )}
              {error && (
                <div className="alert alert-danger">
                  <pre>
                    {error}
                  </pre>
                </div>
              )}

              <button className="btn btn-success" type="submit" disabled={is_loading}>
                Export Product Grid
              </button>
            </form>
          </div>
        </div>
      </div>
    )
  }
})

export default ExportProductGrid;

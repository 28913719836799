import React from 'react';
import PropTypes from 'prop-types';

class Modal extends React.Component {
  render() {
    // Render nothing if the "show" prop is false
    if(!this.props.show) {
      return null;
    }

    // The gray background
    const backdropStyle = {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: 'rgba(0,0,0,0.3)',
      padding: 150
    };

    // The modal "window"
    const modalStyle = {
      backgroundColor: '#fff',
      borderRadius: 5,
      maxWidth: 500,
      margin: '0 auto'
    };

    return (
      <div style={backdropStyle}>
          <div style={modalStyle}>
              <div className="modal-header">
                <h5 className="modal-title">{this.props.title}</h5>
                <button className="close" onClick={this.props.onClose}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>{this.props.children}</p>
              </div>
              <div className="modal-footer">
                <button className="btn btn-success" onClick={this.props.onRun}>Place Order</button>
                <button className="btn btn-secondary" onClick={this.props.onClose}>Close</button>
              </div>
          </div>
      </div>
    );
  }
}

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
  children: PropTypes.node
};

export default Modal;
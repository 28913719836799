import PropTypes from 'prop-types'
import React from 'react';
import {Link} from 'react-router-dom';

const SingleBlogPost = ({blog_post, just_preview}) => {
  const html = just_preview ? blog_post.properties.excerpt : blog_post.properties.body;
  return (
    <div className='mb-5'>
      <h3>
        <Link className='blogPage-post' to={`/newsletter/${blog_post.slug}`}>
          {blog_post.properties.title}
        </Link>
      </h3>
      <div dangerouslySetInnerHTML={{__html: html}}/>
    </div>
  );
}

SingleBlogPost.propTypes = {
  blog_post: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    properties: PropTypes.shape({
      title: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  just_preview: PropTypes.bool.isRequired
};

SingleBlogPost.defaultProps = {
  just_preview: false,
}


export default SingleBlogPost;

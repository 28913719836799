import * as net from '../net';

const CustomerDAO = {};

CustomerDAO.get_customers = () => net.get({url: '/api/customers'});

CustomerDAO.get_customer = (id) => net.get({url: `/api/customers/${id}`});

CustomerDAO.create_customer = (data) => net.post({url: '/api/customers', data});

CustomerDAO.update_customer = (customer_id, data) =>
  net.post({url: `/api/customers/${customer_id}`, data});

CustomerDAO.get_orders = (id) => net.get({url: `/api/customers/${id}/orders`});

CustomerDAO.get_users = (id) => net.get({url: `/api/customers/${id}/users`});

CustomerDAO.get_best_items = (id) => net.get({url: `/api/customers/${id}/best-items`});

CustomerDAO.get_best_items_report = (id) => net.get({url: `/api/customers/${id}/best-items-report`});

CustomerDAO.get_sales_by_month = (id, data) => net.get({url: `/api/customers/${id}/sales-by-month`, data});

CustomerDAO.get_sales_by_month_for_customers = (customer_ids) =>
  net.post({url: '/api/customers/sales-by-month', data: {customer_ids}});

CustomerDAO.set_user_buying_for_customer = (data) =>
  net.post({url: '/api/customers/actions/set-user-buying-for-customer', data});

CustomerDAO.get_shipping_address = (customer_id) =>
  net.get({url: `/api/customers/${customer_id}/shipping-address`})

CustomerDAO.update_shipping_address = (customer_id, data) =>
  net.post({url: `/api/customers/${customer_id}/shipping-address`, data})

CustomerDAO.get_billing_address = (customer_id) =>
  net.get({url: `/api/customers/${customer_id}/billing-address`})

CustomerDAO.update_billing_address = (customer_id, data) =>
  net.post({url: `/api/customers/${customer_id}/billing-address`, data})

CustomerDAO.get_resale_certificate = (customer_id) =>
  net.get({url: `/api/customers/${customer_id}/resale-certificate`})

CustomerDAO.upload_resale_certificate = (customer_id, {files}) =>
  net.postFiles({url: `/api/customers/${customer_id}/resale-certificate`, files})

CustomerDAO.delete_resale_certificate = ({uuid}) =>
  net.post({url: '/api/customers/delete-resale-certificate', data: {uuid}})

CustomerDAO.get_terms_and_conditions_doc = (customer_id) =>
  net.get({url: `/api/customers/${customer_id}/terms-and-conditions-doc`})

// TODO: this should be moved to reports or somewhere else
CustomerDAO.get_uploaded_ecomm_products = () => net.get({url: '/api/reports/ecomm-uploaded-products'});

CustomerDAO.get_credit_cards = (customer_id) => net.get({url: `/api/customers/${customer_id}/cards`})

CustomerDAO.create_new_credit_card = (customer_id, data) =>
  net.post({url: `/api/customers/${customer_id}/cards`, data})

CustomerDAO.create_comment = (customer_id, data) =>
  net.post({url: `/api/customers/${customer_id}/comments`, data})

CustomerDAO.get_comments = (customer_id) =>
  net.get({url: `/api/customers/${customer_id}/comments`})

CustomerDAO.send_resale_certificate_for_signature = (customer_id, data) =>
  net.post({url: `/api/customers/${customer_id}/send-resale-certificate-for-signature`, data})

CustomerDAO.merge_customers = (data) =>
  net.post({url: '/api/customers/merge-customers', data})

export default CustomerDAO;

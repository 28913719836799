import createReactClass from 'create-react-class';
import React from 'react';

import ItemCard from '../components/ItemCard';
import dao from '../dao';

const CollectionItemCards = createReactClass({
  getInitialState() {
    return {collection_items: null};
  },

  async componentDidMount() {
    const collection_name = this.props.collection_name;
    const collection_items = await dao.getCollectionItems(collection_name)
    this.setState({collection_items});
  },

  render() {
    if (!this.state.collection_items) {
      return <div>Loading collection items...</div>;
    }

    return (
      <div>
        {this.state.collection_items.map((item) =>
          <ItemCard key={item.sku} item={item} linkify_sku={true} />)}
      </div>
    );
  }
});

const CollectionCard = createReactClass({
  getInitialState() {
    return {collection: null};
  },

  async componentDidMount() {
    const collection_name = this.props.collection_name;
    const collection = await dao.getCollection(collection_name)
    this.setState({collection});
  },

  render() {
    if (!this.state.collection) {
      return <div>Loading Collection...</div>
    }
    const {name} = this.state.collection;
    return (
      <div style={{marginBottom: 40}}>
        <h2>{name} Collection</h2>
      </div>
    )
  }
});

const CollectionPage = createReactClass({
  render() {
    const {collection_name} = this.props.match.params;
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <h1>Collection Page</h1>
            <hr />

            <CollectionCard collection_name={collection_name} />
            <CollectionItemCards collection_name={collection_name} />
          </div>
        </div>
      </div>
    );
  }
})

export default CollectionPage;

import PropTypes from 'prop-types';
import React from 'react';

import CartStore from './CartStore';

class CartDataWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.getCartState();
  }


  componentDidMount() {
    CartStore.on('change', this.setCartState);
  }

  componentWillUnmount() {
    CartStore.removeListener('change', this.setCartState);
  }

  setCartState = () => {
    this.setState(this.getCartState());
  }

  getCartState = () => {
    return {
      cart_sku_to_quantity: CartStore.get_cart()
    }
  }

  render() {
    const {render} = this.props;
    const {cart_sku_to_quantity} = this.state

    return (
      render({cart_sku_to_quantity})
    )
  }
}

CartDataWrapper.propTypes = {
  render: PropTypes.func.isRequired,
}

export {CartDataWrapper};
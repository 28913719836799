import React from 'react';
import Dropzone from 'react-dropzone';

import * as net from '../net';
import {ExportImagesToDropboxSection} from './ExportImagesToDropboxSection';

const PRODUCT_IMAGE_TYPE = 'Product';
const LIFESTYLE_IMAGE_TYPE = 'Lifestyle';
const FACTORY_IMAGE_TYPE = 'Factory';
const IMAGE_TYPES = [PRODUCT_IMAGE_TYPE, LIFESTYLE_IMAGE_TYPE, FACTORY_IMAGE_TYPE];
const dropstyle = {width: 200, height: 200, borderWidth: 2, borderColor: '#666666', borderStyle: 'dashed', borderRadius: 5, margin: 5};


class UploadProductImagesSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      is_loading: false,
      error: null,
      image_type: PRODUCT_IMAGE_TYPE,
    }

  }

  onDrop = (files) => {
    this.setState({files});
  }

  uploadImages = async() => {
    this.setState({is_loading: true});
    const {files, image_type} = this.state;

    try {
      await net.postFiles({
        url: '/api/images/upload-images',
        files,
        data: JSON.stringify({image_type}),
      })
    } catch (e) {
      this.setState({is_loading: false, error: 'Problem processing files...'});
      return await null;
    }

    this.setState({is_loading: false, files: [], error: ''});
  }

  render() {
    const {error, files, is_loading, image_type} = this.state;

    if (error) {
      return <div className="alert alert-danger">{error}</div>;
    }

    if (is_loading) {
      return <div className="alert alert-warning">Loading...</div>;
    }

    return (
      <div>
        <h3>Upload Images</h3>
        <p>
          Images must be labeled by SKU, i.e. <code>{'<sku>_<num>.jpg'}</code>.
        </p>

        <div className="form-check">
          <label>
            Image Type
          </label>
          <select
            className="form-control"
            required={true}
            onChange={(e) => this.setState({image_type: e.target.value})}
            value={image_type}
          >
            {IMAGE_TYPES.map((image_type_option) =>
              <option key={image_type_option} value={image_type_option}>{image_type_option}</option>)}
          </select>
        </div>

        <section>
          <div className="dropzone">
            <Dropzone
              accept="image/jpeg, image/gif, video/mp4"
              onDrop={this.onDrop.bind(this)}
            >
              {({getRootProps, getInputProps}) => (
                <div style={dropstyle} {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p>Try dropping some files here, or click to select files to upload.</p>
                </div>
              )}
            </Dropzone>
          </div>
          <aside>
            <h5>Dropped files</h5>
            <ul>
              { files.map(f => <li key={f.name}>{f.name}</li>) }
            </ul>
          </aside>
        </section>

        <button
          className="btn btn-success"
          onClick={this.uploadImages}
          disabled={files.length === 0 || is_loading}
        >
          Upload Images
        </button>
      </div>
    );
  }
}


class UploadAssemblyInstructions extends React.Component {
  default_state = {
    is_loading: false,
    error: null,
    success_message: false,
  }

  constructor(props) {
    super(props);
    this.state = this.default_state;
  }

  onDrop = async(files) => {
    await this.uploadAssemblyInstructions(files)
  }

  uploadAssemblyInstructions = async(files) => {
    this.setState(this.default_state);
    try {
      await net.postFiles({url: '/api/reports/upload-assembly-instructions', files})
    } catch (e) {
      this.setState({is_loading: false, error: 'Problem processing files...'});
      return await null;
    }

    this.setState({is_loading: false, error: '', success_message: true});
  }

  render() {
    const {error, is_loading, success_message} = this.state;
    return (
      <div>
        <h3>Upload Assembly Instructions</h3>

        <p>
          Assembly instructions file names should refer to the product name, e.g.
          <code>abby_coffee_table.pdf</code>.
        </p>

        <p>
          After assembly instructions are loaded, they can be associated with their corresponding products
          using the <a
          href="https://euro.style/admin/item">admin page for each product</a>.
        </p>

        <p>
          To update an assembly instruction, you can upload a file with the same file name as the current
          assembly instruction. That will update the assembly instruction on the server so that all products
          point to the updates file.
        </p>

        <section>
          <div className="dropzone">
            <Dropzone
              accept="application/pdf"
              onDrop={this.onDrop.bind(this)}
              disabled={this.state.is_loading}
            >
              {({getRootProps, getInputProps}) => (
                <div style={dropstyle} {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p>Drag and drop an assembly instruction PDF here.</p>
                </div>
              )}
            </Dropzone>
          </div>
        </section>

        {!is_loading && (
          <div>
            {success_message && (
              <div className="alert alert-success">Finished upload</div>
            )}

            {error && (
              <div className="alert alert-danger">{error}</div>
            )}
          </div>
        )}
      </div>
    );
  }
}

const UploadImagesPage = () => (
  <div className="container">
    <div className="row">
      <div className="mx-auto col-md-8">
        <h1>Upload Assets</h1>

        <UploadProductImagesSection />

        <hr />

        <UploadAssemblyInstructions />

        <hr />

        <ExportImagesToDropboxSection />
      </div>
    </div>
  </div>
)


export default UploadImagesPage;

import {Link} from 'react-router-dom';
import React from 'react';
import _ from 'lodash';

import CustomerDAO from '../customers/CustomerDAO';
import Price from '../components/Price';

const CUSTOMER_IDS = [
  6259,
  10718,
  27083,
  6518,
  6481,
  5211,
  20116,
  12163,
  11842,
  26522,
  4384,
  20077,
  6399,
  15425,
  10730,
  15492,
  19488,
  15017,
  26155,
]

class ECommOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: true,
      customer_id_to_info: null,
    };
  }

  componentDidMount = async() => {
    const {customer_id_to_info} = await CustomerDAO.get_sales_by_month_for_customers(CUSTOMER_IDS);
    this.setState({customer_id_to_info, is_loading: false})
  }

  render() {
    const {is_loading, customer_id_to_info} = this.state;

    if (is_loading) {
      return 'Loading ecomm information';
    }

    const sales_by_month_example = customer_id_to_info[CUSTOMER_IDS[0]].sales_by_month
    const customer_infos = _.sortBy(
      _.values(customer_id_to_info),
      ({sales_by_month}) => -1 * sales_by_month.reduce(
        (curr, month_tuple) => month_tuple[1].total_volume + curr, 0))

    return (
      <div>
        <h2>eCommerce Customers</h2>

        <p>
          This looks at all completed sales orders with an order date in the month. It should be pulling data
          for the last rolling calendar year.
        </p>

        <p>
          Credit memos are not included in this total.
        </p>

        <table className="table table-striped table-sm table-responsive">
          <thead>
            <tr>
              <th />
              {sales_by_month_example.map((sale_info) => (
                <th>
                  {sale_info[0]}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {_.map(customer_infos, (info) => (
              <tr>
                <th>
                  <Link to={`/internal/customers/${info.customer.id}`}>
                    {info.customer.name}
                  </Link>
                </th>
                {info.sales_by_month.map((sale_info) => (
                  <td className="text-right p-2">
                    <Price price={sale_info[1].total_volume} num_digits={1} show_thousands={true} />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>

      </div>
    )
  }
}

export default ECommOverview;


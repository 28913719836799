import PropTypes from 'prop-types';
import React from 'react';
import Dropzone from 'react-dropzone';

import UserStore from '../auth/UserStore';
import CustomerDAO from '../customers/CustomerDAO';
import util from '../util';

const dropstyle = {width: 200, height: 200, borderWidth: 2, borderColor: '#666666', borderStyle: 'dashed', borderRadius: 5, margin: 5};

class ShowResaleCertificate extends React.Component {
  constructor(props) {
    super(props);
    this.state = Object.assign({
      is_loading: false,
    }, this.getUserState());
  }

  componentDidMount() {
    UserStore.on('change', this.setUserState);
  }

  componentWillUnmount() {
    UserStore.removeListener('change', this.setUserState);
  }

  getUserState = () => {
    return {user: UserStore.get_user()};
  }

  setUserState = () => {
    this.setState(this.getUserState());
  }

  render() {
    const resale_certificate = this.props.resale_certificate;
    const {user} = this.state;
    const user_is_employee = user.user_type === 'Employee';

    if (resale_certificate.hello_sign_document) {
      const {is_complete, signer_email} = resale_certificate.hello_sign_document;

      if (is_complete) {
        return (
          <div className="col-12 col-sm-6">
            <h5>Resale Certificate Signed</h5>

            <p>
              Thanks for signing your resale certificate via HelloSign!
            </p>
          </div>
        );
      }

      return (
        <div className="col-12 col-sm-6">
          <h5>Resale Certificate Pending</h5>

          <p>
            We have sent your resale certificate to {signer_email}. Please follow instructions in that email
            to sign your resale certificate.
          </p>

          <p>
            If you can't find that email or need it sent somewhere else, shoot us an email at <a
            href="mailto:cs@euro.style">cs@euro.style</a>.
          </p>
        </div>
      );
    }

    return (
      <div className="col-12 col-sm-6">
        <h5>Resale Certificate</h5>

        <p>
          Thank you for uploading your resale certificate. To view your resale certificate {' '}
          <a href={util.getS3Url(resale_certificate.s3_key)} target='_blank' rel="noopener noreferrer">click here</a>.
        </p>
        {user_is_employee && (
        <div>
          <button type="button" className="btn btn-danger" onClick={async() => {
            if (window.confirm('Do you want delete resale certificate?')) {
              await CustomerDAO.delete_resale_certificate({uuid: resale_certificate.uuid})
              window.location.reload();
            }}}>Delete Resale Certificate</button>
          <p className="my-2">This button can only be seen by Euro Style employees.</p>
        </div>
        )}
      </div>
    );
  }
}

class CreateResaleCertificate extends React.Component {
  constructor(props) {
    super(props);
    this.state = Object.assign({
      is_loading: false,

      signer_name: '',
      signer_email: '',
    }, this.getUserState());
  }

  componentDidMount() {
    UserStore.on('change', this.setUserState);
  }

  componentWillUnmount() {
    UserStore.removeListener('change', this.setUserState);
  }

  getUserState = () => {
    return {user: UserStore.get_user()};
  }

  setUserState = () => {
    this.setState(this.getUserState());
  }

  upload_resale_certificate = async(file) => {
    this.setState({is_loading: true});

    const {customer_id, update_resale_certificate} = this.props;

    try {
      const {resale_certificate} = await CustomerDAO.upload_resale_certificate(customer_id, {files: [file]});
      update_resale_certificate(resale_certificate);

    } catch (e) {
      this.setState({is_loading: false, error: 'Problem uploading resale certificate...'});
      return await null;
    }
  }

  send_resale_certificate_for_signature = async(e) => {
    e.preventDefault();
    this.setState({is_loading: true});

    const {customer_id, update_resale_certificate} = this.props;
    const {signer_email, signer_name} = this.state

    const {resale_certificate} = await
      CustomerDAO.send_resale_certificate_for_signature(customer_id, {signer_email, signer_name});
    update_resale_certificate(resale_certificate);
  }

  render() {
    const {signer_name, signer_email, user} = this.state;
    const user_is_employee = user.user_type === 'Employee';

    return (
      <div className="col-12 col-sm-6">
        <h5>Sign a Resale Certificate</h5>

        <p>
          The resale certificate states that you will be reselling the goods you buy from Eurø Style and
          allows Eurø Style to waive sales tax on your purchases.
        </p>

        <p>
          Click the button below to send the resale certificate form to be electronically signed.
        </p>

        <form onSubmit={this.send_resale_certificate_for_signature} className="mb-5">
          <div className="form-group">
            <label>Name of Signer</label>
            <input
              required={true}
              type="text"
              className="form-control"
              value={signer_name}
              onChange={(e) => this.setState({signer_name: e.target.value})}
            />
          </div>

          <div className="form-group">
            <label>Email of Signer</label>
            <input
              required={true}
              type="email"
              className="form-control"
              value={signer_email}
              onChange={(e) => this.setState({signer_email: e.target.value})}
            />
          </div>

          <div className="form-group">
            <button type="submit" className="btn btn-success">Send Resale Certificate</button>
          </div>
        </form>

        <p>
          If you don't want to sign electronically, you can print and fill out can fill out {' '}
          <a href="http://www.cdtfa.ca.gov/formspubs/cdtfa230.pdf" target="_blank" rel="noopener noreferrer">this form</a> {' '}
          and email it to <a href="mailto:cs@euro.style">cs@euro.style</a>.
        </p>

        {user_is_employee && (
          <div>
            <hr className="mt-5"/>

            <p>
              This section can only be seen by Euro Style employees. When customers log in, they can only
              see the option to send an email.
            </p>

            <div className="dropzone">
              <Dropzone
                multiple={false}
                onDrop={(files) => this.upload_resale_certificate(files[0])}
              >
                {({getRootProps, getInputProps}) => (
                  <div style={dropstyle} {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>Drag and drop resale certificate here.</p>
                  </div>
                )}
              </Dropzone>
            </div>
          </div>
        )
        }
      </div>
    )
  }
}

CreateResaleCertificate.propTypes = {
  customer_id: PropTypes.number.isRequired,
  update_resale_certificate: PropTypes.func.isRequired,
}

class ResaleCertificateSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resale_certificate: null,

      error: null,
      is_loading: true,
    }
  }

  async componentDidMount() {
    const {customer_id} = this.props;
    const {resale_certificate} = await CustomerDAO.get_resale_certificate(customer_id);
    this.setState({resale_certificate, is_loading: false})
  }

  update_resale_certificate = (resale_certificate) => this.setState({resale_certificate});

  render() {
    const customer_id = this.props.customer_id;
    const {resale_certificate, is_loading, error} = this.state;

    if (error) {
      return <div className="alert alert-danger">{error}</div>;
    }

    if (is_loading) {
      return <div className="alert alert-warning">Loading resale certificate...</div>;
    }

    if (resale_certificate) {
      return <ShowResaleCertificate resale_certificate={resale_certificate}/>;
    }

    return <CreateResaleCertificate customer_id={customer_id} update_resale_certificate={this.update_resale_certificate}/>;
  }
}

ResaleCertificateSection.propTypes = {
  customer_id: PropTypes.number.isRequired,
}

export {ResaleCertificateSection};

import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';

import ItemReportDAO from './ItemReportDAO';
import util from '../util';

const ItemReportList = ({item_reports}) => (
  <div>
    <h3>Item Reports</h3>

    <table className="table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Created By</th>
          <th>Created On</th>
        </tr>
      </thead>

      <tbody>
        {item_reports.map(({id, name, created_by_user_email, created_at}) => (
          <tr>
            <td>
              <Link to={`/internal/item-reports/${id}`}>
                {id}
              </Link>
            </td>
            <td>{name}</td>
            <td>{created_by_user_email}</td>
            <td>{util.date_format_iso_to_day(created_at)}</td>
            <td>
              <Link to={`/internal/item-reports/${id}/edit`}>
                Edit
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)

ItemReportList.propTypes = {
  item_reports: PropTypes.array.isRequired,
}


class ItemReportsWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: true,
      error: null,

      item_reports: null,
    }
  }

  componentDidMount = async() => {
    const {item_reports} = await ItemReportDAO.get_item_reports();

    this.setState({is_loading: false, item_reports});
  }

  render() {
    const {render} = this.props;
    const {is_loading, error, item_reports} = this.state;

    if (error) {
      return <div className="alert alert-danger">{error}</div>;
    }

    if (is_loading) {
      return <div className="alert alert-warning">Loading...</div>;
    }

    return render({item_reports});
  }
}

ItemReportsWrapper.propTypes = {
  render: PropTypes.func.isRequired,
}


const ItemReportListPage = () => (
  <div className="container">
    <div className="row">
      <div className="col-12 col-md-10 mx-auto">
        <ItemReportsWrapper
          render={({item_reports}) => (
            <ItemReportList item_reports={item_reports}/>
          )}
        />
      </div>
    </div>
  </div>
)

export {ItemReportListPage};

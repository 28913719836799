import EventEmitter from 'events';

import Promise from 'bluebird';
import {Map} from 'immutable';
import _ from 'lodash';

import * as net from '../net';
import ItemStore from '../product/ItemStore';

// Each cart is a sku to quantity
const carts_to_save_queue = [];
let save_cart_request = Promise.resolve(null);

const save_cart_to_server = (sku_to_quantity) => {
  carts_to_save_queue.push(sku_to_quantity)

  const _save_cart_to_server = async(_sku_to_quantity) => {
    return await net.post({
      url: '/api/users/current-user/update-cart',
      data: {sku_to_quantity: _sku_to_quantity.toJSON()}
    })
  };

  const next_cart_to_save = carts_to_save_queue.shift();

  save_cart_request = save_cart_request.then(() => {
    return _save_cart_to_server(next_cart_to_save);
  });
};

const debounced_save_cart_to_server = _.debounce(save_cart_to_server, 500);


class CartStore extends EventEmitter {
  constructor() {
    super();

    this.sku_to_quantity = Map();
  }

  get_cart() {
    return this.sku_to_quantity;
  }

  set_cart(sku_to_quantity) {
    this.sku_to_quantity = sku_to_quantity;
    this.emit_change();
  }

  reset_cart() {
    this.sku_to_quantity = Map();
    this.emit_change();
  }

  remove_from_cart(sku) {
    this.sku_to_quantity = this.sku_to_quantity.remove(sku);
    this.emit_change();
  }

  clean_out_zero_quantity_items() {
    this.sku_to_quantity = this.sku_to_quantity.filter((quantity) => quantity > 0);
  }

  update_cart_quantity(sku, quantity) {
    if (typeof quantity !== 'number' || isNaN(quantity)) {
      quantity = 0;
    }

    this.sku_to_quantity = this.sku_to_quantity.set(sku, quantity);
    this.emit_change();
  }

  add_to_cart(sku, quantity_to_add) {
    const current_quantity = this.sku_to_quantity.get(sku) || 0;
    const new_quantity = current_quantity + quantity_to_add;
    this.sku_to_quantity = this.sku_to_quantity.set(sku, new_quantity);
    this.emit_change();
  }

  set_by_cart_items(cart_items) {
    let sku_to_quantity = Map();

    cart_items.forEach(({item_sku}) => {
      const current_quantity = sku_to_quantity.get(item_sku);
      if (!current_quantity) {
        sku_to_quantity = sku_to_quantity.set(item_sku, 1);
      } else {
        sku_to_quantity = sku_to_quantity.set(item_sku, current_quantity + 1);
      }
    })

    this.sku_to_quantity = sku_to_quantity;
    this.emit_change();
  }

  emit_change() {
    debounced_save_cart_to_server(this.sku_to_quantity);
    this.emit('change');
  }
}

const cart_store = new CartStore();

const get_current_cart = async() => {
  const {cart_items, items} = await net.get({url: '/api/users/current-user/cart'})

  ItemStore.add_items(items);
  cart_store.set_by_cart_items(cart_items);
}

get_current_cart();

export default cart_store;

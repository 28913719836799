import * as net from '../net';

const OrderDAO = {};

OrderDAO.get_order = (order_id) => net.get({url: `/api/orders/${order_id}`})

OrderDAO.update_order = (order_id, data) => net.post({url: `/api/orders/${order_id}`, data})

OrderDAO.get_orders = () => net.get({url: '/api/orders'});
OrderDAO.get_last_orders = () => net.get({url: '/api/last_orders'});
OrderDAO.get_orders_to_enter = () => net.get({url: '/api/orders_to_enter'});

OrderDAO.get_freight_quotes = (order_id, data) =>
  net.get({url: `/api/orders/${order_id}/freight-quotes`, data})

OrderDAO.get_shipping_address = (order_id) =>
  net.get({url: `/api/orders/${order_id}/shipping-address`})

OrderDAO.update_shipping_address = (order_id, data) =>
  net.post({url: `/api/orders/${order_id}/shipping-address`, data})

OrderDAO.get_order_stats = () => net.get({url: '/api/reports/order-stats'});

OrderDAO.get_item_stats = () => net.get({url: '/api/reports/order-item-stats'});

OrderDAO.create_order_shipment = (order_id, data) =>
  net.post({url: `/api/orders/${order_id}/order-shipment`, data})

OrderDAO.get_order_shipment = (order_id) =>
  net.get({url: `/api/orders/${order_id}/order-shipment`})

OrderDAO.send_to_nav_queue = (order_id) =>
  net.post({url: `/api/orders/${order_id}/send-to-nav-queue`})

export default OrderDAO;

import UserStore from '../auth/UserStore';

import CustomerDAO from './CustomerDAO';
import CustomerStore from './CustomerStore';

const CustomerUtil = {};

CustomerUtil.set_current_customer = async (customer) => {
  UserStore.set_customer(customer);
  await CustomerDAO.set_user_buying_for_customer({customer_id: customer.id});
}

CustomerUtil.get_customers = async () => {
  const is_fresh = CustomerStore.get_is_fresh();
  if (is_fresh) {
    const existing_customers = CustomerStore.get_customers();
    return existing_customers;
  }

  const {customers: fetched_customers} = await CustomerDAO.get_customers();
  CustomerStore.add_customers(fetched_customers);
  return CustomerStore.get_customers();
}

CustomerUtil.get_customer = async (customer_id) => {
  const is_fresh = CustomerStore.get_is_fresh();
  if (is_fresh) {
    return CustomerStore.get_customer(customer_id);
  }

  const {customer} = await CustomerDAO.get_customer(customer_id);
  return customer;
}

CustomerUtil.create_customer = async (customer_data) => {
  const {customer} = await CustomerDAO.create_customer(customer_data);
  await CustomerUtil.set_current_customer(customer);

  const is_fresh = CustomerStore.get_is_fresh();
  if (is_fresh) {
    CustomerStore.add_customer(customer);
  }

  return customer;
}

export default CustomerUtil;

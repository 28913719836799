const CategoryConstants = {}

CategoryConstants.CATEGORY_TO_INFO = {
  office: {
    display: 'Office',
    item_category_code: 'A',
  },
  bar: {
    display: 'Bar',
    item_category_code: 'B',
  },
  dining: {
    display: 'Dining',
    item_category_code: 'D',
  },
  occasional: {
    display: 'Occasional',
    item_category_code: 'C',
  },
  outdoor: {
    display: 'Outdoor',
    item_category_code: '',
  },
};

CategoryConstants.CATEGORIES = Object.keys(CategoryConstants.CATEGORY_TO_INFO);

export default CategoryConstants;

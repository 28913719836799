import * as net from './net';

const ItemDAO = {};

ItemDAO.get_items_as_employee = ({
  is_ecommerce_product = null,
  do_not_show_on_website_from_nav = null,
  status = null,
  show_components = false,
}) =>
  net.get({
    url: '/api/items/privileged',
    data: {
      is_ecommerce_product,
      status,
      show_components,
      do_not_show_on_website_from_nav,
    }
  });

ItemDAO.get_parcel_shipping_rates = (sku, destination_zip) =>
  net.get({url: `/api/items/${sku}/parcel-shipping-quotes`, data: {destination_zip}});

export default ItemDAO;

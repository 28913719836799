import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';

import dao from '../dao';

const ComponentInfo = ({sku, description_es, price_msrp, quantity_available}) => (
  <div className='card mb-2'>
    <div className='card-body'>
      <div className='font-weight-bold'>
        <Link to={`/products/${sku}`}>
          {sku}
        </Link>
      </div>

      <div>{description_es}</div>
      <div>Quantity Available: {quantity_available}</div>
    </div>
  </div>
);

class ComponentsSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: true,
      components: null,
    }
  }

  componentDidMount = async () => {
    const {sku} = this.props;
    const {components} = await dao.getItemComponents(sku);
    this.setState({components, is_loading: false});
  };

  render() {
    const {is_loading, components} = this.state;

    if (is_loading) {
      return <div>Loading components for item.</div>
    }
    if (!components.length) {
      return <div>Error, no components found for kit.</div>
    }

    return (
      <div className={this.props.className}>
        <h5>Components</h5>
        {components.map((component) => <ComponentInfo key={component.sku} {...component} />)}
      </div>
    );
  }
}

ComponentsSection.propTypes = {
  sku: PropTypes.string.isRequired,
}

export default ComponentsSection;
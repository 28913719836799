import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';

import util from '../util';

import './ItemPreviewSquare.css';

class ItemPreviewSquare extends React.Component {
  render() {
    const {item, has_extra_colors, show_color_variation} = this.props;
    const description = item.product_name || item.description_es;
    const link_location = `/product/${item.sku}`;

    // TODO: come up with a better batch way of getting all of the main item images
    // TODO: render a sillhouette as the sample image
    return (
      <Link
        to={link_location}
        className="col-12 col-sm-6 col-md-4 col-lg-3 itemPreviewSquare"
      >
        <div className="itemPreviewSquare-imgContainer">
          {item.main_image_s3_key ? (
              <img src={util.getS3Url(item.main_image_s3_key, {size: 'medium'})} alt={description} className="itemPreviewSquare-img" />
            ) : (
              <img src="/static/sample_img.jpg" alt={description} className="itemPreviewSquare-img" />
            )
          }
        </div>
        <div className="itemPreviewSquare-textContainer">
          <div><strong>{description}</strong></div>
          {/*<div>MSRP ${item.price_msrp}</div>*/}

          {has_extra_colors && (
            <div style={{color: 'darkgray'}}>
              Additional Colors
            </div>
          )}

          {show_color_variation && item.variation_color_value && (
            <div style={{color: 'darkgray'}}>
              {item.variation_color_value}
            </div>
          )}
        </div>
        <div style={{height:24}}>
          <div className="itemPreviewSquare-textContainer stock" style={{position:'absolute', bottom: 10}}>
            {item.closeout ? item.quantity_available > 0 ? (
              <span style={{padding: '0px 10px 2px', color: 'red', fontSize: 'smaller', fontWeight: 'bold'}}>
                Last Chance
              </span>
            ) : (
              <span style={{padding: '0px 10px 2px', color: 'red', fontSize: 'smaller', fontWeight: 'bold'}}>
                Out of stock
              </span>
            ) : item.quantity_available > 0 ? (
              <span style={{padding: '0px 10px 2px', color: 'green', fontSize: 'smaller', fontWeight: 'bold'}}>
                In Stock
              </span>
            ) : (
              <span style={{padding: '0px 10px 2px', color: 'red', fontSize: 'smaller', fontWeight: 'bold'}}>
                Arriving Soon
              </span>
            )
            }
          </div>
        </div>
      </Link>
    )
  }
}

ItemPreviewSquare.propTypes = {
  has_extra_colors: PropTypes.bool,
  show_color_variation: PropTypes.bool,
}

export default ItemPreviewSquare;

import React from 'react';
import _ from 'lodash';

import dao from '../dao';
import ItemGrid from '../product/ItemGrid';


class FactoryPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: null,
    };
  }

  async componentDidMount() {
    const {factory_code} = this.props.match.params;
    const items = await dao.getItems({
      vendor_code: factory_code,
    });
    this.setState({items});
  }

  render() {
    const {factory_code} = this.props.match.params;

    const {items} = this.state;
    const sorted_items = _.sortBy(items, ({status, description_es}) => [status, description_es])
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-12 mx-auto col-sm-8">
              <h3>Products From <span className="bold">{factory_code}</span></h3>
              <a className="btn btn-secondary" target="_blank" rel="noopener noreferrer" href={`/api/items/factory/${factory_code}`}>
                Download CSV
              </a>
            </div>
          </div>
        </div>
        <hr />
        <ItemGrid items={sorted_items} non_filter_items={sorted_items}/>
      </div>
    );
  }
}

export default FactoryPage;
import React from 'react';

import ReportDAO from './ReportDAO';

const DEFAULT_ERROR = 'Problem exporting report...';

class ExportCustomColumnsReport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sku_text: '',
      column_text: '',
      error: null,
      is_loading: false,
    }
  }

  generateProductTags = async(e) => {
    e.preventDefault()
    this.setState({is_loading: true, error: null});

    const {sku_text, column_text} = this.state;
    const skus = sku_text.trim().split('\n');
    const columns = column_text.split('\n');

    try {
      const {s3_key} = await ReportDAO.export_custom_column_report({skus, columns});

      const s3_url = `https://s3-us-west-2.amazonaws.com/eurostyle-item-photos/${s3_key}`
      window.open(s3_url)

      this.setState({is_loading: false, error: ''});
    } catch (e) {
      let error = DEFAULT_ERROR;

      const {skus_not_found, error_message} = e.responseJSON;

      if (error_message) {
        error = error_message;
      }

      if (skus_not_found) {
        error = ['Skus not found:'].concat(skus_not_found).join('\n')
      }

      this.setState({is_loading: false, error});
      return await null;
    }
  }

  render() {
    const {error, is_loading} = this.state;

    return (
      <div>
        <h2>Export Custom Report</h2>

        <p>
          This will export a CSV with custom columns based on the attributes specified.
          Right now this will only support item attributes.
        </p>

        <form onSubmit={this.generateProductTags}>
          <div className="form-group mb-2">
            <label>
              SKU List
            </label>
            <textarea
              className="form-control"
              value={this.state.sku_text}
              placeholder='Enter skus'
              onChange={(e) => this.setState({sku_text: e.target.value})}
            />
          </div>

          <div className="form-group mb-2">
            <label>
              Column List
            </label>

            <textarea
              className="form-control"
              value={this.state.column_text}
              placeholder='Enter columns'
              onChange={(e) => this.setState({column_text: e.target.value})}
            />
          </div>

          {is_loading && ( <div>Loading......</div> )}
          {error && (<div className="alert alert-danger">
            <pre>{error}</pre>
          </div> )}

          <button className="btn btn-success" type="submit" disabled={is_loading}>
            Export Report
          </button>
        </form>
      </div>
    )
  }
}

export default ExportCustomColumnsReport;

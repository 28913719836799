import PropTypes from 'prop-types';
import React from 'react';

import CustomerDAO from './CustomerDAO';
import {GetCommentsWrapper, CommentsList, CommentEditForm} from '../comments/CommentsViews';

class NewCustomerComment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  save_comment = async({text}) => {
    const {customer_id} = this.props;
    await CustomerDAO.create_comment(customer_id, {text})

    const {on_save_comment} = this.props;
    if (on_save_comment) {
      on_save_comment();
    }

    return '';
  }

  render() {
    return (
      <div>
        <h5>New Comment</h5>

        <CommentEditForm save_comment={this.save_comment}/>
      </div>
    )
  }
}

NewCustomerComment.propTypes = {
  customer_id: PropTypes.number.isRequired,
  on_save_comment: PropTypes.func,
}

class CustomerComments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 0,
    };
  }

  render() {
    const {customer_id} = this.props;
    const {key} = this.state;

    return (
      <div>
        <h3>Comments</h3>

        <NewCustomerComment
          customer_id={customer_id}
          on_save_comment={() => this.setState({key: key + 1})}
        />

        <hr />

        <GetCommentsWrapper
          key={key}
          customer_id={customer_id}
          render={({comments}) => (
            <CommentsList comments={comments}/>
          )}
        />
      </div>
    )
  }
}

CustomerComments.propTypes = {
  customer_id: PropTypes.number.isRequired,
}

export {CustomerComments};


import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import {Link} from 'react-router-dom';

import OrderDAO from '../orders/OrderDAO';
import OrderTable from '../orders/OrderTable';
import UserStore from '../auth/UserStore';
import dao from '../dao';
import DescriptionList from '../components/DescriptionList';
import AddressEditable from '../components/AddressEditable';
import CustomerDAO from '../customers/CustomerDAO';
import {ResaleCertificateSection} from './ResaleCertificate';
import TermsAndConditionsDoc from './TermsAndConditionsDoc';
import {SalesOrderTableWrapper} from '../sales_orders/SalesOrderTable';


class CustomerInfoSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    const {customer} = this.props;

    return (
      <div>
        <h3>Company Info</h3>

        <DescriptionList
          className='mb-3'
          description_schemas={[
            {label: 'Name', attribute: 'name'},
            {label: 'Category', attribute: 'category'},
            {label: 'Price Level', attribute: 'price_group'},
            {label: 'Customer Service', attribute: 'customer_service_code'},
            {label: 'Sales Rep', attribute: 'sales_person_code'},
            {label: 'Primary Email', attribute: 'email'},
            {
              label: 'Website',
              render_func: ({website}) => website ? <a href={website} target="_blank" rel="noopener noreferrer">{website}</a> : null
            },
            {label: 'Phone Number', attribute: 'phone_number'},
            {
              label: 'Date Opened',
              render_func: ({created_at}) => moment(created_at).format('MMMM, DD YYYY')
            },
            {label: 'Payment Terms', attribute: 'payment_terms_code'},
          ]}
          data={customer}
        />

        <div className="row">
          <div className="col-12 col-sm-6">
            <AddressEditable
              label="Shipping Address"
              get_address_func={async() => {
                const {shipping_address: address} = await CustomerDAO.get_shipping_address(customer.id)
                return {address};
              }}
              update_address_func={async(data) => {
                const {shipping_address: address} =
                  await CustomerDAO.update_shipping_address(customer.id, data)
                return {address};
              }}
            />
          </div>

          <div className="col-12 col-sm-6">
          </div>
        </div>
      </div>
    )
  }
}

CustomerInfoSection.propTypes = {
  customer: PropTypes.object.isRequired,
}


class UserInfoSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      new_account_application: null,
      is_loading: true,
    }
  }

  componentDidMount = async() => {
    const {new_account_application} = await dao.getNewAccountApplication()
    this.setState({new_account_application, is_loading: false});
  }

  render() {
    const {user, customer} = this.props;
    const {is_loading, new_account_application} = this.state;

    if (!user || is_loading) {
      return <div>Loading your profile...</div>
    }

    const {is_active} = user;

    if (!is_active) {
      if (new_account_application) {
        return (
          <p>
            We have received your account application. You should receive a response from
            us within one business day.
          </p>
        )
      } else {
        return (
          <div>
            <p>
              Once your account is approved you will be able to see pricing, quote freight, and place
              orders on this website.
            </p>

            <p>
              We need more information to get your account set up and approved.
            </p>

            I am a...

            <div className="mb-2">
              <Link to='/requests/new-account' className="btn btn-success">
                New Customer
              </Link>
            </div>
            <div className="mb-2">
              <Link to='/requests/account-approval' className="btn btn-info">
                Existing Customer
              </Link>
            </div>
          </div>
        )
      }
    }

    return (
      <div>
        <h3>Your Profile</h3>

        <DescriptionList
          description_schemas={[
            {attribute: 'first_name', label: 'First Name'},
            {attribute: 'last_name', label: 'Last Name'},
            {attribute: 'email', label: 'Email'},
            {attribute: 'phone_number', label: 'Phone Number'},
          ]}
          data={user}
        />

        {customer && (
          <CustomerInfoSection customer={customer}/>
        )}

        <div className="d-flex justify-content-around mt-3">
          <Link to="/account/request-a-part" className="btn btn-info">
            Request a Part
          </Link>
          <a href='/auth/logout' className="btn btn-danger">Log Out</a>
        </div>
      </div>
    )
  }
}

UserInfoSection.propTypes = {
  user: PropTypes.object,
  customer: PropTypes.object,
}

class ProfileOrderSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: null,
      is_loading: true,
    }
  }

  componentDidMount = async() => {
    const {orders} = await OrderDAO.get_last_orders()
    this.setState({is_loading: false, orders});
  }

  render() {
    const {customer_id} = this.props;
    const {is_loading, orders} = this.state;

    if (is_loading) {
      return <div className="col-12">Loading orders...</div>
    }

    return (
      <div className="row">
        <div className="col-12">
          <h3>euro.style Orders</h3>

          <p>Below are all orders your company has placed on this website.</p>

          <OrderTable orders={orders} show_customer={false}/>
        </div>

        <hr className="col-12"/>

        <div className="col-12">
          <h3>All Sales Orders</h3>

          <p>Below are all of orders we have in our system for your company.</p>

          <SalesOrderTableWrapper customer_id={customer_id}/>
        </div>
      </div>
    )
  }
}

ProfileOrderSection.propTypes = {
  customer_id: PropTypes.number.isRequired,
}

/**
 * Load the new account application from
 * Allow them to create a new account application if they haven't already applied.
 */
class AccountHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getUserState();
  }

  componentDidMount() {
    UserStore.on('change', this.setUserState);
  }

  componentWillUnmount() {
    UserStore.removeListener('change', this.setUserState);
  }

  getUserState = () => {
    return {
      customer: UserStore.get_customer(),
      user: UserStore.get_user(),
    };
  }

  setUserState = () => {
    this.setState(this.getUserState());
  }

  render() {
    const {user, customer} = this.state;

    return (
      <div className="container">
        <div className="row">
          <div className="col-12 mx-auto">
            <h1 className="mb-5">Welcome to <span className="bold">Eurø Style®</span></h1>

            <UserInfoSection user={user} customer={customer}/>


            {customer && (
              <div>
                <hr className="my-5"/>

                <div className="row">
                  <ResaleCertificateSection customer_id={customer.id}/>

                  <TermsAndConditionsDoc user_id={user.id}/>
                </div>
              </div>
            )}

            {user && user.is_active && customer && (
              <div>
                <hr className="my-5"/>

                <ProfileOrderSection customer_id={customer.id}/>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default AccountHome;
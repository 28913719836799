import classNames from 'classnames';
import createReactClass from 'create-react-class';
import React from 'react';
import {Link} from 'react-router-dom';
import _ from 'lodash';

import * as net from '../net';

const TaggerLeaderboard = createReactClass({
  getInitialState() {
    return {users: null}
  },

  async componentDidMount() {
    const users = await net.get({url: '/api/images/leaderboard'});
    this.setState({users});
  },

  render() {
    const {users} = this.state;
    if (!users) {
      return <div>Loading...</div>;
    }

    const sortedUsers = _.sortBy(users, ({images_tagged}) => -1 * images_tagged);

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <h1>Leaderboard</h1>

            <Link to="/internal/tagger">Back to Tagger</Link>
            <hr />

            <table className="table table-striped">
              <thead>
                <tr>
                  <td>Rank</td>
                  <td>Email</td>
                  <td>Images Tagged</td>
                </tr>
              </thead>
              <tbody>
                {sortedUsers.map(({email, images_tagged}, i) => (
                  <tr
                    key={i}
                    className={
                      classNames({
                        success: i === 0,
                        danger: i === this.state.users.length || images_tagged === 0
                      })}
                  >
                    <td>{i + 1}</td>
                    <td>{email}</td>
                    <td>{images_tagged}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
})

export default TaggerLeaderboard;

import React from 'react';

const Price = ({price, num_digits, show_thousands}) => {
  if (price == null) {
    return null;
  }

  let display_price = price;

  if (show_thousands) {
    display_price /= 1000;
  }

  let display = display_price.toLocaleString(
    undefined,
    {
      minimumFractionDigits: num_digits,
      maximumFractionDigits: num_digits,
    });

  if (show_thousands) {
    display += 'k'
  }

  return <span>${display}</span>;
}

Price.defaultProps = {
  num_digits: 2,
  show_thousands: false,
}

export default Price;


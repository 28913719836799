import PropTypes from 'prop-types';
import React from 'react';
import StripeCheckout from 'react-stripe-checkout';

import CustomerDAO from '../customers/CustomerDAO';

class AddCardViaStripeCheckout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  on_token = async(token) => {
    const {customer_id} = this.props;
    await CustomerDAO.create_new_credit_card(customer_id, {token: token.id});

    window.location.reload();
  }

  render() {
    return (
      <div>
        <StripeCheckout
          name="Euro Style"
          description="Add credit card for future purchases."
          token={this.on_token}
          stripeKey={window.STRIPE_PUBLIC_KEY}
          zipCode={true}
          allowRememberMe={false}
        >
          <button className="btn btn-success">
            Add Credit Card
          </button>
        </StripeCheckout>
      </div>
    )
  }
}

AddCardViaStripeCheckout.propTypes = {
  customer_id: PropTypes.number.isRequired,
}

const CardsTable = ({cards, choose_card_func}) => (
  <table className="table table-striped">
    <thead>
      <tr>
        <th>Last 4</th>
        <th>Name</th>
        <th>Expiry</th>
        <th>Brand</th>
      </tr>
    </thead>

    <tbody>
      {cards.map(({id, last4, name, brand, exp_month, exp_year}, idx) => (
        <tr key={idx}>
          <td>{last4}</td>
          <td>{name}</td>
          <td>{exp_month} / {exp_year}</td>
          <td>{brand}</td>
          {choose_card_func && (
            <td>
              <button type="button" className="btn btn-link" onClick={async () => choose_card_func(id)}>
                Choose Card
              </button>
            </td>
          )}
        </tr>
      ))}
    </tbody>
  </table>
);

CardsTable.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.object).isRequired,
  choose_card_func: PropTypes.func,
}

class CustomerCardsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cards: null,
      is_loading: true,
    }
  }

  componentDidMount = async() => {
    const {customer_id} = this.props;
    const {cards} = await CustomerDAO.get_credit_cards(customer_id);
    this.setState({is_loading: false, cards})
  }

  render() {
    const {cards, is_loading} = this.state;

    if (is_loading) {
      return <div className="alert alert-warning">Loading cards</div>;
    }

    return (
      <div>
        <h4>Credit Cards</h4>
        <CardsTable cards={cards}/>
      </div>
    );
  }
}

CustomerCardsTable.propTypes = {
  customer_id: PropTypes.number.isRequired,
}

export {AddCardViaStripeCheckout, CustomerCardsTable, CardsTable};


import PropTypes from 'prop-types';
import moment from 'moment';
import React from 'react';
import {Link} from 'react-router-dom';

import Price from '../components/Price';

class OrderTable extends React.Component {
  render() {
    const {orders, show_customer, show_sent_to_nav_column} = this.props;
    if (!orders) return <div>No orders</div>;

    return (
      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Time Submitted</th>
            <th># Items</th>
            <th>Subtotal</th>
            {show_customer && ([
              <th>Customer</th>,
              <th>Category</th>
            ])}
            <th>Submitted By</th>
            <th>Status</th>
            {show_sent_to_nav_column && (
              <th>Sent to NAV</th>
            )}
          </tr>
        </thead>

        <tbody>
        {orders.map((order) => (
          <tr key={order.id}>
            <td><Link to={`/orders/${order.id}`}>{order.id}</Link></td>
            <td>{moment.utc(order.created_at).local().format("MMM Do YYYY HH:mm")}</td>
            <td>{order.order_items.length}</td>
            <td><Price price={order.subtotal}/></td>
            {show_customer && ([
              <td>
                <Link to={`/internal/customers/${order.customer_id}`}>{order.customer.name}</Link>
              </td>,
              <td>{order.customer.category}</td>
            ])}
            <td>{order.user_who_ordered_email}</td>
            <td>{order.status}</td>
            {show_sent_to_nav_column && (
              <td
                className={order.sent_to_nav_queue_at ? 'table-warning' : 'table-danger'}
              >{JSON.stringify(Boolean(order.sent_to_nav_queue_at))}</td>
            )}
          </tr>
        ))}
        </tbody>
      </table>
    );
  }
}

OrderTable.propTypes = {
  orders: PropTypes.array.isRequired,
  show_customer: PropTypes.bool.isRequired,
  show_sent_to_nav_column: PropTypes.bool.isRequired,
}

OrderTable.defaultProps = {
  show_customer: true,
  show_sent_to_nav_column: false,
}

export default OrderTable;

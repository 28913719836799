import React from 'react';

import {ContentsIoPage} from '../components/ContentsIoPage';

class MAPPolicy extends React.Component {
  render() {
    return (
      <div className="container">
        <ContentsIoPage
          contents_slug={'policy'}
        />
      </div>
    )
  }
}

export default MAPPolicy;
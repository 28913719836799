import PropTypes from 'prop-types';
import React from 'react';

import DescriptionList from '../components/DescriptionList';
import PartsDAO from './PartsDAO';
import AddressDisplay from '../components/AddressDisplay';

class PartRequestView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {part_request} = this.props;
    const {id, shipping_address} = part_request

    return (
      <div>
        <h3>Part Request {id}</h3>

        <DescriptionList
          description_schemas={[{
            label: 'Requester',
            attribute: 'requester_email',
          }, {
            label: 'Customer',
            attribute: 'customer_name',
          }, {
            label: 'Sales Order',
            attribute: 'original_sales_order_number',
          }, {
            label: 'Purchase Order',
            attribute: 'original_purchase_order_number',
          }, {
            label: 'Product SKU',
            attribute: 'product_sku',
          }, {
            label: 'Product Name',
            attribute: 'product_name',
          }, {
            label: 'Reference Number',
            attribute: 'container_reference_number',
          }, {
            label: 'Part Description',
            attribute: 'part_description',
          }]}
          data={part_request}
          render_empty_cells={true}
        />

        <strong>Shipping Address</strong>
        <AddressDisplay {...shipping_address} />
      </div>
    )
  }
}

PartRequestView.propTypes = {
  part_request: PropTypes.object.isRequired,
}

class PartRequestWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: true,
    }
  }

  componentDidMount = async() => {
    const {part_request_id} = this.props;
    const {part_request} = await PartsDAO.get_part_request(part_request_id);

    this.setState({part_request, is_loading: false})
  }

  render() {
    const {render} = this.props;
    const {part_request, is_loading} = this.state;

    if (is_loading) {
      return <div className="alert alert-warning">Loading...</div>;
    }

    return render({part_request});
  }
}

PartRequestWrapper.propTypes = {
  part_request_id: PropTypes.string.isRequired,
  render: PropTypes.func.isRequired,
}

const PartRequestPage = ({match}) => (
  <PartRequestWrapper
    part_request_id={match.params.part_request_id}
    render={({part_request}) => (
      <div className="container">
        <div className="row">
          <div className="col">
            <PartRequestView part_request={part_request}/>
          </div>
        </div>
      </div>
    )}
  />
)

PartRequestPage.propTypes = {
  match: PropTypes.object.isRequired,
}

export {PartRequestPage};

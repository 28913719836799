import React from 'react';

import dao from '../dao';
import CustomerInfoTable from '../reports/CustomerInfoTable';


class BestCustomersForItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      best_customer_infos: null,
      is_loading: true,
    }
  }

  componentDidMount = async() => {
    const {sku} = this.props;
    const {best_customer_infos} = await dao.get_best_customers(sku)
    this.setState({best_customer_infos, is_loading: false});
  }

  render() {
    const {is_loading, best_customer_infos} = this.state;
    if (is_loading) {
      return <div>Loading best customers...</div>;
    }

    return (
      <div>
        <h3>Best Customers</h3>

        <p>
          Showing the top 20 customers for this item in the last 365 days with totals based on all customers.
        </p>

        <CustomerInfoTable
          customer_infos={best_customer_infos}
          num_customers_to_show={20}
        />
      </div>
    )
  }
}

export default BestCustomersForItem;

import React from 'react';

import * as net from '../net';
import {FormInput} from '../requests/FormInput';

const NAME_BY_SKU = 'SKU';
const NAME_BY_UPC = 'UPC';

const DROPBOX_IMAGE_REQUEST_FIELDS = [
  {
    key: 'dropbox_access_token',
    display: 'Dropbox Access Token',
    required: true,
  },
  {
    key: 'dropbox_folder_name',
    display: 'Dropbox Folder Name',
    required: true,
  },
  {
    key: 'naming_system',
    display: 'Naming System',
    type: 'select',
    initial_value: 'Under $500k',
    options: [NAME_BY_SKU, NAME_BY_UPC],
    required: true,
  },
  {
    key: 'sku_text',
    display: 'SKUs',
    type: 'textarea',
    large: true,
    required: true,
  },
]

class ExportImagesToDropboxSection extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      error: null,
      is_loading: false,
      success_message: null,

      dropbox_access_token: '',
      dropbox_folder_name: '',
      sku_text: '',
      naming_system: NAME_BY_SKU,
    }
  }

  send_dropbox_upload_request = async (e) => {
    e.preventDefault();

    this.setState({is_loading: true});

    const {dropbox_access_token, dropbox_folder_name, sku_text, naming_system} = this.state;
    const {message} = await net.post({
      url: '/api/tasks/export-images-to-dropbox-folder',
      data: {
        dropbox_access_token, dropbox_folder_name, sku_text, naming_system,
      }
    });

    this.setState({is_loading: false, success_message: message});
  }

  render () {
    const {error, is_loading, success_message} = this.state;
    return (
      <div>
        <h3>Export Images to Dropbox Folder</h3>

        <p>
          This will export all of the images for a list of SKUs to a Dropbox folder. This is useful when
          sharing images with our eCommerce partners.
        </p>

        <p>
          This may take a long time (I need to figure out how long). Please don't send too many requests at
          once because it could overwhelm the server.
        </p>

        <form onSubmit={this.send_dropbox_upload_request}>
          <div className="row">
            {DROPBOX_IMAGE_REQUEST_FIELDS.map((request_field) => (
              <FormInput
                updateValue={(new_value) => this.setState({[request_field.key]: new_value})}
                value={this.state[request_field.key]}
                fieldKey={request_field.key}
                {...request_field}
              />
            ))}
          </div>

          <button className="btn btn-success" type="submit" disabled={is_loading}>
            Submit Request
          </button>
        </form>

        {is_loading ? (
            <div className="alert alert-danger">Loading...</div>
          ) : (
            <div>
              {success_message && (
                <div className="alert alert-success">{success_message}</div>
              )}

              {error && (
                <div className="alert alert-danger">{error}</div>
              )}
            </div>
          )}
      </div>
    );
  }
}

ExportImagesToDropboxSection.propTypes = {}

export {ExportImagesToDropboxSection}
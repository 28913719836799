import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';

import EdiDAO from './EdiDAO';
import util from '../util';

const EdiFileList = ({edi_files}) => (
  <div>
    <h3>Item Reports</h3>

    <table className="table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Source</th>
          <th>Customer ID</th>
          <th>Control Number</th>
          <th>Test</th>
          <th>Created</th>
        </tr>
      </thead>

      <tbody>
        {edi_files.map(({id, source, customer_id, interchange_control_number, is_test_mode, created_at}) => (
          <tr>
            <td>
              <Link to={`/internal/edi-files/${id}`}>
                {id}
              </Link>
            </td>
            <td>{source}</td>
            <td>{customer_id}</td>
            <td>{interchange_control_number}</td>
            <td>{JSON.stringify(is_test_mode)}</td>
            <td>{util.date_format_iso_to_day(created_at)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)

EdiFileList.propTypes = {
  edi_files: PropTypes.array.isRequired,
}


class EdiFilesWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: true,
      error: null,

      edi_files: null,
    }
  }

  componentDidMount = async() => {
    const {edi_files} = await EdiDAO.get_edi_files();

    this.setState({is_loading: false, edi_files});
  }

  render() {
    const {render} = this.props;
    const {is_loading, error, edi_files} = this.state;

    if (error) {
      return <div className="alert alert-danger">{error}</div>;
    }

    if (is_loading) {
      return <div className="alert alert-warning">Loading...</div>;
    }

    return render({edi_files});
  }
}

EdiFilesWrapper.propTypes = {
  render: PropTypes.func.isRequired,
}


const EdiFileListPage = () => (
  <div className="container">
    <div className="row">
      <div className="col-12 col-md-10 mx-auto">
        <EdiFilesWrapper
          render={({edi_files}) => (
            <EdiFileList edi_files={edi_files}/>
          )}
        />
      </div>
    </div>
  </div>
)

export {EdiFileListPage};

import * as net from '../net';

const PresentationDAO = {};

PresentationDAO.get_presentation =
  (url_key) => net.get({url: `/api/presentations/${url_key}`})

PresentationDAO.get_presentations = () => net.get({url: '/api/presentations'})

PresentationDAO.create_presentation = (data) =>
  net.post({url: '/api/presentations', data})

PresentationDAO.send_presentation_as_email = (url_key, data) =>
  net.post({url: `/api/presentations/${url_key}/send-email`, data})

PresentationDAO.delete_presentation = (id) =>
  net.delete_({url: `/api/presentations/${id}/delete`})

export default PresentationDAO;

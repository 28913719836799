import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import Markdown from 'react-markdown';
import {Link} from 'react-router-dom';
import FontAwesome from 'react-fontawesome';

import UserStore from '../auth/UserStore';
import CommentDAO from './CommentDAO';
import CustomerDAO from '../customers/CustomerDAO';

class GetUserWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getUserState();
  }

  componentDidMount() {
    UserStore.on('change', this.setUserState);
  }

  componentWillUnmount() {
    UserStore.removeListener('change', this.setUserState);
  }

  getUserState = () => {
    return {
      customer: UserStore.get_customer(),
      user: UserStore.get_user(),
    };
  }

  setUserState = () => {
    this.setState(this.getUserState());
  }


  render() {
    const {user, customer} = this.state;
    return this.props.render({user, customer});
  }
}

class SingleComment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit_mode: false,
    };
  }

  save_comment = async({text}) => {
    const {comment} = this.props;
    await CommentDAO.update_comment(comment.id, {text})

    window.location.reload();
  }

  render() {
    const {comment, show_customer_info} = this.props;
    const {text, author_email, created_at, modified_at, customer_name, customer_id} = comment;
    const {edit_mode} = this.state;

    return (
      <GetUserWrapper
        render={({user}) => (
          <div className="card mb-4">
            <div className="card-header d-flex justify-content-between">
              <div>
                <strong>{author_email}</strong> {' '}
                on {moment.utc(created_at).local().format('MMM Do YYYY HH:mm')}

                {show_customer_info && customer_name && (
                  <span> for <Link
                    to={`/internal/customers/${customer_id}/comments`}>{customer_name}</Link></span>
                )}
              </div>

              <div>
                <FontAwesome name="pencil" onClick={() => this.setState({edit_mode: true})}/>
              </div>
            </div>

            <div className="card-body">
              {edit_mode ? (
                  <CommentEditForm
                    text={comment.text}
                    save_comment={this.save_comment}
                  />
                ) : (
                  <Markdown
                    source={text}
                    renderers={{
                      heading: (props) => (
                        React.createElement(`h${props.level}`, props,
                          <span className="markdown-header">{props.children}</span>
                        )
                      )
                    }}
                  />
                )}

              <p className="card-text">
                <small className="text-muted">
                  Last updated {moment.utc(modified_at).local().calendar()}
                </small>
              </p>
            </div>
          </div>
        )}
      />
    );
  }
}

SingleComment.propTypes = {
  comment: PropTypes.object.isRequired,
  show_customer_info: PropTypes.bool,
  update_comment: PropTypes.func,
}

const CommentsList = ({comments, show_customer_info}) => (
  comments.map((comment) => (
    <SingleComment key={comment.id} comment={comment} show_customer_info={show_customer_info}/>
  ))
)

CommentsList.propTypes = {
  comments: PropTypes.arrayOf(PropTypes.object).isRequired,
  show_customer_info: PropTypes.bool,
}


class CommentEditForm extends React.Component {
  constructor(props) {
    super(props);

    const {text} = props;
    this.state = {
      is_loading: false,

      current_text: text || '',
    }
  }

  submit_form = async(e) => {
    e.preventDefault();

    this.setState({is_loading: true});

    const {save_comment} = this.props;
    const {current_text} = this.state;

    const new_text = await save_comment({text: current_text});

    this.setState({is_loading: false, current_text: new_text});
  }

  render() {
    const {current_text, is_loading} = this.state;

    if (is_loading) {
      return <div className="alert alert-warning">Loading...</div>
    }

    return (
      <form onSubmit={this.submit_form}>
        <textarea
          className="form-control mb-2 w-100"
          rows="10"
          onChange={(e) => this.setState({current_text: e.target.value})}
          value={current_text}
        />

        <button className="btn btn-success">Save</button>
      </form>
    )
  }
}

CommentEditForm.propTypes = {
  text: PropTypes.string,
  save_comment: PropTypes.func.isRequired,
}


class GetCommentsWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      is_loading: true,
      error: null,

      comments: null,
    };
  }

  componentDidMount() {
    this.fetch_comments();
  }

  fetch_comments = async() => {
    this.setState({is_loading: true, error: null});

    const {all_comments, comment_id, customer_id} = this.props;

    if (all_comments) {
      const {comments} = await CommentDAO.get_all_comments();

      this.setState({is_loading: false, comments});
    } else if (customer_id) {
      const {comments} = await CustomerDAO.get_comments(customer_id);

      this.setState({is_loading: false, comments});
    } else if (comment_id) {
      const {comment} = await CommentDAO.get_comment(comment_id);

      this.setState({is_loading: false, comments: [comment]});
    } else {
      throw new Error('fuck we do not have a customer or comment id');
    }
  }

  render() {
    const {render} = this.props;
    const {error, is_loading, comments} = this.state;

    if (error) {
      return <div className="alert alert-danger">{error}</div>;
    }

    if (is_loading) {
      return <div className="alert alert-warning">Loading...</div>;
    }

    return render({comments})
  }
}


GetCommentsWrapper.propTypes = {
  customer_id: PropTypes.number,
  comment_id: PropTypes.number,
  render: PropTypes.func.isRequired,
}

export {GetCommentsWrapper, CommentsList, CommentEditForm, GetUserWrapper};

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

class DescriptionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {style, render_empty_cells, description_schemas, data, className, col_className} = this.props;
    return (
      <dl className={classNames('row', className)} style={style}>
        {_.map(description_schemas, ({label, value, attribute, render_func}) => {
          let d_data;

          if (render_func) {
            d_data = render_func(data);
          } else if (attribute) {
            d_data = data[attribute];
          } else if (value) {
            d_data = value;
          }

          if (typeof d_data === 'boolean') {
            d_data = JSON.stringify(d_data);
          }

          if (!render_empty_cells && (d_data == null || d_data === '')) {
            return null;
          }

          return [
            <dt className={classNames(col_className, 'text-right')} key="dt">
              {label}
            </dt>,
            <dd className={col_className} key="dd">{d_data}</dd>
          ]
        })}
      </dl>
    )
  }
}

DescriptionList.propTypes = {
  description_schemas: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
  col_className: PropTypes.string,
  render_empty_cells: PropTypes.bool,
  style: PropTypes.object,
}

DescriptionList.defaultProps = {
  col_className: 'col-6 col-sm-3',
  style: {},
  render_empty_cells: true,
}

export default DescriptionList;


import PropTypes from 'prop-types';
import React from 'react';
import axios from 'axios';

const CONTENTS_BASE_URL = 'https://cdn.contents.io';

const DEFAULT_CONTENTS_TEAM = 'euro-style';
const DEFAULT_CONTENTS_COLLECTION = 'pages';

const get_contents_collection_url = (team = DEFAULT_CONTENTS_TEAM, collection = DEFAULT_CONTENTS_COLLECTION) => (
  `${CONTENTS_BASE_URL}/teams/${team}/collections/${collection}/items`
);

const get_contents_item_url = (team, collection, slug) => (
  `${CONTENTS_BASE_URL}/teams/${team}/collections/${collection}/items/${slug}`
);

class ContentsIoPage extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      is_loading: true,
    };
  }

  componentDidMount = async () => {
    const {contents_team, contents_collection} = this.props;

    const {contents_slug} = this.props;

    const contents_url = get_contents_item_url(contents_team, contents_collection, contents_slug);
    const resp = await axios.get(contents_url);
    const contents_data = resp.data

    this.setState({
      contents_data,
      is_loading: false,
    })
  }

  render () {
    const {is_loading, contents_data} = this.state;

    if (is_loading) {
      return <div className="alert alert-warning">Loading...</div>;
    }

    const {render} = this.props;
    const {title, body} = contents_data.properties;

    return (
      <div className="row">
        <div className="col-xs-12 col-md-12 col-lg-12 mx-auto">
          <div className="blog">
            <h1 className="mt-3">
              {title}
            </h1>

            <div className={this.props.className} dangerouslySetInnerHTML={{__html: body}}/>
          </div>

          {render && render()}
        </div>
      </div>
    )
  }
}

ContentsIoPage.propTypes = {
  contents_team: PropTypes.string,
  contents_collection: PropTypes.string,
  contents_slug: PropTypes.string.isRequired,
  show_contact_form: PropTypes.bool,
  render: PropTypes.func,
  above_title_section: PropTypes.node,
}

ContentsIoPage.defaultProps = {
  contents_team: 'euro-style',
  contents_collection: 'pages',
}

export {ContentsIoPage, get_contents_collection_url};


import React from 'react';
import _ from 'lodash';

import dao from '../dao';

import {FormInput} from './FormInput';

const ACCOUNT_APPROVAL_FORMS = [
  {
    key: 'customer_service_rep',
    display: 'Customer Service Rep',
    large: true,
  },
  {
    key: 'city',
    display: 'City',
  },
  {
    key: 'state',
    display: 'State',
  },
  {
    key: 'notes',
    display: 'Any other notes?',
    type: 'textarea',
    large: true,
  },
]

class NewAccountForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = _.reduce(ACCOUNT_APPROVAL_FORMS, (obj, request_field) => {
      obj[request_field.key] = '';
      return obj;
    }, {is_loading: false});
  }

  send_account_approval_request = async(e) => {
    e.preventDefault();
    this.setState({is_loading: true});

    try {
      await dao.requestAccountApproval(this.state);
      window.location = '/requests/success';
    } catch (e) {
      this.setState({is_loading: false});
    }
  }

  render() {
    const {is_loading} = this.state;
    return (
      <div className="container">
        <div className="row">
          <div className="col-8 col-sm-6 col-md-5 col-lg-4 mx-auto">
            <h2>Request Account Approval</h2>

            <p>
              Once your account is approved you will be able to see pricing, quote freight, and place
              orders on this website.
            </p>

            <form onSubmit={this.send_account_approval_request}>
              <div className="row">
                {ACCOUNT_APPROVAL_FORMS.map((request_field) => (
                  <FormInput
                    updateValue={(new_value) => this.setState({[request_field.key]: new_value})}
                    value={this.state[request_field.key]}
                    fieldKey={request_field.key}
                    {...request_field}
                  />
                ))}
              </div>

              {is_loading && (
                <div className="alert alert-warning">Sending request...</div>
              )}

              <button className="btn btn-success" type="submit" disabled={is_loading}>
                Submit Request
              </button>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default NewAccountForm;


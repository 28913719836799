import * as net from '../net';

const CommentDAO = {};

CommentDAO.get_comment = (comment_id) => net.get({url: `/api/comments/${comment_id}`})

CommentDAO.get_all_comments = (comment_id) => net.get({url: '/api/comments'});

CommentDAO.create_comment = (data) =>
  net.post({url: '/api/comments', data})

CommentDAO.update_comment = (comment_id, data) =>
  net.post({url: `/api/comments/${comment_id}`, data})

export default CommentDAO;

import queryString from 'query-string';
import classNames from 'classnames';
import React from 'react';
import {Link} from 'react-router-dom';
import _ from 'lodash';

import util from '../util';

import dao from '../dao';
import ItemConstants from '../dashboard/ItemConstants';
import ItemGrid from '../product/ItemGrid';


class CategoryHome extends React.Component {
  constructor(props) {
    super(props);
    const {page, query: initial_search_term, group, filter: initial_filter} = queryString.parse(this.props.location.search);

    const initial_page = +page || 1;

    this.state = {
      search_term: initial_search_term || '',
      page: initial_page,
      filter: initial_filter || '',
      items: null,
      product_group: group || ''
    };
  }

  async componentDidMount() {
    if (this.search_input)
      this.search_input.focus();

    const items = await dao.getItems({
      is_new: true,
      status: ItemConstants.STATUS.ACTIVE
    });
    this.setState({items});
    const {group} = queryString.parse(this.props.location.search);
    if (group != null) {
      this.setState({product_group: group});
    }
    this.updateSearchTerm(this.state.search_term, this.state.filter, this.state.page, this.state.product_group);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.history.action === "PUSH" && this.props.location.search !== prevProps.location.search) {
      const {group} = queryString.parse(this.props.location.search);
      this.updateSearchTerm(this.state.search_term, this.state.filter, this.state.page, group);
    }
  }

  update_url({new_filter, new_page = null, new_search_term = null, new_group = null}) {
    if (new_page) {
      this.setState({page: new_page, filter: new_filter});
    }
    if (new_search_term != null) {
      this.setState({search_term: new_search_term});
    }
    if (new_group != null) {
      this.setState({product_group: new_group});
    }

    const {replace} = this.props.history;
    const {search_term, page, product_group} = this.state;

    if (new_group == null) {
      new_group = product_group;
    }
    replace({
      pathname: '/whats-new',
      search: `?group=${new_group}&query=${new_search_term || search_term}&page=${new_page || page}&filter=${encodeURIComponent(new_filter)}`,
    });
  }

  updateSearchTerm (search_term, filter, page = null, group = null) {
    this.update_url({new_search_term: search_term, new_page: page || 1, new_group: group || '', new_filter: filter})

    //track_search_debounced(search_term);

    const {items} = this.state;
    if (!items) {
      return;
    }

    const search_results = items.filter((item) => util.filter_item_description(item, search_term));
    this.setState({search_results});
  }
  
  render() {
    const {search_term, page, product_group} = this.state;
    let search_results = this.state.search_results;
    if (!search_results) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3><span className="bold">New</span> Products</h3>
              <p>
                Each quarter we launch 200 new products. Below is a selection of our most recent
                introductions.
              </p>
            </div>
          </div>
          <p>Loading...</p>
        </div>
      );
    }
    const product_groups = _.sortBy(_.uniq(this.state.items.map(({product_group}) => product_group)));
    if (product_group) {
      search_results = search_results.filter(
        ({product_group: current_product_group}) => current_product_group === product_group)
    }
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3><span className="bold">New</span> Products</h3>
              <p>
                Each quarter we launch 200 new products. Below is a selection of our most recent
                introductions.
              </p>
              <ul className="nav nav-pills justify-content-center">
                {product_groups.map((potential_product_group) => (
                  <li
                    key={potential_product_group}
                    className='nav-item'
                  >
                    <Link
                      className={classNames('nav-link', {active: potential_product_group === product_group})}
                      to={`/whats-new?group=${potential_product_group}&query=${search_term}&page=${page}`}
                    >
                      {potential_product_group}
                    </Link>
                  </li>
                ))}
                <li key='Any' className='nav-item'>
                  <Link
                    className={classNames('nav-link', {active: product_group === ''})}
                    to={`/whats-new?group=&query=${search_term}&page=${page}`}
                  >
                    Any
                  </Link>
                </li>
              </ul>
              <hr />
              <input
                value={search_term}
                onChange={(e) => this.updateSearchTerm(e.target.value, this.state.filter, this.state.page, this.state.product_group)}
                placeholder="Search..."
                className="form-control"
                style={{maxWidth: 200}}
                ref={(input) => {
                  this.search_input = input;
                }}
              />
            </div>
          </div>
        </div>
        <ItemGrid
          update_page={(new_page) => {
            this.update_url({new_page})
          }}
          page={page}
          items={search_results}
          non_filter_items={this.state.items}
          allow_filters={true}
          update_filter={(new_filter) => {
            this.update_url({new_page: page || 1, new_filter})
          }}
          filter={this.state.filter}
        />
      </div>
    );
  }
}

export default CategoryHome;

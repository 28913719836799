import createReactClass from 'create-react-class';
import React from 'react';
import _ from 'lodash';

import ItemPresentation from '../dashboard/ItemPresentation';
import dao from '../dao';

const GeneratePresentationPage = createReactClass({
  getInitialState() {
    return {
      sku_text: '',
      skus_to_present: [],
    };
  },

  async generatePresentation() {
    const parsed_skus = this.state.sku_text.split('\n');

    const items = await dao.getItems({skus: parsed_skus});
    const skus_to_present = _.sortBy(items, (item) => {
      // Sort all that have features and benefits first
      const has_features = !Boolean(item.features_and_benefits);

      // TODO: Then sort by category
      return [has_features, item.description_es];
    }).map(({sku}) => sku);
    this.setState({skus_to_present, sku_text: skus_to_present.join('\n')});

  },

  moveSku(idx, up_or_down) {
    const sku_to_move = this.state.skus_to_present[idx];

    let skus_to_present;

    if (up_or_down === 'UP') {
      const sku_to_move_down = this.state.skus_to_present[idx - 1];
      skus_to_present = this.state.skus_to_present
        .slice(0, idx - 1)
        .concat([sku_to_move, sku_to_move_down])
        .concat(this.state.skus_to_present.slice(idx + 1));
    }
    else if (up_or_down === 'DOWN') {
      const sku_to_move_up = this.state.skus_to_present[idx + 1];
      skus_to_present = this.state.skus_to_present
        .slice(0, idx)
        .concat([sku_to_move_up, sku_to_move])
        .concat(this.state.skus_to_present.slice(idx + 2));
    }
    this.setState({skus_to_present})
  },

  removeSku(sku) {
    const skus_to_present = this.state.skus_to_present.filter((existing_sku) => existing_sku !== sku);
    const sku_text = skus_to_present.join('\n');
    this.setState({sku_text, skus_to_present});
  },

  render() {
    return (
      <div>
        <div className="hidden-print container" style={{marginBottom: 30}}>
          <div className="row">
            <div className="col-md-4 mx-auto">
              <h3>Generate a Presentation</h3>
              <textarea
                onChange={(e) => this.setState({sku_text: e.target.value})}
                placeholder="Enter one sku per line"
                style={{marginBottom: 15}}
                value={this.state.sku_text}
                className="form-control"
              />
              <button className="btn btn-success" onClick={this.generatePresentation}>
                Generate Presentation
              </button>
            </div>
          </div>
        </div>

        {this.state.skus_to_present.map((sku, i) => (
          <ItemPresentation
            key={sku}
            sku={sku}
            removeSku={this.removeSku}
            skuIndex={i}
            moveSku={this.moveSku}
          />
        ))}
      </div>
    );
  }
})

export default GeneratePresentationPage;

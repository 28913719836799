import React from 'react';
import reactUpdate from 'react-addons-update';
import _ from 'lodash';

import dao from '../dao';

import {FormInput} from './FormInput';
import {AddressFields, EMPTY_SHIPPING_ADDRESS} from '../components/AddressFields';

const CONTACT_INFORMATION = [
  {
    key: 'company_name',
    display: 'Company Name',
    required: true,
    large: true,
  },
  {
    key: 'website',
    display: 'Company Website',
  },
  {
    key: 'how_did_you_hear',
    display: 'How did you hear about us?',
    required: true,
    large: true,
  },
  {
    key: 'tell_us_more_about_your_business',
    display: 'Tell us more about your business',
    type: 'textarea',
    required: true,
    large: true,
  },
];

const BUSINESS_INFORMATION = [
  {
    key: 'customer_category',
    display: 'Customer Category',
    type: 'select',
    initial_value: 'Stocking Dealer',
    options: ['Stocking Dealer', 'Designer', 'Contract', 'eCommerce', 'Other'],
    required: true,
  },
  {
    key: 'approx_yearly_revenue',
    display: 'Approx Yearly Revenue',
    type: 'select',
    initial_value: 'Under $500k',
    options: ['Under $500k', '$500k - $1M', '$1 - $2M', '$2M - $5M', '$5M - 10M', '$10M+'],
    required: true,
  },
  {
    key: 'showroom_square_feet',
    display: 'Showroom Square Feet',
    initial_value: 0,
    type: 'number',
    step: 1,
  },
  {
    key: 'warehouse_square_feet',
    display: 'Warehouse Square Feet',
    initial_value: 0,
    type: 'number',
    step: 1,
  },
  {
    key: 'num_years_in_business',
    display: 'Years in Business',
    type: 'number',
    step: 1,
    required: true,
  },
  {
    key: 'num_stores_or_locations',
    display: 'Number of Stores',
    type: 'number',
    step: 1,
    required: true,
  },
  {
    key: 'has_done_business_with_es_before',
    display: 'Have you done business with Euro Style before?',
    type: 'select',
    options: [{value: false, display: 'No'}, {value: true, display: 'Yes'}],
    initial_value: false,
    required: true,
    large: true,
  },
];

const ADDITIONAL_INFORMATION = [
  {
    key: 'federal_ein',
    display: 'Federal EIN',
    required: true,
    large: true,
  },
];
const FILE_INFORMATION = [
  {
    key: 'sellers_permit',
  },
  {
    key: 'file_name',
  },
];

const INPUT_FIELD_TYPES = [
  'tel',
  'number',
  'text',
  'input',
];


class NewAccountForm extends React.Component {
  constructor(props) {
    super(props);
    const state = _.union(CONTACT_INFORMATION, BUSINESS_INFORMATION, ADDITIONAL_INFORMATION, FILE_INFORMATION).reduce((obj, request_field) => {
      let initial_value;
      if (!request_field.type || INPUT_FIELD_TYPES.includes(request_field.type)) {
        initial_value = '';
      } else if (request_field.type === 'select') {
        initial_value = request_field.initial_value;
      }

      obj[request_field.key] = initial_value;
      return obj;
    }, {billing_address: EMPTY_SHIPPING_ADDRESS});
    this.state = state;
  }

  create_new_account_application = async(e) => {
    e.preventDefault();
    let fileContent = '';
    getBase64(this.fileUpload.files[0], async(result) => {
      fileContent = result;
      this.setState({"sellers_permit" : fileContent, "file_name" : this.fileUpload.files[0].name + '|' + this.fileUpload.files[0].type})
      await dao.createNewAccountApplication(this.state);
      window.location = '/requests/success';
    });
  }

  render() {
    const {billing_address} = this.state;

    return (
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-8 mx-auto">
            <h2>Open a <span className="bold">New Account</span></h2>

            <p>
              If you have any questions while filling this out, send us an email
              at <a href="mailto:cs@euro.style">cs@euro.style</a>.
            </p>

            <p>
              Fields with an asterisk (*) are required.
            </p>

            <form onSubmit={this.create_new_account_application}>
              <div className="row">

                <div className="col-12 mt-4">
                  <h4><span className="bold">Contact</span> Information</h4>
                </div>

                {CONTACT_INFORMATION.map((request_field) => (
                  <FormInput
                    updateValue={(new_value) => this.setState({[request_field.key]: new_value})}
                    value={this.state[request_field.key]}
                    fieldKey={request_field.key}
                    {...request_field}
                  />
                ))}

                <div className="col-12 mt-4">
                  <h4><span className="bold">Business</span> Information</h4>
                </div>

                {BUSINESS_INFORMATION.map((request_field) => (
                  <FormInput
                    updateValue={(new_value) => this.setState({[request_field.key]: new_value})}
                    value={this.state[request_field.key]}
                    fieldKey={request_field.key}
                    {...request_field}
                  />
                ))}

                <div className="col-12 mt-4">
                  <h4><span className="bold">Billing</span> Address</h4>

                  <AddressFields
                    update_address={(new_obj) => {
                      const new_billing_address = reactUpdate(billing_address, {$merge: new_obj});
                      this.setState({billing_address: new_billing_address});
                    }}
                    {...billing_address}
                  />
                </div>

                <div className="col-12 mt-4">
                  <h4><span className="bold">Additional</span> Information</h4>
                </div>

                {ADDITIONAL_INFORMATION.map((request_field) => (
                  <FormInput
                    updateValue={(new_value) => this.setState({[request_field.key]: new_value})}
                    value={this.state[request_field.key]}
                    fieldKey={request_field.key}
                    {...request_field}
                  />
                ))}

                <div className="col-12">
                  <div className="form-group">
                    <label className="font-weight-bold">
                      {billing_address.state.toUpperCase() === "CA" ? "Seller's Permit" : "Business License or Seller's Permit"}
                      <b style={{color: "red"}}> *</b>
                    </label>
                    <input className="form-control" type="file" required ref={(ref) => this.fileUpload = ref}></input>
                  </div>
                </div>
              </div>

              <button className="btn btn-success" type="submit">
                Submit Application
              </button>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

const getBase64 = (file, cb) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
      cb(reader.result)
  };
  reader.onerror = function (error) {
      console.log('Error: ', error);
  };
}

export default NewAccountForm;


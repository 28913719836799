import React from 'react';

import dao from '../dao';
import ItemGrid from '../product/ItemGrid';


class OutdoorProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: null,
    };
  }

  async componentDidMount() {
    const items = await dao.getItems({
      is_ok_for_outdoor: true,
    });
    this.setState({items});
  }

  render() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-12 mx-auto col-sm-8">
              <h3>Outdoor Products</h3>

            </div>
          </div>
        </div>

        <hr />

        <ItemGrid items={this.state.items} non_filter_items={this.state.items}/>
      </div>
    );
  }
}

export default OutdoorProducts;

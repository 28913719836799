import queryString from 'query-string';
import React from 'react';

import dao from '../dao';
import ItemGrid from '../product/ItemGrid';



class SelectionPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {selected_items: null};
  }

  async componentDidMount() {
    const query_args = this.props.location.search;
    const raw_skus_text = queryString.parse(query_args).skus;
    const skus = raw_skus_text.split(',')
    const selected_items = await dao.getItems({skus, is_shown_on_public_website: null});
    this.setState({selected_items})
  }

  render() {
    const {selected_items} = this.state;
    if (!selected_items) {
      return <div>Loading your product selection...</div>;
    }

    return (
      <ItemGrid items={selected_items} non_filter_items={selected_items} collapse_color_variations={false}/>
    )
  }
}

export default SelectionPage;

import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

import PaginatedItems from '../components/PaginatedItems'
import Price from '../components/Price'
import Percent from '../components/Percent'

const QuoteCards = ({quotes, order_subtotal, choose_quote_func}) => {
  const ordered_quotes = _.sortBy(quotes, ({price}) => parseFloat(price, 10))

  return (
    <PaginatedItems
      num_items_per_page={8}
      items={ordered_quotes}
      scroll_to_top={false}
      map_item_to_element={(quote) => {
        const price = Math.ceil(quote.price * 1.1);
        // const {price} = quote;

        return (
          <div className="col-6 col-lg-4">
            <div
              className="card mb-3 "
              style={{borderColor: quote.carrier === 'FedEx Freight' ? 'purple' : null}}
            >
              <div className="card-header bg-transparent font-weight-bold">
                {quote.carrier}
              </div>
              <div className="card-body" style={{minHeight: 120}}>
                <div className="mb-1">
                  <Price price={price} num_digits={0}/>
                  {order_subtotal && (
                    <span> | <Percent percent={price / order_subtotal} /></span>
                  )}
                </div>
                <div className="mb-1">{quote.carrier_service}</div>

                <div className="mb-1">{quote.quote_number}</div>
              </div>

              {choose_quote_func && (
                <div className="card-footer bg-transparent">
                  <button className="btn btn-success" onClick={() => choose_quote_func(quote, price)}>
                    Choose Quote
                  </button>
                </div>
              )}
            </div>
          </div>
        )
      }}
    />
  )
}

QuoteCards.propTypes = {
  quotes: PropTypes.arrayOf(PropTypes.object).isRequired,
  choose_quote_func: PropTypes.func,
}

export {QuoteCards};
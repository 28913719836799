import React from 'react';

import dao from '../dao';

const DEFAULT_ERROR = 'Problem generating PDFs...';

class GenerateProductTags extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sku_text: '',
      error: null,
      is_loading: false,
    }
  }

  get_sku_infos = () => {
    const {sku_text} = this.state;
    return sku_text.split('\n').map(
      (line) => {
        if (!line.trim()) {
          return null;
        }
        const parts = line.split(/[\t ]/)
        const sku = parts[0].trim();
        if (parts.length === 1) {
          return {sku};
        }
        const count = parts[1]
        return {sku, count: parseInt(count, 10)};
      }
    ).filter((sku_info) => sku_info);
  }

  generateProductTags = async(e) => {
    e.preventDefault()
    this.setState({is_loading: true, error: null});

    const sku_infos = this.get_sku_infos();

    try {
      const {s3_key} = await dao.generateProductTags({sku_infos});

      const s3_url = `https://s3-us-west-2.amazonaws.com/eurostyle-item-photos/${s3_key}`
      window.open(s3_url)

      this.setState({is_loading: false, error: ''});
    } catch (e) {
      let error = DEFAULT_ERROR;

      const {responseJSON} = e;
      if (responseJSON) {
        const {skus_not_found, error_message} = responseJSON;
        if (skus_not_found) {
          error = ['Skus not found:'].concat(skus_not_found).join('\n')
        } else if (error_message) {
          error = error_message;
        }
      }

      this.setState({is_loading: false, error});
      return await null;
    }
  }

  generate_product_brochure = async(e) => {
    e.preventDefault()
    this.setState({is_loading: true, error: null});

    const {sku_text} = this.state;

    try {
      const {s3_key} = await dao.generate_product_brochure({sku_text});

      const s3_url = `https://s3-us-west-2.amazonaws.com/eurostyle-item-photos/${s3_key}`
      window.open(s3_url)

      this.setState({is_loading: false, error: ''});
    } catch (e) {
      let error = DEFAULT_ERROR;

      const {responseJSON} = e;
      if (responseJSON) {
        const {skus_not_found, error_message} = responseJSON;
        if (skus_not_found) {
          error = ['Skus not found:'].concat(skus_not_found).join('\n')
        } else if (error_message) {
          error = error_message;
        }
      }

      this.setState({is_loading: false, error});
      return await null;
    }
  }

  render() {
    const {error, is_loading} = this.state;

    return (
      <div>
        <h2>Generate Marketing PDFs</h2>

        <strong>Product Tags</strong>

        <p>
          Enter a list of SKUs on each line to generate tags for the products. Each line can be in the form
          of <code>{'<sku> <count>'}</code> where <code>count</code> will determine how many times that
          tag is printed.
        </p>

        <p>
          These should be printed on Avery 5163. <a
          href="https://s3-us-west-2.amazonaws.com/eurostyle-item-photos/product_tags/2018-04-08%2020-52%20Product%20Tags.pdf"
          target="_blank" rel="noopener noreferrer">Open example PDF.</a>
        </p>

        <strong>Product Brochure</strong>

        <p>
          Each line should have the format <code>
            {'<sku_1> <sku_2> <sku_3> <intro_1> <intro_2> <features_and_benefits'}</code>. To easily generate
          this kind of text, keep a spreadsheet with these columns and copy and paste from the spreadsheet.
          When you copy from a spreadsheet and paste into Chrome, tabs will automatically be inserted
          between columns.
        </p>

        <p>
          We often combine multiple colors of a product into one box. So if we have multiple colors, you can
          use <code>sku_2</code> and <code>sku_3</code> to show color options.
        </p>

        <p>
          <a
          href="https://s3-us-west-2.amazonaws.com/eurostyle-item-photos/product_tags/2018-04-08%2021-09%20Product%20Brochure.pdf"
          target="_blank" rel="noopener noreferrer">Open example PDF.</a>
        </p>

        <form onSubmit={this.generateProductTags}>
          <label>
            SKU List
          </label>
          <textarea
            className="form-control"
            value={this.state.sku_text}
            placeholder='Enter skus'
            onChange={(e) => this.setState({sku_text: e.target.value})}
            style={{marginBottom: 20}}
          />

          {is_loading && ( <div>Loading......</div> )}
          {error && (<div className="alert alert-danger">
            <pre>{error}</pre>
          </div> )}

          <div>
            <button className="btn btn-success" type="submit" disabled={is_loading}>
              Generate Product Tags
            </button>
          </div>

          <div className="btn btn-info mt-3" disabled={is_loading} onClick={this.generate_product_brochure}>
            Generate Product Brochure
          </div>
        </form>
      </div>
    )
  }
}

export default GenerateProductTags;

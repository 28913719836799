import React from 'react';

import CustomerDAO from './CustomerDAO';
import {SalesByMonthTable} from '../dashboard/ItemSalesOverTime';

class CustomerSalesByMonth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sales_by_month_this_year: null,
      sales_by_month_previous_year: null,

      is_loading: true,
    }
  }

  componentDidMount = async() => {
    const {customer_id} = this.props;
    const {sales_by_month: sales_by_month_this_year} = await CustomerDAO.get_sales_by_month(customer_id)
    const {sales_by_month: sales_by_month_previous_year} = await
      CustomerDAO.get_sales_by_month(customer_id, {previous_year: true})

    this.setState({sales_by_month_this_year, sales_by_month_previous_year, is_loading: false});
  }

  render() {
    const {is_loading, sales_by_month_previous_year, sales_by_month_this_year} = this.state;

    if (is_loading) {
      return <div>Loading sales over time...</div>;
    }

    return (
      <div>
        <SalesByMonthTable sales_by_month={sales_by_month_this_year}/>
        <SalesByMonthTable sales_by_month={sales_by_month_previous_year} omit_header={true}/>
      </div>
    );
  }
}

export default CustomerSalesByMonth;

import createReactClass from 'create-react-class';
import React from 'react';

import util from '../util';
import dao from '../dao';

const ItemPresentation = createReactClass({
  getInitialState() {
    return {
      item: null,
    }
  },

  async componentDidMount() {
    const {sku} = this.props;

    const item = await dao.getItem(sku);
    this.setState({item});
  },

  render() {
    const {item} = this.state;
    if (!item) {
      return <div>Loading...</div>
    }

    const PAGE_HEIGHT = 612;
    const PAGE_WIDTH = 792;
    const PADDING = 15;

    const CONTAINER_HEIGHT = PAGE_HEIGHT - PADDING * 2;
    const CONTAINER_WIDTH = PAGE_WIDTH - PADDING * 2;
    const HEADER_HEIGHT = 80;

    return (
      <div
        style={{
          width: PAGE_WIDTH,
          height: PAGE_HEIGHT,
          padding: PADDING,
          border: 'solid 1px black',
          position: 'relative',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        {this.props.moveSku && (
          <div
            onClick={() => this.props.moveSku(this.props.skuIndex, 'UP')}
            style={{position: 'absolute', top: 20, left: 20, cursor: 'pointer'}}
            className="hidden-print"
          >
            Up
          </div>
        )}
        {this.props.moveSku && (
          <div
            onClick={() => this.props.moveSku(this.props.skuIndex, 'DOWN')}
            style={{position: 'absolute', top: 20, left: 40, cursor: 'pointer'}}
            className="hidden-print"
          >
            Down
          </div>
        )}
        {this.props.removeSku && (
          <div
            onClick={() => this.props.removeSku(this.props.sku)}
            style={{position: 'absolute', top: 20, right: 20, cursor: 'pointer'}}
            className="hidden-print"
          >
            X
          </div>
        )}
        <div
          style={{
            display: 'flex',
            height: HEADER_HEIGHT,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <h3 style={{width: 500, textAlign: 'center'}}>
            {item.product_description || item.description_es}
            </h3>
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: CONTAINER_HEIGHT - HEADER_HEIGHT,
            width: CONTAINER_WIDTH,
            fontSize: 16,
          }}
        >

          <div style={{width: 400, height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            {item.main_image_s3_key && (
              <img
                src={util.getS3Url(item.main_image_s3_key)}
                style={{maxWidth: 380, maxHeight: 400}}
                alt="Product"
              />
            )}
          </div>

          <div>

            <dl className="dl-horizontal">
              <dt>SKU</dt>
              <dd>{item.sku}</dd>

              <dt>MSRP</dt>
              <dd>${item.price_msrp}</dd>

              <dt>Wholesale</dt>
              <dd>${item.price_wholesale}</dd>
            </dl>

            {item.features_and_benefits && (
              <div style={{marginTop: 10}}>
                <h4>Features</h4>
                <div>
                  <ul>
                    {item.features_and_benefits.split('\n').map((feature, i) => (
                      <li key={`feature-${i}`}>{feature}</li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
});

export default ItemPresentation;

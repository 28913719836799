import React from 'react';
import BaseSelect from 'react-select';
import {Link}  from 'react-router-dom';

import UserStore from './UserStore';
import FixRequiredSelect from "./FixRequiredSelect";
import auth_dao from './auth_dao';
import dao from '../dao';

const Select = props => (
  <FixRequiredSelect
    {...props}
    SelectComponent={BaseSelect}
    options={props.options}
  />
);

class SignupPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: '',
      last_name: '',
      company_name: '',
      company_title: '',
      email: '',
      password: '',
      phone_number: '',
      link: '',
      resale_number: '',
      receive_emails: true,

      error_message: null,
      is_loading: true,
    }
  }

  submitSignup = async(e) => {
    e.preventDefault();
    this.setState({is_loading: true, error_message: null});

    const {email, first_name, last_name, company_name, company_title, password, phone_number, receive_emails,
      annual_revenue, link, resale_number, sell_online, groups} = this.state;
    try {
      const {user} =
        await auth_dao.trySignup({
          email,
          first_name,
          last_name,
          company_name,
          company_title,
          password,
          phone_number,
          receive_emails,
          annual_revenue,
          link,
          resale_number,
          sell_online,
          groups
        });
      if (user) {
        UserStore.set_user(user);
        this.props.history.push('/account');
      }
    } catch (e) {
      const {error_message} = e.responseJSON;
      this.setState({is_loading: false, error_message});
    }
  }

  toggleChange = () => {
    this.setState({
      receive_emails: !this.state.receive_emails,
    });
  }

  componentDidMount = async () => {
    const setup = await dao.getSiteSetup();
    var values = [];
    if (setup.customer_groups) {
      setup.customer_groups.split(';').forEach(element => {
        values.push({value: element, label: element });
      });
    }
    this.setState({is_loading: false, values});
  }

  setGroups = (e) => {
    var list = "";
    if (e) {
      e.forEach(element => list += element.value + ';');
    }
    this.setState({groups: list});
  }

  render() {
    const {email, password, first_name, last_name, company_title, company_name, error_message, phone_number,
      receive_emails, link, resale_number, values} = this.state;

    return (
      <div className="container">
        <div className="col-12 col-sm-8 col-md-6 mx-auto">
          <h3>Dealer Sign Up</h3>

          <p>
            Thank you for your interest in Eurø Style. We sell to trade only, including retail stores, interior designers, hospitality, and select eCommerce sites.
          </p>

          <p>
            If you are already a customer but do not have website logins, please create a new login.
          </p>

          <p>
            <Link to="/request-new-password">Forgot Password</Link>
          </p>

          {error_message && <div className="alert alert-danger" role="alert"> {error_message} </div> }

          <form onSubmit={this.submitSignup} className="mb-5">
            <div className="form-group">
              <label>Kind of business<b style={{color: "red"}}> *</b></label>
              <Select
                autoFocus
                closeMenuOnSelect={false}
                isMulti
                options={values}
                onChange={this.setGroups}
                required
              />
            </div>

            <div className="form-group">
              <label>Email<b style={{color: "red"}}> *</b></label>
              <input
                name="email"
                type="email"
                className="form-control"
                value={email}
                onChange={(e) => this.setState({email: e.target.value})}
                required
              />
            </div>

            <div className="form-group">
              <label>Password<b style={{color: "red"}}> *</b></label>
              <input
                name="password"
                type="password"
                className="form-control"
                value={password}
                onChange={(e) => this.setState({password: e.target.value})}
                required
              />
            </div>

            <div className="form-group">
              <label>First Name<b style={{color: "red"}}> *</b></label>
              <input
                name="givenName"
                type="text"
                className="form-control"
                value={first_name}
                onChange={(e) => this.setState({first_name: e.target.value})}
                required
              />
            </div>

            <div className="form-group">
              <label>Last Name<b style={{color: "red"}}> *</b></label>
              <input
                name="surname"
                type="text"
                className="form-control"
                value={last_name}
                onChange={(e) => this.setState({last_name: e.target.value})}
                required
              />
            </div>

            <div className="form-group">
              <label>Phone Number<b style={{color: "red"}}> *</b></label>
              <input
                name="phone-number"
                type="tel"
                className="form-control"
                value={phone_number}
                onChange={(e) => this.setState({phone_number: e.target.value})}
                required
              />
            </div>

            <div className="form-group">
              <label>Company<b style={{color: "red"}}> *</b></label>
              <input
                name="company"
                type="text"
                className="form-control"
                value={company_name}
                onChange={(e) => this.setState({company_name: e.target.value})}
                required
              />
            </div>

            <div className="form-group">
              <label>Title</label>
              <input
                name="title"
                type="text"
                className="form-control"
                value={company_title}
                onChange={(e) => this.setState({company_title: e.target.value})}
              />
            </div>

            <div className="form-group">
              <label>Resale Number<b style={{color: "red"}}> *</b></label>
              <input
                name="resale_number"
                type="text"
                className="form-control"
                value={resale_number}
                onChange={(e) => this.setState({resale_number: e.target.value})}
                required
              />
            </div>

            <div className="form-group">
              <label>Annual Revenue<b style={{color: "red"}}> *</b></label>
              <Select
                closeMenuOnSelect={true}
                options={[
                  { value: 1, label: "Under $500K" },
                  { value: 2, label: "$1M - $5M" },
                  { value: 3, label: "$5M - $10M" },
                  { value: 4, label: "$10M - $20M" },
                  { value: 5, label: "$20M +" },
                ]}
                onChange={(e) => this.setState({annual_revenue: e.label})}
                required
              />
            </div>

            <div className="form-group">
              <label>Website or social media link</label>
              <input
                name="link"
                type="text"
                className="form-control"
                value={link}
                onChange={(e) => this.setState({link: e.target.value})}
              />
            </div>

            <div className="form-group">
              <label>Do you sell online?<b style={{color: "red"}}> *</b></label>
              <Select
                closeMenuOnSelect={true}
                options={[
                  { value: 1, label: "No" },
                  { value: 2, label: "Yes" },
                ]}
                onChange={(e) => this.setState({sell_online: e.label})}
                required
              />
            </div>

            <div class="form-group">
              <label>
                <input type="checkbox"
                  name="receive_emails"
                  checked={receive_emails}
                  onChange={this.toggleChange}
                /> Uncheck this box if you do not wish to receive our marketing emails.
              </label>
            </div>

            <button className="btn btn-success">Sign Up</button>
          </form>

          <div>
            <Link to="/login">I already have an account</Link>
          </div>
        </div>
      </div>
    )
  }
}

export default SignupPage;
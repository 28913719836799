import React from 'react';

import ContentDAO from './ContentDAO.js';
import SingleBlogPost from './SingleBlogPost.js';

import './blog.css';

class BlogPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      blog_posts: null,
    }
  }

  componentDidMount = async() => {
    const blog_posts = await ContentDAO.getBlogPosts()
    this.setState({blog_posts});
  }

  render() {
    const {blog_posts} = this.state
    return (
      <div className="container blog">
        <div className='row'>
          <div className='col-12 col-md-10 mx-auto'>
            <h1 className='mb-5'>Our <span className="bold">Newsletter</span></h1>

            {blog_posts === null && <div>Loading...</div>}

            {blog_posts && blog_posts.map((blog_post, index) => {
              return <SingleBlogPost blog_post={blog_post} key={index} just_preview={true}/>
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default BlogPage;

import React from 'react';
import queryString from 'query-string';

import auth_dao from './auth_dao';
import UserStore from './UserStore';

class SetPasswordPage extends React.Component {
  constructor(props) {
    const query_string = props.location.search;
    const {token, email} = queryString.parse(query_string);

    super(props);
    this.state = {
      email,
      password: '',
      token,

      error_message: null,
      is_loading: true,
    }
  }

  setPassword = async(e) => {
    e.preventDefault();
    this.setState({is_loading: true, error_message: null});

    const {email, password, token} = this.state;
    try {
      const user = await auth_dao.setPassword({email, password, token})
      if (user) {
        this.props.history.push('/');
        UserStore.set_user(user);
      }
    } catch (e) {
      const {error_message} = e.responseJSON;
      this.setState({is_loading: false, error_message});
    }
  }

  render() {
    const {email, password, error_message} = this.state;

    return (
      <div className="container">
        <div className="col-12 col-sm-8 col-md-6 mx-auto">
          <h3>Sign Up</h3>

          {error_message && <div className="alert alert-danger" role="alert"> {error_message} </div> }

          <form onSubmit={this.setPassword}>
            <div className="form-group">
              <label>Email</label>
              <input name="email" type="email" className="form-control" value={email} readOnly />
            </div>

            <div className="form-group">
              <label>Password</label>
              <input
                name="password"
                type="password"
                className="form-control"
                value={password}
                onChange={(e) => this.setState({password: e.target.value})}
                required
              />
            </div>

            <button className="btn btn-success">Set Password</button>
          </form>
        </div>
      </div>
    )
  }
}

export default SetPasswordPage;
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import React from 'react';
import _ from 'lodash';

import util from '../util';
import ItemStore from '../product/ItemStore';
import UserStore from '../auth/UserStore';

const TdMiddleAlign = ({right, center, children, className}) => (
  <td className={classNames('align-middle', className, {'text-right': right, 'text-center': center})}>
    {children}
  </td>
)

TdMiddleAlign.defaultProps = {
  right: false,
  center: false,
}

const OrderItemsRowColumns = ({item, columns, get_row_context, user}) => (
  <tr>
    {columns.map((column, idx) => (
      <OrderItemsRowColumn
        key={idx}
        item={item}
        column={column}
        row_context={get_row_context && get_row_context(item)}
        user={user}
      />
    ))}
  </tr>
);

OrderItemsRowColumns.propTypes = {
  item: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
}

const OrderItemsRowColumn = ({item, column, row_context, user}) => {
  const {render, right_align, center_align, to_value} = column;
  const value = to_value && to_value(item, row_context);

  return (
    <TdMiddleAlign right={right_align} center={center_align}>
      {render(item, value, user)}
    </TdMiddleAlign>
  )
}

OrderItemsRowColumn.propTypes = {
  item: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
}


const ThTextCenter = ({children}) => <th className="text-center">{children}</th>;

const OrderItemsHeader = ({columns}) => (
  <thead>
    <tr>
      {columns.map(({header_text}, idx) => (
        <ThTextCenter key={idx}>{header_text || ''}</ThTextCenter>
      ))}
    </tr>
  </thead>
)

OrderItemsHeader.propTypes = {
  columns: PropTypes.array.isRequired,
}

const get_subtotal = (items, column, get_row_context) => (
  _.chain(items)
    .map((item) => {
      const row_context = get_row_context(item);
      return column.to_value(item, row_context);
    })
    .reduce((a, b) => a + b, 0)
    .value()
)


const SubtotalRow = ({columns, items, subtotal_keys, get_row_context}) => (
  <tr>
    {columns.map((column, idx) => {
      if (!subtotal_keys.includes(column.key)) {
        return <td key={idx}/>;
      }

      const subtotal_value = get_subtotal(items, column, get_row_context)

      return (
        <TdMiddleAlign
          key={idx}
          className='table-info font-weight-bold'
          right={column.right_align}
          center={column.center_align}
        >
          {column.render(null, subtotal_value, null)}
        </TdMiddleAlign>
      )
    })}
  </tr>
)

SubtotalRow.propTypes = {
  columns: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  subtotal_keys: PropTypes.array.isRequired,
  get_row_context: PropTypes.func.isRequired,
}

const ITEM_IMAGE_COLUMN = {
  key: 'image',
  render: (item) => (
    <img
      src={util.getS3Url(item.main_image_s3_key + '-thumb')}
      style={{
        maxWidth: 80
      }}
      alt='Missing info'/>
  ),
};

const ITEM_SKU_COLUMN = {
  key: 'sku',
  header_text: 'SKU',
  render: (item) => (
    <Link to={`/products/${item.sku}`}>
      {item.sku}
    </Link>
  ),
};

const ITEM_NAME_COLUMN = {
  key: 'sku',
  header_text: 'Product',
  render: (item, value, user) => (
    user && user.is_obs_customer && item.obs_item ? (
        <span>{item.product_description || item.description_es} <br/><span style={{color: "red"}}>[Truckload Product]</span></span>
      ) : (
        item.product_description || item.description_es
      )
  ),
};

const ITEM_VOLUME_COLUMN = {
  key: 'volume',
  header_text: 'Volume',
  right_align: true,
  show_subtotal: true,
  to_value: (item, row_context) => item.carton_volume_per_piece * row_context.quantity,
  render: (item, value) => (
    <span>
      {value.toLocaleString(undefined, {minimumFractionDigits: 1})} ft<sup>3</sup>
    </span>
  ),
};

const ITEM_UNIT_VOLUME = {
  key: 'volume',
  header_text: 'Unit Volume',
  right_align: true,
  show_subtotal: true,
  to_value: (item, row_context) => item.carton_volume_per_piece,
  render: (item, value) => (
    value ? (
        <span>
            {value.toLocaleString(undefined, {minimumFractionDigits: 1})} ft<sup>3</sup>
          </span>
      ) : (
        <span>Missing data</span>
      )
  ),
};

const ITEM_WEIGHT_COLUMN = {
  key: 'weight',
  header_text: 'Weight',
  right_align: true,
  show_subtotal: true,
  to_value: (item, row_context) => item.carton_weight_per_piece * row_context.quantity,
  render: (item, value) => (
    <span>
      {value.toLocaleString(undefined, {minimumFractionDigits: 1})} lbs
    </span>
  ),
}

const ITEM_CARTON_COLUMN = {
  key: 'cartons',
  header_text: 'Cartons',
  right_align: true,
  show_subtotal: true,
  to_value: (item, row_context) => row_context.quantity / item.items_per_carton,
  render: (item, value) => (
    <span>{value} ctns</span>
  ),
};

const DEFAULT_COLUMNS = [
  ITEM_IMAGE_COLUMN,
  ITEM_SKU_COLUMN,
  ITEM_NAME_COLUMN,
];


const OrderItemsTable = ({
  show_subtotal_row, skus, extra_columns, get_row_context
}) => {
  const columns = DEFAULT_COLUMNS.concat(extra_columns);
  const subtotal_keys = columns.filter(({show_subtotal}) => show_subtotal).map(({key}) => key);
  const items = skus.map((sku) => ItemStore.get_item(sku));
  const user = UserStore.get_user();

  return (
    <div className='table-responsive mb-4'>
      <table className='table'>
        <OrderItemsHeader columns={columns}/>

        <tbody>
          {_.map(skus, (sku, idx) => {
            return (
              <OrderItemsRowColumns
                key={idx}
                item={ItemStore.get_item(sku)}
                columns={columns}
                get_row_context={get_row_context}
                user={user}
              />
            )
          })}
        </tbody>

        <tfoot>
          {show_subtotal_row && (
            <SubtotalRow
              columns={columns}
              items={items}
              subtotal_keys={subtotal_keys}
              get_row_context={get_row_context}
            />
          )}
        </tfoot>
      </table>
    </div>
  );
}


OrderItemsTable.propTypes = {
  skus: PropTypes.array.isRequired,
  extra_columns: PropTypes.array.isRequired,
  get_row_context: PropTypes.func,
  show_subtotal_row: PropTypes.bool,
}

OrderItemsTable.defaultProps = {
  show_subtotal_row: true,
  extra_columns: [],
};

export {
  OrderItemsTable,
  ITEM_WEIGHT_COLUMN,
  ITEM_VOLUME_COLUMN,
  ITEM_CARTON_COLUMN,
  ITEM_UNIT_VOLUME,
};

import React from 'react';
import {Link} from 'react-router-dom';

import DescriptionList from '../components/DescriptionList';
import ComponentsDimensions from './ComponentsDimensions';
import ItemUtil from './ItemUtil';

const PRODUCT_DIMENSION_ATTRIBUTE_TO_LABEL = [
  {
    render_func: ({product_height, product_height_min}) =>
      ItemUtil.num_to_display(product_height, product_height_min, 1, '"'),
    label: 'Height',
  },
  {
    render_func: ({seat_height_max, seat_height_min}) =>
      ItemUtil.num_to_display(seat_height_max, seat_height_min, 1, '"'),
    label: 'Seat Height',
  },
  {
    render_func: ({product_depth, product_depth_min}) =>
      ItemUtil.num_to_display(product_depth, product_depth_min, 1, '"'),
    label: 'Depth / Length',
  },
  {
    render_func: ({product_width, product_width_min}) =>
      ItemUtil.num_to_display(product_width, product_width_min, 1, '"'),
    label: 'Width',
  },
  {
    attribute: 'additional_dimensions',
    label: 'Additional Dimensions',
  },
]

const ProductDimensions = ({item}) => (
  <div className="col-12 col-sm-6 col-md-4">
    <h4 className="text-center">Product Dimensions</h4>

    <DescriptionList
      style={{fontSize: '0.85rem'}}
      col_className='col-6'
      render_empty_cells={false}
      description_schemas={PRODUCT_DIMENSION_ATTRIBUTE_TO_LABEL}
      data={item}
    />

    {item.item_type === 'KIT' && <ComponentsDimensions sku={item.sku} />}
  </div>
);

const PRODUCT_MATERIAL_ATTRIBUTE_TO_LABEL = [
  {
    attribute: 'color_or_finish',
    label: 'Color / Finish',
  },
  {
    attribute: 'casters_info',
    label: 'Casters',
  },
  {
    attribute: 'shape',
    label: 'Shape',
  },
  {
    attribute: 'material_seat_back',
    label: 'Seat Back Material',
  },
  {
    attribute: 'material_top',
    label: 'Top Material',
  },
  {
    attribute: 'material_base_legs_frame',
    label: 'Base Material',
  },
];

const ProductMaterials = ({item}) => (
  <div className="col-12 col-sm-6 col-md-4">
    <h4 className="text-center">Product Materials</h4>

    <DescriptionList
      style={{fontSize: '0.85rem'}}
      col_className='col-6'
      render_empty_cells={false}
      description_schemas={PRODUCT_MATERIAL_ATTRIBUTE_TO_LABEL}
      data={item}
    />
  </div>
)

const SHIPPING_INFO_ATTRIBUTE_TO_LABEL = [
  {
    attribute: 'assembly_type',
    label: 'Assembly',
  },
  {
    attribute: 'ship_method',
    label: 'Ships via',
  },
  {
    label: 'Carton Dims',
    render_func: (item) => {
      const {carton_length, carton_width, carton_height} = item;
      return ItemUtil.to_dims_string(carton_length, carton_height, carton_width);
    },
  },
  {
    label: 'Carton Weight',
    render_func: (item) => <span>{ItemUtil.round_dim(item.carton_weight)} lbs.</span>,
  },
  {
    label: 'Carton Volume',
    render_func: (item) => <span>{ItemUtil.round_dim(item.carton_volume)} ft<sup>3</sup></span>,
  },
  // {
  //   label: 'Carton Density',
  //   render_func: (item) =>
  //     <span>{round_dim(item.carton_weight / item.carton_volume)} lbs/ft<sup>3</sup></span>,
  // },
  // {
  //   label: 'Pallet Dims',
  //   render_func: (item) => {
  //     if (!item.pallet_size) return null;
  //     const {pallet_length, pallet_width, pallet_height} = item;
  //     return to_dims_string(pallet_length, pallet_height, pallet_width);
  //   },
  // },
  // {
  //   label: 'Pallet Weight',
  //   render_func: (item) => {
  //     if (!item.pallet_size) return null;
  //     return <span>{round_dim(item.pallet_weight)} lbs.</span>;
  //   },
  // },
  // {
  //   attribute: 'moq',
  //   label: 'MOQ (Special Orders)',
  // },
  {
    attribute: 'country_of_origin',
    label: 'Country of Origin',
  },
  {
    label: 'Consolidation Code',
    render_func: (item) => <Link to={`/factory/${item.vendor_code}`}>{item.vendor_code}</Link>,
  }
];

const ShippingInfo = ({item}) => (
  <div className="col-12 col-sm-6 col-md-4">
    <h4 className="text-center">Shipping Info</h4>

    <DescriptionList
      style={{fontSize: '0.85rem'}}
      col_className='col-6'
      render_empty_cells={false}
      description_schemas={SHIPPING_INFO_ATTRIBUTE_TO_LABEL}
      data={item}
    />
  </div>
)

const ProductStatsSection = ({item}) => (
  <div className="row">
    <ProductDimensions item={item}/>
    <ProductMaterials item={item}/>
    <ShippingInfo item={item}/>
  </div>
)

export default ProductStatsSection;

import classNames from 'classnames';
import createReactClass from 'create-react-class';
import moment from 'moment';
import React from 'react';
import {Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import ReactTooltip from 'react-tooltip'
import FontAwesome from 'react-fontawesome';

import ItemPreviewSquare from './ItemPreviewSquare';
import ItemStore from './ItemStore';
import ItemUtil from './ItemUtil';
import dao from '../dao';
import util from '../util';
import FeaturesAndBenefits from '../components/FeaturesAndBenefits';
import UserStore from '../auth/UserStore';
import {AddToCartButton} from '../cart/AddToCartButton';

import ComponentsSection from './ComponentsSection';
import ProductImageGallery from './ProductImageGallery';
import ProductStatsSection from './ProductStatsSection';

const dasherize = (string) => string.replace(/[\s/-]+/g, '-');

const PRICING_COLUMN_LEFT_CLASS = 'col-4';
const PRICING_COLUMN_RIGHT_CLASS = 'col-7';

const ProductPricingAndQuantitySection = ({item, user, customer}) => {
  if (!user) {
    return (
      <div className="mb-2">
        <Link to="/signup">Sign up</Link> or <Link to="/login">log in</Link> to view pricing and quantity.
      </div>
    );
  }

  if (!user.is_active) {
    return (
      <div>Pricing and quantity will be shown once your account is approved.</div>
    );
  }

  const comes_in_a_set = item.items_per_carton > 1;

  let customer_price;
  let customer_price_container;
  let customer_price_orig;
  let customer_price_container_orig;

  if (customer) {
    const price_level = util.PRICE_GROUP_TO_PRICE_LEVEL[customer.price_group];
    customer_price = item[price_level];
    customer_price_orig = item[util.ORIG_PRICE_GROUP_TO_PRICE_LEVEL[customer.price_group]];

    const {price_new_ecommerce_wholesale} = item;
    if (price_new_ecommerce_wholesale && price_new_ecommerce_wholesale < customer_price) {
      customer_price = price_new_ecommerce_wholesale;
    }

    const {orig_price_new_ecommerce_wholesale} = item;
    if (orig_price_new_ecommerce_wholesale && orig_price_new_ecommerce_wholesale < customer_price_orig) {
      customer_price_orig = orig_price_new_ecommerce_wholesale;
    }

    const {price_group_container} = customer;

    if (price_group_container) {
      const price_level_container = util.PRICE_GROUP_TO_PRICE_LEVEL[price_group_container];
      customer_price_container = item[price_level_container];
      customer_price_container_orig = item[util.ORIG_PRICE_GROUP_TO_PRICE_LEVEL[price_group_container]];
    }
  }

  let current_price_level;
  if (user) {
    current_price_level = user.current_price_level;
  }

  const {price_tradeshow_special} = item;

  let price_map = item.price_map;
  const {price_new_ecommerce_map} = item;
  if (price_new_ecommerce_map && price_new_ecommerce_map < price_map) {
    price_map = price_new_ecommerce_map;
  }

  return (
    <div>
      {user.is_obs_customer && item.obs_item && (
        <div className="mb-2"><span style={{color: "red"}}>[Truckload Product]</span></div>
      )}

      <div className="mb-2">
        {item.is_stackable && (
          <img src="/static/stackable.png" title="Stackable" className="border rounded mb-0" style={{position: "absolute", marginLeft: 450}} alt="stackable"></img>
          //<img src="/stackable.png" title="Stackable" style={{position: "absolute", marginLeft: 220, marginTop: -12, width: 54}}></img>
        )}
        {!item.closeout && (
          <div>Quantity Available: <strong>{item.quantity_available}</strong></div>
        )}
        {item.closeout && (
          <div style={{color: "red"}}>Last Chance Final Quantity Available: <strong>{item.quantity_available}</strong></div>
        )}
        {item.quantity_on_water > 0 && (
          <div>Quantity Coming Soon: <strong>{item.quantity_on_water}</strong></div>
        )}
        {item.quantity_on_purchase_order > 0 && (
          <div>Quantity in Production: <strong>{item.quantity_on_purchase_order}</strong></div>
        )}
        {item.next_receipt_date && (
          <div>More arriving in {formatArrivalDate(moment(item.next_receipt_date))}.</div>
        )}
      </div>

      <dl className="row mb-2">
        <dt className={PRICING_COLUMN_LEFT_CLASS}>
          MSRP <FontAwesome name="info-circle"
                            data-tip="Manufacturer's Suggested Retail Price or List Price"/>
          <ReactTooltip />
        </dt>
        {showMSRP({item, comes_in_a_set})}

        <dt className={PRICING_COLUMN_LEFT_CLASS}>
          MAP <FontAwesome name="info-circle" data-tip="Minimum Advertised Price"/><ReactTooltip />
        </dt>
        {showPriceMap({item, price_map})}

        {customer && (
          [
            <dt className={PRICING_COLUMN_LEFT_CLASS} key="1">
              My Cost <FontAwesome name="info-circle" data-tip="FOB California"/><ReactTooltip />
            </dt>,
            showCustomerPrice({item, customer_price, customer_price_orig})
          ]
        )}

        {customer_price_container && (
          [
            <dt className={PRICING_COLUMN_LEFT_CLASS} style={{color: '#007bff'}}>
              My Container Cost <FontAwesome name="info-circle" data-tip="FOB Factory"/><ReactTooltip />
            </dt>,
            showCustomerPriceContainer({item, customer_price_container, customer_price_container_orig})
          ]
        )}

        {current_price_level && (
          [
            <dt className={PRICING_COLUMN_LEFT_CLASS} style={{color: 'gold'}}>Override</dt>,
            <dd className={PRICING_COLUMN_RIGHT_CLASS}>
              ${item[current_price_level] * item.items_per_carton}
            </dd>
          ]
        )}

        {price_tradeshow_special && (
          [
            <dt className={classNames(PRICING_COLUMN_LEFT_CLASS, "text-danger")}>HP Special</dt>,
            <dd className={PRICING_COLUMN_RIGHT_CLASS}>
              ${price_tradeshow_special * item.items_per_carton}
            </dd>
          ]
        )}
      </dl>
    </div>
  )
}

function showMSRP({ item, comes_in_a_set }) {
  if (item.orig_price_msrp && item.orig_price_msrp > 0 && item.orig_price_msrp > item.price_msrp) {
    return  <dd className={PRICING_COLUMN_RIGHT_CLASS}>
              <strike style={{ color: 'grey' }}>${comes_in_a_set ? item.orig_price_msrp_per_carton : item.orig_price_msrp}</strike>
              <span style={{ color: 'red', paddingLeft: 10 }}>$
              {comes_in_a_set ? item.price_msrp_per_carton : item.price_msrp}</span>
            </dd>;
  }
  return   <dd className={PRICING_COLUMN_RIGHT_CLASS}>$
            {comes_in_a_set ? item.price_msrp_per_carton: item.price_msrp}
          </dd>;
}

function showPriceMap({ item, price_map }) {
  if (item.orig_price_map_from_nav && item.orig_price_map_from_nav > 0 && item.orig_price_map_from_nav > item.price_map) {    
    return  <dd className={PRICING_COLUMN_RIGHT_CLASS}>
              <strike style={{ color: 'grey' }}>${item.orig_price_map_from_nav * item.items_per_carton}</strike>
              <span style={{ color: 'red', paddingLeft: 10 }}>${price_map * item.items_per_carton}</span>
            </dd>;
  }
  return  <dd className={PRICING_COLUMN_RIGHT_CLASS}>
            ${price_map * item.items_per_carton}
          </dd>;
}

function showCustomerPrice({ item, customer_price, customer_price_orig }) {
  if (customer_price_orig && customer_price_orig > 0 && customer_price_orig > customer_price) {
    return  <dd className={PRICING_COLUMN_RIGHT_CLASS} key="2">
              <strike style={{ color: 'grey' }}>${customer_price_orig * item.items_per_carton}</strike>
              <span style={{ color: 'red', paddingLeft: 10 }}>${customer_price * item.items_per_carton}</span>
          </dd>;
  }
  return  <dd className={PRICING_COLUMN_RIGHT_CLASS} key="2">
            ${customer_price * item.items_per_carton}
          </dd>;
}

function showCustomerPriceContainer({ item, customer_price_container, customer_price_container_orig }) {
  if (customer_price_container_orig && customer_price_container_orig > 0 && customer_price_container_orig > customer_price_container) {
    return  <dd className={PRICING_COLUMN_RIGHT_CLASS}>
              <strike style={{ color: 'grey' }}>${customer_price_container_orig * item.items_per_carton}</strike>
              <span style={{ color: 'red', paddingLeft: 10 }}>${customer_price_container * item.items_per_carton}</span>
            </dd>;
  }
  return  <dd className={PRICING_COLUMN_RIGHT_CLASS}>
            ${customer_price_container * item.items_per_carton}
          </dd>;
}

function formatArrivalDate(data) {
    if (data.date() > 10)
        return 'early ' + data.format('MMMM');
    return 'end of ' + data.format('MMMM');
}

const CollectionItems = createReactClass({
  getInitialState() {
    return {collection: null, collection_items: null};
  },

  async componentDidMount() {
    const collection = await dao.getCollection(this.props.collection_id)
    const collection_items = await dao.getCollectionItems(this.props.collection_id)
    this.setState({collection, collection_items})
  },

  render() {
    const {collection, collection_items} = this.state;
    if (!collection_items) {
      return <div>Loading collection...</div>;
    }

    return (
      <div>
        <h3>The <span className="bold">{collection.name}</span> Collection</h3>
        <div className="container">
          <div className="row">
            {collection_items.map((item) => (
              <ItemPreviewSquare key={item.sku} item={item} show_color_variation={true}/>
            ))}
          </div>
        </div>
      </div>
    )
  }
});

const AssemblyInstructionLink = ({assembly_instruction}) => {
  if (!assembly_instruction) {
    return <div>Loading...</div>;
  }

  return (
    <a href={util.getS3Url(assembly_instruction.s3_key)} target="_blank" rel="noopener noreferrer">
      Assembly Instructions
    </a>
  )
}

class ColorVariations extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      variation_items: null
    }
  }

  componentDidMount = async() => {
    const {variation_color_sku} = this.props;
    if (variation_color_sku) {
      const variation_items = await dao.getItemVariations(variation_color_sku);
      this.setState({variation_items});
    }
  }

  render = () => {
    const {variation_color_sku} = this.props;

    if (!variation_color_sku) {
      return null;
    }

    const {variation_items} = this.state;

    if (!variation_items) {
      return <div>Loading other colors...</div>;
    }

    return (
      <div style={{marginTop: 20}}>
        <h6>Select Color</h6>
        <div className="btn-group flex-wrap">
          {variation_items.map((variation_item) => (
            <Link
              className={classNames("btn btn-light", {active: variation_item.sku === this.props.current_item_sku})}
              key={variation_item.sku}
              role="button"
              to={`/product/${variation_item.sku}`}
            >
              {variation_item.variation_color_value}
            </Link>
          ))}
        </div>
      </div>
    )
  }
}


class ProductPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: null,
      assembly_instruction: null,
      user: UserStore.get_user(),
      customer: UserStore.get_customer(),
    };
  }

  async componentDidMount() {
    UserStore.on('change', this.setUserFromStore);

    const {sku} = this.props;
    const item = await ItemUtil.get_item(sku);
    if (item == null) {
      this.setState({item:{sku:null}});
      return;
    }

    ItemStore.add_item(item);
    this.setState({item});

    const {product_description} = item;
    const {location, replace} = this.props.history;
    const {pathname} = location;
    if (product_description && /^\/products\/[\w-]+$/.test(pathname)) {
      replace(`${pathname}/${dasherize(product_description)}`)
    }

    const assembly_instruction = await dao.getAssemblyInstructionsForItem(sku);
    this.setState({assembly_instruction});
  }

  componentWillUnmount() {
    UserStore.removeListener('change', this.setUserFromStore);
  }

  setUserFromStore = () => {
    this.setState({
      user: UserStore.get_user(),
      customer: UserStore.get_customer(),
    })
  }

  render() {
    const {item, user, customer} = this.state;

    if (!item) {
      return <div>Loading...</div>
    }

    if (!item.sku) {
      return <div>Item not found</div>
    }

    return (
      <div className="container">
        <Helmet>
          <title>{item.product_name + ' - Euro Style'}</title>
          <meta
            name="description"
            content={item.description_ad}/>

          <meta property="og:title" content={`${item.product_name} - Euro Style`}/>
          <meta property="og:type" content="product"/>
          <meta property="og:url" content={`https://euro.style/products/${item.sku}`}/>
          <meta property="og:image" content={util.getS3Url(item.main_s3_key)}/>
          <meta property="og:description" content={item.description_ad}/>
        </Helmet>

        <div className="row">
          <div className="col-12 col-sm-4 col-md-6">
            <ProductImageGallery item_sku={item.sku}/>
          </div>

          <div className="col-12 col-sm-8 col-md-6">
            <h3>{item.product_name || item.description_es} {' '}
              <small style={{whiteSpace: 'no-wrap'}}>{item.sku}</small>
            </h3>
            <h5>{item.product_description}</h5>


            <ProductPricingAndQuantitySection item={item} user={user} customer={customer}/>

            {item.is_contract_grade && (
              <div><strong>Contract Grade</strong></div>
            )}

            {item.status !== 'Active' && (item.status === 'Evaluation' ? (
                <div>Intro</div>
              ) : ((item.status === 'Special Order' && item.quantity_on_hand === 0) ? (
                <div>MOQ Required</div>
              ) : (
                <div>Promo</div>
            )))}

            {item.item_type === 'KIT' && <ComponentsSection sku={item.sku} className='mt-4'/>}

            <div style={{marginTop: 20}}>
              {item.description_ad}
            </div>

            <FeaturesAndBenefits
              style={{marginTop: 20}}
              features_and_benefits={item.features_and_benefits}
            />

            {item.assembly_instruction_id && (
              <AssemblyInstructionLink assembly_instruction={this.state.assembly_instruction}/>
            )}

            <ColorVariations variation_color_sku={item.variation_color_sku} current_item_sku={item.sku}/>

            {user && user.is_active && item.sku && item.item_type !== 'COMPONENT' && (!item.obs_item || user.is_obs_customer) && (
              <AddToCartButton
                sku={item.sku}
                items_per_carton={item.items_per_carton}
                history={this.props.history}
              />
            )}

            {user && user.is_active && user.user_type === 'Employee' && !Boolean(user.rep_code) && (
              <div className={(item.item_type !== 'COMPONENT' ? 'mt-5' : '')}>
                <div><Link to={`/internal/item/${item.sku}`}>Internal Page</Link></div>
                <div><a href={`/admin/item/edit?id=${item.sku}`}>Edit Item</a></div>
              </div>
            )}
          </div>
        </div>

        <hr />

        <ProductStatsSection item={item}/>

        <hr />
        <CollectionItems collection_id={item.collection_id}/>
      </div>
    );
  }
}

const ProductPageWrapper = ({match, history}) => {
  const {sku} = match.params;
  return <ProductPage key={sku} sku={sku} history={history}/>
}

export default ProductPageWrapper;

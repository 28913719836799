import axios from 'axios';
import React from 'react';

import dao from '../dao';
import ItemGrid from '../product/ItemGrid';

import './contentsio.css';


class FeaturedProductsSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {items: null};
  }

  async componentDidMount() {
    const items = await dao.getItems({is_new: true});
    const items_with_images = items.filter(({main_image_s3_key}) => main_image_s3_key);

    this.setState({items: items_with_images});
  }

  render() {
    const {items} = this.state;

    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>New Products</h2>
            </div>
          </div>
        </div>

        <ItemGrid items={items} non_filter_items={items}/>
      </div>
    )
  }
}


class PublicHome extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      is_loading: true,
      contents_html: null,
      home_page_html: null,
    };
  }

  componentDidMount = async () => {
    const setup = await dao.getSiteSetup();
    const home_page_html = setup.home_page;
    var contents_html = '';
    if (setup.dates != null) {
      const {data} = await axios.get('https://cdn.contents.io/teams/euro-style/collections/pages/items/appointment-page');
      const RE = new RegExp('<img src="(\\S*)" alt="\\/requests\\/appointment"\\/>', 'g');
      const source = RE.exec(data.properties.body);
      if (source && source[1]) {
        contents_html = '<figure><a href="/requests/appointment"><img src="' + source[1] + '"></a></figure>';
      }
      else {
        contents_html = data.properties.body;
      }
    }
    if (setup.main_picture != null)
    {
      contents_html = contents_html + setup.main_picture;
    }
    else {
      const {data} = await axios.get('https://cdn.contents.io/teams/euro-style/collections/pages/items/home-page');
      contents_html = contents_html + data.properties.body;
    }

    this.setState({
      is_loading: false,
      contents_html,
      home_page_html,
    })
  }

  render() {
    const {is_loading, contents_html, home_page_html} = this.state;

    return (
      <div>
        <div className="container contents-container">
          {is_loading ? (
            <div>Loading...</div>
          ) : (
            <div dangerouslySetInnerHTML={{__html: contents_html}}/>
          )}
        </div>

        {home_page_html && (
          <div className="container" dangerouslySetInnerHTML={{__html: home_page_html}} />
        )}

        <FeaturedProductsSection />
      </div>
    );
  }
}

export default PublicHome;

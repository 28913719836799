import classNames from 'classnames';
import React from 'react';
import _ from 'lodash';
import {Link, Route} from 'react-router-dom';

import OrderTable from './OrderTable';
import OrderStatsSection from './OrderStatsSection';
import ItemStatsSection from './ItemStatsSection';
import OrderDAO from './OrderDAO';
import {AdminOrdersTab} from './AdminOrdersTab';

const ORDERS_TABS = [
  {
    route: '/last_orders',
    display: 'Orders',
    render: ({orders}) => <AdminOrdersTab orders={orders}/>,
  },
  {
    route: '/last_orders/info/orders-to-enter',
    display: 'Orders to Enter',
    render: ({orders}) =>
      <OrderTable
        orders={
          _.filter(
            orders, ({status, sales_order_id}) => status !== 'Canceled' && !sales_order_id)}
        show_sent_to_nav_column={true}
      />,
  },
  {
    route: '/last_orders/info/stats',
    display: 'Order Stats',
    render: ({orders}) => (
      <div>
        <OrderStatsSection />

        <hr />

        <ItemStatsSection />
      </div>
    ),
  },
];

class LastOrdersPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: true,

      orders: null,
    }
  }

  componentDidMount = async() => {
    const {orders} = await OrderDAO.get_last_orders();
    this.setState({orders, is_loading: false});
  }

  render() {
    const {orders, is_loading} = this.state;

    if (is_loading) {
      return <div className="alert alert-warning">Loading orders...</div>;
    }

    return (
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h3>Last Orders</h3>
            <h5>(last 6 months)</h5>

            <ul className="nav nav-pills justify-content-center mb-3">
              {ORDERS_TABS.map(({route, display}) => (
                <li className='nav-item'>
                  <Link className={classNames('nav-link', {active: route === window.location.pathname})}
                        to={route}>
                    {display}
                  </Link>
                </li>
              ))}
            </ul>

            {ORDERS_TABS.map(({route, render}) => (
              <Route
                exact={true}
                path={route}
                render={() => render({orders})}
              />
            ))}
          </div>
        </div>
      </div>
    )
  }
}

export default LastOrdersPage;
import React from 'react';
import ImageGallery from 'react-image-gallery';

import dao from '../dao';
import util from '../util';

class ProductImageGallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {images: null};
  }

  componentDidMount = async() =>{
    const images = await dao.getImagesForItem(this.props.item_sku);
    this.setState({images});
  }

  handleImageLoad(event) { }


  _renderVideo(item) {
    return (
      <div>
        <video className="videoInsert" controls>
            <source src={item.original} type="video/mp4" />
            Sorry, your browser doesn't support embedded videos.
        </video>
      </div>
    );
  }

  render() {
    const {images} = this.state;

    if (!images) {
      return <div>Loading...</div>;
    }

    const images_for_gallery = images.map(({s3_key}) => ( util.getS3Url(s3_key).substr(-3) === 'mp4' ? {
      original: util.getS3Url(s3_key),
      thumbnail: util.getS3Url(s3_key, {size: 'thumb'}),
      renderItem: this._renderVideo.bind(this)
    } : {
      original: util.getS3Url(s3_key),
      thumbnail: util.getS3Url(s3_key, {size: 'thumb'}),
    }));

    return (
      <ImageGallery
        items={images_for_gallery}
        slideInterval={10000}
        onImageLoad={this.handleImageLoad}
        showFullscreenButton={false}
        showPlayButton={false}
      />
    );
  }
}

export default ProductImageGallery


import classNames from 'classnames';
import React from 'react';
import moment from 'moment';
import {Link} from 'react-router-dom';

import UserDAO from './UserDAO';

class UsersPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: null,
      is_loading: true,
      show_only_pending_users: true,
    };
  }

  componentDidMount = async() => {
    const {users} = await UserDAO.get_users();
    this.setState({users, is_loading: false});
  }

  render() {
    const {is_loading, users, show_only_pending_users} = this.state;

    if (is_loading) {
      return <div>Loading users...</div>
    }

    return (
      <div className='row'>
        <div className='col-12'>
          <h3>Users</h3>

          <div className="form-check">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="checkbox"
                checked={show_only_pending_users}
                onChange={() => this.setState({show_only_pending_users: !show_only_pending_users})}
              /> Show Only Pending Users
            </label>
          </div>

          <table className="table table-striped">
            <thead>
              <tr>
                <th>ID</th>
                <th>Email</th>
                <th>Company Name</th>
                <th>Company Title</th>
                <th>Status</th>
                <th>Type</th>
                <th>Marketing Emails</th>
                <th>Last Visit</th>
              </tr>

            </thead>

            <tbody>
              {users.map((user) => {
                if (show_only_pending_users && (user.status !== 'Pending' || user.user_type !== 'Retailer')) {
                  return null;
                }

                return (
                  <tr>
                    <td><Link to={`/internal/users/${user.id}`}>{user.id}</Link></td>
                    <td>{user.email}</td>
                    <td>{user.company_name}</td>
                    <td>{user.company_title}</td>
                    <td
                      className={
                        classNames({'table-success': user.is_active, 'table-warning': !user.is_active})}
                    >
                      {user.status}
                    </td>
                    <td>{user.user_type}</td>
                    <td>{String(user.receive_emails)}</td>
                    <td>{user.last_visit && (moment(user.last_visit).format('MM/DD/YYYY'))}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

UsersPage.propTypes = {}

export default UsersPage;